import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Backend, { Backends, MouseTransition, TouchTransition } from 'react-dnd-multi-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdContainer from './components/acheter/AdContainer';
import CheckoutContainer from './components/acheter/CheckoutContainer';
import PaymentContainer from './components/acheter/PaymentContainer';
import ChatContainer from './components/chat/ChatContainer';
import Welcome from './components/home/Welcome';
import ChangePasswordContainer from './components/user/ChangePasswordContainer';
import EditProfileFormContainer from './components/user/EditProfileFormContainer';
import LoginContainer from './components/user/LoginContainer';
import PasswordResetContainer from './components/user/PasswordResetContainer';
import RegisterContainer from './components/user/RegisterContainer';
import AddArticleContainer from './components/vendre/AddArticleContainer';
import store from './redux/store';
import StartupContainer from './StartupContainer';
import './translations/i18n';

const backends: Backends = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition
    },
    {
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
      },
      preview: true,
      transition: TouchTransition
    }
  ]
}

export const DraggableTypes = {
  PHOTO: 'photo',
}

export const routesLoadingUser = [
  '/add-card',
  '/my-profile',
  '/chat/article/',
  '/chat/discussion/'
]

const App: React.FC = () => {
  return (
    <DndProvider backend={Backend} options={backends}>
      <Provider store={store}>
        <Router>
          <StartupContainer routesLoadingUser={routesLoadingUser}>
            <Switch>
              <Route path="/pay/:uid" component={PaymentContainer} />
              <Route path="/add-article" component={AddArticleContainer} />
              <Route path="/login" component={LoginContainer} />
              <Route path="/register" component={RegisterContainer} />
              <Route path="/my-profile" component={EditProfileFormContainer} />
              <Route path="/ad/:uid" component={AdContainer} />
              <Route path="/checkout/:uid" component={CheckoutContainer} />
              <Route path="/forgot/:loginEmail" component={PasswordResetContainer} />
              <Route path="/forgot/" component={PasswordResetContainer} />
              <Route path="/password/:token" component={ChangePasswordContainer} />
              <Route path="/chat/article/:articleUid" component={ChatContainer} />
              <Route path="/chat/discussion/:discussionUid" component={ChatContainer} />
              <Route path="/password/" component={ChangePasswordContainer} />
              <Route path="/" component={Welcome} />
            </Switch>
          </StartupContainer>
        </Router>
      </Provider>
    </DndProvider>
  );
}

export default App;
