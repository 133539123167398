import { ArticleDetailsDto, CreateTransactionDto, LocationDto, ShippingOptionName, ShippingOptionNames } from '@youzd/ref-data';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';
import { addError, FormMessagesWithField, getFieldErrors, getFieldMessages } from '../../helpers/forms';
import { useFormMessagesState } from '../../hooks/useFormMessagesState';
import { ApiError } from '../../service/api';
import ArticleShippingOption from '../article/ArticleShippingOption';
import ArticleSummary from '../article/ArticleSummary';
import Price from '../article/Price';
import Button from '../controls/Button';
import Cta from '../controls/Cta';
import FormField from '../form/FormField';
import LocationEditor from '../geo/LocationEditor';
import YouzdScreen from '../template/YouzdScreen';
import './Checkout.scss';

type ComponentProps = {
    article: ArticleDetailsDto;
    errors: ApiError[],
    loading: boolean,
    createTransaction: (transaction: CreateTransactionDto) => void,
    reloadAd: (forLocation: LocationDto) => void,
}

type CheckoutField = 'delivery' | 'shipOption';

const Checkout: React.FC<ComponentProps> = ({ article, errors, createTransaction, loading, reloadAd }) => {
    const apiErrorsToFormErrors = (errors: ApiError[]): FormMessagesWithField<CheckoutField> =>
        errors.map(e => {
            switch (e.errorType) {
                case 'NOT_AVAILABLE':
                    return {
                        relatedFields: ['all'],
                        message: {
                            level: 'error',
                            text: t(`checkout.error.notAvailable`),
                        }
                    }
                default:
                    return {
                        relatedFields: ['all'],
                        message: {
                            level: 'error',
                            text: t(`checkout.error.technical`)
                        }
                    }
            }
        });
    const { t } = useTranslation();
    const [delivery, setDelivery] = useState<LocationDto | undefined>(article.deliveryLocation);
    const [shipOption, setShipOption] = useState<ShippingOptionName | undefined>(undefined);
    const [messages, setMessages] = useFormMessagesState<CheckoutField>(apiErrorsToFormErrors(errors));

    const changeDelivery = (newDelivery: LocationDto) => {
        setDelivery(newDelivery);
        reloadAd(newDelivery);
    }

    const ctaClicked = () => {
        let messages: FormMessagesWithField<CheckoutField> = [];
        if (!delivery && (!shipOption || shipOption !== ShippingOptionNames.pickAtSeller)) {
            messages = addError(messages, ['delivery'], t('checkout.error.deliveryMissing'));
        }
        if (!shipOption) {
            messages = addError(messages, ['shipOption'], t('checkout.error.shipOptionMissing'));
        }
        setMessages(messages);
        if (delivery && shipOption) {
            createTransaction(
                {
                    uid: uuid(),
                    article: article.uid,
                    shippingOption: shipOption,
                    delivery: delivery,
                }
            )
        }
    }

    const shipOptions = article.shippingOptions.map(o => <li key={o.type} className={o.type === shipOption ? 'selected' : ''} onClick={() => !loading && setShipOption(o.type)}>
        <div className="radio"></div>
        <ArticleShippingOption option={o} hasDelivery={delivery !== undefined} />
    </li>);

    useEffect(() => {
        if (article.shippingOptions.length === 1) {
            setShipOption(article.shippingOptions[0].type);
        } else if (shipOption && article.shippingOptions.find(o => o.type === shipOption) === undefined) {
            setShipOption(undefined);
        }
        if ((!delivery && article.deliveryLocation) || (delivery?.address !== article.deliveryLocation?.address)) {
            setDelivery(article.deliveryLocation)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article]);


    useEffect(() => {
        setMessages(apiErrorsToFormErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);


    const errorMessages = getFieldErrors('all', messages).map((e, index) => <li key={index} dangerouslySetInnerHTML={{ __html: e.text }} />);
    return errorMessages && errorMessages.length > 0 ?
        <YouzdScreen header={true} className='grey'>
            <div className="checkout-error">
                <ul>{errorMessages}</ul>
                <Link to="/"><Button>{t('checkout.back')}</Button></Link>
            </div>
        </YouzdScreen>
        :
        <YouzdScreen header={true} className='grey'>
            <div className='checkout-container'>
                <div className="scroller">
                    <div className="summary">
                        <ArticleSummary article={article} clickable={false} />
                        <Price startPrice={article.startPrice} endPrice={article.endPrice} price={article.price} />
                    </div>
                    <FormField
                        label={t('checkout.choseLocation')}
                        messages={getFieldMessages('delivery', messages)}
                        className="delivery"
                    >
                        <LocationEditor location={delivery} onLocationChanged={changeDelivery} disabled={loading} />

                    </FormField>
                    <FormField
                        label={t('checkout.choseShipping')}
                        messages={getFieldMessages('shipOption', messages)}
                        className="ship-option"
                    >
                        <div className='shipping-options-wrapper'>
                            <ul className='shipping-options'>
                                {shipOptions}
                            </ul>
                        </div>
                    </FormField>
                </div>
                <Cta
                    label={t('checkout.validate')}
                    onClick={ctaClicked}
                    loading={loading}
                />
            </div>
        </YouzdScreen>;
}

export default Checkout;
