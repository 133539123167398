import React from 'react';
import { connect } from 'react-redux';
import ArticleShippingOptions from './ArticleShippingOptions';
import { LocationDto, ArticleDetailsDto } from '@youzd/ref-data';
import { AppState } from '../../redux/reducer';
import { AnyThunkDispatch, recordRedirect } from '../../redux/actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type PropsFromState = {
    deliveryLocation: LocationDto | undefined,
    userLoggedIn: boolean,
};

type OwnProps = {
    article: ArticleDetailsDto,
}

type DispatchProps = {
    loginFirst: (urlAfterLogin: string) => void,
    editProfileFirst: (urlAfterLogin: string) => void,
}

type ComponentProps = OwnProps & PropsFromState & DispatchProps;

const ArticleShippingOptionsContainer: React.FC<ComponentProps> = ({ article, deliveryLocation, loginFirst, editProfileFirst, userLoggedIn }) => {
    return <ArticleShippingOptions
        options={article.shippingOptions}
        deliveryLocation={deliveryLocation}
        userLoggedIn={userLoggedIn}
        doAddressInput={() => userLoggedIn ? editProfileFirst(`/ad/${article.uid}`) : loginFirst(`/ad/${article.uid}`)} />
};

const mapStateToProps = (state: AppState): PropsFromState => ({
    deliveryLocation: state.user?.address?.address ? state.user.address : undefined,
    userLoggedIn: state.user !== undefined,
});

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps): DispatchProps => ({
    loginFirst: (urlAfterLogin: string) => {
        dispatch(recordRedirect(urlAfterLogin));
        routeProps.history.push('/login');
    },
    editProfileFirst: (urlAfterLogin: string) => {
        dispatch(recordRedirect(urlAfterLogin));
        routeProps.history.push('/my-profile');
    }
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(ArticleShippingOptionsContainer));