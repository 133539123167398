import React, { useEffect } from 'react';
import AddPhotos from './AddPhotos';
import { AnyImageKind } from '../../helpers/photos';
import { ArticleDto, Location, ClockPriceDto, ShippingSize, ArticleState } from '@youzd/ref-data';
import EditAttributes from '../attributes/EditAttributes';
import './AddArticle.scss';
import YouzdScreen from '../template/YouzdScreen';
import Modal from '../template/Modal';
import { useTranslation } from 'react-i18next';
import Button from '../controls/Button';
import { useMedia } from '../../hooks/useMedia';

type NullableArticle = Omit<ArticleDto, "state" | "location" | "price" | "shippingSize"> & {
    location: Location | undefined,
    state: ArticleState | undefined,
    price: ClockPriceDto | undefined,
    shippingSize: ShippingSize | undefined,
}

export type NullableArticleWithTempPhotos = Omit<NullableArticle, "photos"> & {
    photos: AnyImageKind[]
}

type Step = 'photos' | 'attributes' | 'init';

type ComponentProps = {
    article: NullableArticleWithTempPhotos,
    recover: boolean,
    loading: boolean,
    onArticleSave: (article: NullableArticleWithTempPhotos) => void
    onArticleUpdated: (article: NullableArticleWithTempPhotos) => void
    onReset: () => void
}

const AddArticle: React.FC<ComponentProps> = ({ article, onArticleSave, onArticleUpdated, recover, onReset, loading }) => {

    const [step, setStep] = React.useState<Step>('init');
    const [showRecover, setShowRecover] = React.useState<boolean>(recover);
    const { isDesktop } = useMedia();

    useEffect(() => {
        setStep((article.photos && article.photos.length > 0) || isDesktop ? 'attributes' : 'photos');
        // eslint-disable-next-line
    }, [article])

    const { t } = useTranslation();

    const photosValidated = (photos: AnyImageKind[]) => {
        onArticleUpdated({ ...article, photos });
        setStep('attributes');
    }

    return (
        <YouzdScreen header={true} className='grey'>
            {showRecover && <Modal dismiss={() => setShowRecover(false)} contentId="recover">
                <h2>{t('recover.question')}</h2>
                <Button onClick={() => setShowRecover(false)}>{t('recover.continue')}</Button>
                <Button className="secondary" onClick={() => { setShowRecover(false); onReset() }}>{t('recover.reset')}</Button>
            </Modal>}
            {step === 'photos' &&
                <AddPhotos uid={article.uid} onValidate={photosValidated} photos={article.photos} />
            }
            {step === 'attributes' &&
                <EditAttributes article={article} onSaveArticle={onArticleSave} onUpdateArticle={onArticleUpdated} loading={loading} />
            }
        </YouzdScreen>
    )
}

export default AddArticle;