import React from 'react';
import { ShippingOption, ShippingOptionNames, SellerDelivery, YouzdDelivery } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../helpers/numbers';


type ComponentProps = {
    option: ShippingOption,
    hasDelivery: boolean,
}

const ArticleShippingOption: React.FC<ComponentProps> = ({ option, hasDelivery }) => {
    const { t } = useTranslation();
    switch (option.type) {
        case ShippingOptionNames.pickAtSeller:
            return <>
                <span className="label">{t(`shipOption.${option.type}.buyerLabel`)}</span>
                &nbsp;:&nbsp;
                <span className="price">{t('ad.shipping.free')}</span>
            </>
        case ShippingOptionNames.pickup:
            return <>
                <span className="label">{t(`shipOption.${option.type}.buyerLabel`)}</span>
                &nbsp;:&nbsp;
                <span className="price">{hasDelivery ? '+19' : '? '}€</span>
            </>
        case ShippingOptionNames.sellerDelivery:
            return <>
                <span className="label">{t(`shipOption.${option.type}.buyerLabel`)}</span>
                &nbsp;:&nbsp;
                <span className="price">{hasDelivery ? '+' + formatAmount((option as SellerDelivery).shippingPrice) : '? '} €</span>
            </>
        case ShippingOptionNames.youzdDelivery:
            return <>
                <span className="label">{t(`shipOption.${option.type}.buyerLabel`)}</span>
                &nbsp;:&nbsp;
                <span className="price">{hasDelivery ? '+' + formatAmount((option as YouzdDelivery).buyerCost) : '? '}€</span>
            </>
    }
}

export default React.memo(ArticleShippingOption);