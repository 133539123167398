import React, { useState } from 'react';
import { CategoryName, CategoryNames } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import CatAppliancesImage from '../../assets/icons/cat-appliances.svg'
import CatBabyImage from '../../assets/icons/cat-baby.svg';
import CatBedImage from '../../assets/icons/cat-bed.svg';
import CatDecorationImage from '../../assets/icons/cat-decoration.svg';
import CatElectronicsImage from '../../assets/icons/cat-electronics.svg';
import CatLeisureImage from '../../assets/icons/cat-leisure.svg';
import CatLightsImage from '../../assets/icons/cat-lights.svg';
import CatSeatImage from '../../assets/icons/cat-seat.svg';
import CatSmallAppliancesImage from '../../assets/icons/cat-small-appliances.svg';
import CatSofaImage from '../../assets/icons/cat-sofa.svg';
import CatStorageImage from '../../assets/icons/cat-storage.svg';
import CatTableImage from '../../assets/icons/cat-table.svg';
import './SelectCategories.scss';
import Modal from '../template/Modal';
import Button from '../controls/Button';
import Caption from '../controls/Caption';

export type ComponentProps = {
    categories: CategoryName[],
    onCategoriesChange: (categories: CategoryName[]) => void,
}

const SelectCategories: React.FC<ComponentProps> = ({ categories, onCategoriesChange }) => {
    const [tooMany, setTooMany] = useState<boolean>(false);

    const { t } = useTranslation();

    const categoryNames = Object.keys(CategoryNames);
    const images: any = {};
    images['baby'] = CatBabyImage;
    images['bed'] = CatBedImage;
    images['decoration'] = CatDecorationImage;
    images['electronics'] = CatElectronicsImage;
    images['leisure'] = CatLeisureImage;
    images['lights'] = CatLightsImage;
    images['appliances'] = CatAppliancesImage;
    images['seat'] = CatSeatImage;
    images['small-appliances'] = CatSmallAppliancesImage;
    images['sofa'] = CatSofaImage;
    images['storage'] = CatStorageImage;
    images['table'] = CatTableImage;

    const toggleCategory = (catName: CategoryName) => {
        if (categories.indexOf(catName) >= 0) {
            onCategoriesChange(categories.filter(c => c !== catName));
        } else if (categories.length < 2) {
            onCategoriesChange([...categories, catName]);
        } else {
            setTooMany(true);
        }
    }

    const allCategories = categoryNames.map((catName) => (
        <li className={categories.indexOf(catName as CategoryName) >= 0 ? 'selected' : ''} key={catName} onClick={() => { toggleCategory(catName as CategoryName) }}>
            <div className="square">
                <div className="wrapper">
                    {images[`${catName}`] ? <img src={images[`${catName}`]} alt={t(`categories.${catName}`)} /> : ''}
                    <span>{t(`categories.${catName}`)}</span>
                </div>
            </div>
        </li>
    ));
    while (allCategories.length % 4 !== 0) {
        allCategories.push((<li className="padding" key={'padd' + allCategories.length}></li>));
    }

    const dismiss = () => {
        setTooMany(false);
    }

    const errorMessage = tooMany ?
        <Modal dismiss={dismiss}>
            <p>{t("add.categories.error.tooMany")}</p>
            <Button onClick={dismiss}>{t("popup.close")}</Button>
        </Modal>
        : null;

    return (
        <div className="categories-container">
            <Caption safeHtml={t('add.categories.subtitle')} />
            {errorMessage}
            <ul className="categories">
                {allCategories}
            </ul>
        </div>
    );
}

export default SelectCategories;