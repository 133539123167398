import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './FlatMenu.scss';
import { UserDto } from '@youzd/ref-data';
import IconChat from '../../assets/icons/menu-chat.svg';
import IconHeart from '../../assets/icons/menu-heart.svg';
import Button from '../controls/Button';
import MaleAvatar from '../../assets/avatar-m.svg';
import FemaleAvatar from '../../assets/avatar-f.svg';
import { getImageUrl } from '../../helpers/photos';

type ComponentProps = {
    logged: boolean;
    user: UserDto | undefined;
}

const FlatMenu: React.FC<ComponentProps> = ({ user }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const avatar = user?.photo && user.photo.thumb ? getImageUrl(user.photo.thumb) : user?.title === 'M.' ? MaleAvatar : FemaleAvatar;
    return (
        <div className="flat-menu">
            <ul>
                <li><Button onClick={() => history.push('/add-article')}>{t("menu.add")}</Button></li>
                {user && <>
                    <li><NavLink to="/chat" activeClassName="current" exact={true}><img src={IconChat} alt="" /></NavLink></li>
                    <li><NavLink to="/favorites" activeClassName="current" exact={true}><img src={IconHeart} alt="" /></NavLink></li>
                    <li><NavLink to="/my-profile" activeClassName="current" exact={true}><span>{user.nickName}</span><img className="avatar" src={avatar} alt="" /></NavLink></li>
                </>}
                {!user && <li><NavLink to="/login" activeClassName="current" exact={true}><Button>{t('menu.login')}</Button></NavLink></li>}
            </ul>

        </div>
    );
}

export default FlatMenu;