import { getAuth, setAuth } from "./cookies";
import { refresh } from "./user";

export const API_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';


export type ErrorType = 'DUPLICATE' | 'INVALID' | 'OTHER' | 'FAILED' | 'LOGIN_REQUIRED' | 'NOT_AVAILABLE'
export type Operation = 'REGISTER_USER' |
    'LOGIN' |
    'SOCIAL_SGININ' |
    'SAVE_PROFILE' |
    'LOAD_PROFILE' |
    'SAVE_ARTICLE' |
    'UPLOAD_PHOTO' |
    'REFRESH_TOKEN' |
    'LOAD_AD' |
    'RESET_PASSWORD' |
    'CHANGE_PASSWORD' |
    'SAVE_LIKE' |
    'DELETE_LIKE' |
    'CREATE_TRANSACTION' |
    'GET_CARD_REGISTER' |
    'PAY_CARD_REGISTER' |
    'LOAD_TRANSACTION' |
    'GET_CHAT_TOKEN';

export type ApiError = {
    operation: Operation,
    errorMessage: string,
    errorType: ErrorType,
    errorData?: any
}

export const emptyApiErrorsArray: ApiError[] = [];

export type RetriableFetch = {
    url: RequestInfo,
    options?: RequestInit,
    auth: AuthToken,
}

export type AuthToken = {
    token: string,
    refreshToken: string,
}

export const createApiError = (operation: Operation, errorType: ErrorType, errorMessage: string, errorData?: any): ApiError => ({
    operation, errorMessage, errorType, errorData
})

export const getAuthWithRefresh = (operation: Operation, resetToken?: string): Promise<AuthToken> => (
    new Promise<AuthToken>((resolve, reject) => {
        const cookieAuth = getAuth();
        if (cookieAuth && cookieAuth.token) {
            resolve(cookieAuth)
        } else if (cookieAuth.refreshToken) {
            refresh({ refresh: cookieAuth.refreshToken }).then(login => {
                setAuth(login);
                resolve({ token: login.token, refreshToken: login.refreshToken });
            }).catch((e) => reject(e));
        } else if (resetToken) {
            resolve({ token: resetToken, refreshToken: '' });
        } else {
            reject(createApiError(operation, 'LOGIN_REQUIRED', 'no token found'));
        }
    })
);

export const handleError = (operation: Operation, response: Response): Promise<Response> => {
    return new Promise<Response>(async (resolve, reject) => {
        if (response.ok) {
            resolve(response);
        } else {
            let errorMessage: string = response.statusText;
            let errorType: ErrorType = 'OTHER';
            let errorData: any = undefined;
            switch (response.status) {
                case 400:
                    if (response.statusText === 'User already exists') {
                        errorMessage = 'error.duplicateUser';
                        errorType = 'DUPLICATE';
                    } else if (response.statusText === 'Duplicate nickname') {
                        errorMessage = 'error.duplicateNickname';
                        errorType = 'DUPLICATE';
                        errorData = (await response.json()).suggestion;
                    } else if (response.statusText === 'Cannot purchase article') {
                        errorMessage = 'error.notAvailable';
                        errorType = 'NOT_AVAILABLE';
                    } else {
                        errorType = 'INVALID';
                    }
                    break;
                case 401:
                    // Login errors are with a header explaining error of the form 
                    // WWW-Authenticate: Bearer realm="youzd api login" error="invalid_login" error_description="Invalid credentials provided"
                    const authHeader = response.headers.get('WWW-authenticate');
                    const authHeaderRe = /( [^=]+="[^"]+")/g;
                    const headerRe = /^ ([^=]+)="([^"]+)"$/;
                    errorMessage = 'error.' + (authHeader?.match(authHeaderRe)?.map(m => {
                        const parts = m.match(headerRe);
                        return parts ? { key: parts[1], value: parts[2] } : undefined
                    }).find(hObj => hObj && hObj.key === 'error')?.value || response.statusText);
                    if (errorMessage === 'error.invalid_login') {
                        errorType = "FAILED"
                    } else if (errorMessage === 'error.TokenExpiredError') {
                        errorType = "LOGIN_REQUIRED";
                    } else {
                        errorType = "LOGIN_REQUIRED";
                    }
            }

            const error: ApiError = {
                operation,
                errorType,
                errorMessage,
                errorData
            }
            reject(error)
        }
    });
}