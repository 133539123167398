import React, { useState, useEffect } from 'react';
import { AnyImageKind } from '../../helpers/photos';
import Photo from './Photo';
import { EventData, Swipeable } from 'react-swipeable';
import './Carousel.scss'

type ComponentProps = {
    photos: AnyImageKind[];
    onIndexChange?: (index: number) => void,
    selected?: number

}

const Carousel: React.FC<ComponentProps> = ({ photos, onIndexChange, selected }) => {
    const [current, setCurrent] = useState(selected || 0);
    const [swipping, setSwipping] = useState<'left' | 'right' | undefined>(undefined);

    useEffect(() => {
        if (onIndexChange) {
            onIndexChange(current);
        }
    }, [current, onIndexChange])

    const onSwipedLeft = () => {
        if (current !== undefined && current < photos.length - 1) {
            setCurrent(current + 1);
        }
        setSwipping(undefined);
    };
    const onSwipedRight = () => {
        if (current !== undefined && current >= 1) {
            setCurrent(current - 1);
        }
        setSwipping(undefined);
    };
    const onSwiping = (e: EventData) => {
        const direction = e.dir === 'Left' ? 'left' : e.dir === 'Right' ? 'right' : undefined;
        if (direction && (!swipping || direction !== swipping)) {
            setSwipping(direction)
        } else if (!direction) {
            setSwipping(undefined);
        }
    }
    const getSwipingClassName = (index: number) => {
        return [
            (index === 0 ? 'first' : ''),
            (index < current ? 'swiped' : ''),
            (index === current - 1 ? 'before' : ''),
            (index === current ? 'current' : ''),
            (index === current + 1 ? 'after' : ''),
            (swipping === 'left' ? 'swipingLeft' : (swipping === 'right' ? 'swipingRight' : ''))
        ].join(' ');
    }
    return (
        <div className='carousel'>
            <ul>
                {photos.map((p, index) => (<li key={index} className={getSwipingClassName(index)}><Photo image={p} /></li>))}
            </ul>
            <div className="shadow"></div>
            <Swipeable className="swiper" onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight} onSwiping={onSwiping}></Swipeable>
            <ul className="thumbs">{photos.map((p, index) => (<li key={index} onClick={() => setCurrent(index)} className={current === index ? 'active' : ''}></li>))}</ul>
        </div>
    )
}

export default Carousel;