import { ChatService, DiscussionDetailsDto, DiscussionMessageDto, JoinChatDiscussionDto, TransactionMessageDto, UserDto } from '@youzd/ref-data';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import { AnyThunkDispatch } from '../../redux/actions';
import { AppState } from '../../redux/reducer';
import { doLoadUser } from '../../redux/userActions';
import { API_HOST } from '../../service/config';
import { getChatToken } from '../../service/user';
import Loader from '../template/Loader';
import TechnicalError from '../template/TechnicalError';
import Chat from './Chat';

type PropsFromState = {
    user: UserDto | undefined,
    articleUid?: string,
    discussionUid?: string,
}

type DispatchProps = {
    loadUser: () => void
}

type ComponentProps = PropsFromState & DispatchProps;

const ChatContainer: React.FC<ComponentProps> = ({ user, articleUid, discussionUid, loadUser }) => {
    const [discussion, setDiscussion] = useState<DiscussionDetailsDto | undefined>(undefined);
    const [messages, setMessages] = useState<(TransactionMessageDto | DiscussionMessageDto)[]>([]);
    const [socket, setSocket] = useState<SocketIOClient.Socket>();
    const [failed, setFailed] = useState(false);
    const [userUid, setUserUid] = useState<string>();

    const initSocketClient = () => {
        const socket = io(`${API_HOST}`);
        socket.on('connect', () => {
            console.log('chat connected');
            getChatToken().then((token) => {
                socket.emit('token', token);
            }).catch(e => {
                console.log('could not get chat token', e);
                setFailed(true);
            })
            socket.on('service', (serviceMessage: ChatService) => {
                console.log('received service message', serviceMessage);
                if (serviceMessage.result === 'error') {
                    setFailed(true);
                } else {
                    if (serviceMessage.subject === 'TOKEN') {
                        const joinDto: JoinChatDiscussionDto = {
                            articleUid,
                            discussionUid
                        }
                        socket.emit('join', joinDto);
                    }
                }

            })
            socket.on('disconnect', () => {
                console.log('disconnected');
                socket.off('message');
                socket.off('service');
                socket.off('discussion');
            })
            socket.on('discussion', (discussion: DiscussionDetailsDto) => {
                console.log('received discussion', discussion);
                setDiscussion(discussion);
                setMessages(discussion.messages);
            })
        })
        return socket;
    }

    useEffect(() => {
        console.log('resubscribe to messages');
        socket?.off('message');
        socket?.on('message', (message: (TransactionMessageDto | DiscussionMessageDto)) => {
            console.log('received message');
            setMessages([...messages, message]);

        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, socket]);

    useEffect(() => {
        if (!user) {
            loadUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user && !socket && userUid !== user.uid) {
            console.log('init socket from user hook')
            setUserUid(user.uid);
            const newSocket = initSocketClient();
            setSocket(newSocket);
            return () => {
                console.log('disconnect as we are going down (user)');
                if (socket && newSocket.connected) {
                    newSocket.disconnect();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    const say = (message: string) => {
        socket?.emit('say', message);
    }
    return discussion && user && !failed ? <Chat user={user} article={discussion.article} messages={messages} say={say} /> : failed ? <TechnicalError /> : <Loader />;
}

const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ articleUid: string, discussionUid: string }>): PropsFromState => {
    return {
        user: state.user,
        articleUid: routeProps.match.params.articleUid,
        discussionUid: routeProps.match.params.discussionUid,
    }
}

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps<{ articleUid: string, discussionUid: string }>): DispatchProps => ({
    loadUser: () => {
        const discussionUid = routeProps.match.params.discussionUid;
        const articleUid = routeProps.match.params.articleUid;
        dispatch(doLoadUser(routeProps, true, discussionUid ? `/chat/discussion/${discussionUid}` : `/chat/article/${articleUid}`));
    }
});


export default withRouter(connect(mapStateToProps, dispatchToProps)(ChatContainer));