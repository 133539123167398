import React from 'react';
import { ImageDto, Title } from '@youzd/ref-data';
import MaleAvatar from '../../assets/avatar-m.svg'
import FemaleAvatar from '../../assets/avatar-f.svg'
import { getImageUrl } from '../../helpers/photos';
import './UserInfo.scss';

type ComponentProps = {
    nickName: string,
    photo: ImageDto | undefined,
    title: Title | undefined,
}

const UserInfo: React.FC<ComponentProps> = ({ nickName, photo, title }) => {
    return <div className="user-info-container">
        <div className="avatar"><img src={photo && photo.thumb ? getImageUrl(photo.thumb) : title === 'M.' ? MaleAvatar : FemaleAvatar} alt="" /></div>
        <div className="nickName">{nickName}</div>
    </div>;
}

export default UserInfo;