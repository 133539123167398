import React from 'react';
import { connect } from 'react-redux';
import { LocationDto } from '@youzd/ref-data';
import LocationEditor from '././LocationEditor';
import { AppState } from '../../redux/reducer';



type ComponentProps = {
    location: LocationDto | undefined,
    onLocationChanged: (newLocation: LocationDto) => void,
}


const LocationEditorContainer: React.FC<ComponentProps> = ({ location, onLocationChanged }) => {
    return <LocationEditor
        location={location}
        onLocationChanged={onLocationChanged} />;
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps): ComponentProps => {
    const propsLocation = ownProps.location;
    const userLocation = state.user?.address;
    if ((!propsLocation || !propsLocation.address) && userLocation && userLocation.address) {
        ownProps.onLocationChanged(userLocation);
    }
    return ({
        location: propsLocation || userLocation,
        onLocationChanged: ownProps.onLocationChanged
    })
}

export default connect(mapStateToProps)(LocationEditorContainer);