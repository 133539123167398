export type MediaType = {
    isMobile: boolean,
    isDesktop: boolean,
    type: 'desktop' | 'mobile'
}

const media = (() => {
    const isDesktop = window.matchMedia('(min-device-width: 1200px)').matches;
    return {
        isDesktop,
        isMobile: !isDesktop,
        type: isDesktop ? 'desktop' : 'mobile'
    }
})()

export function useMedia() {
    return media;
}