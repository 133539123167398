import { useState } from "react";
import { FormMessagesWithField, FormMessageWithField } from "../helpers/forms";

export function useFormMessageState<F>(error?: string | FormMessageWithField<F>) {
    return useState<FormMessagesWithField<F>>(error ? typeof error === 'string' ? [{
        relatedFields: ['all'],
        message: {
            text: error,
            level: 'error'
        }
    }] : [error] : []);
}

export function useFormMessagesState<F>(errors: FormMessageWithField<F>[]) {
    return useState<FormMessagesWithField<F>>(errors ? errors : []);
}