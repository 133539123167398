import Cookies from 'universal-cookie';
import { AuthToken } from './api';
import { UserLoggedInDto } from '@youzd/ref-data';
import moment from 'moment';


const youzdCookies = new Cookies();


const getCookieOptions = () => ({
    path: '/', domain: window.location.hostname,
    secure: process.env.NODE_ENV === 'production' ? true : false
})

export const getAuth = (): AuthToken => {
    return {
        token: youzdCookies.get('youzd-token'),
        refreshToken: youzdCookies.get('youzd-refresh')
    }
}

export const setAuth = (loginDto: UserLoggedInDto) => {
    const defaultCookieOptions = getCookieOptions();
    youzdCookies.set('youzd-token', loginDto.token, { ...defaultCookieOptions, expires: moment().add(1, 'hour').toDate() });
    youzdCookies.set('youzd-refresh', loginDto.refreshToken, { ...defaultCookieOptions, expires: moment().add(7, 'days').toDate() });
}

export const getStarterSeen = (): boolean => {
    return youzdCookies.get('youzd-starter') === 'true';
}

export const setStarterSeen = (): void => {
    const defaultCookieOptions = getCookieOptions();
    youzdCookies.set('youzd-starter', 'true', { ...defaultCookieOptions, expires: moment().add(7, 'days').toDate() });

}