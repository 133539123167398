import React from 'react';
import { AnyImageKind, getType, ImageType, PhotoData } from '../../helpers/photos';
import Photo from './Photo';
import './PhotoHeader.scss';
import Carousel from './Carousel';
import { ImageDto } from '@youzd/ref-data';

type ComponentProps = {
    photos: AnyImageKind[];
    mode: 'first' | 'carousel'
}

const PhotoHeader: React.FC<ComponentProps> = ({ photos, mode }) => {
    return (
        <div className="photo-header">
            <div className="square">
                <div className="photo-wrapper">
                    {(mode === 'first') && <Photo image={photos[0]} alt="" />}
                    {(mode === 'carousel') && <Carousel photos={photos} />}
                </div>
            </div>
        </div>
    )
}

export default React.memo(PhotoHeader, (prev, next) => {
    const photoIsSame = (prev: AnyImageKind, next: AnyImageKind, type: ImageType): boolean => {
        if (type === 'data') {
            return (prev as PhotoData).data === (next as PhotoData).data;
        } else {
            return (prev as ImageDto).name === (next as ImageDto).name
        }
    }
    const photosAreTheSame = () => {
        let result = true;
        for (let i = 0; i < prev.photos.length; i++) {
            const prevPhoto = prev.photos[i];
            const nextPhoto = next.photos[i];
            const prevType = getType(prevPhoto);
            const nextType = getType(nextPhoto);
            result = result && prevType === nextType && photoIsSame(prevPhoto, nextPhoto, prevType);
            if (!result) break;
        }
        return result;
    }
    return (prev.mode === next.mode) && (prev.photos.length === next.photos.length) && photosAreTheSame();
});