import React from 'react';
import { BrandAttribute } from '@youzd/ref-data';
import Toggle from '../controls/Toggle';
import AutoSuggest from '../controls/AutoSuggest';
import brandDatabase from '../../assets/brands.json';
import { useTranslation } from 'react-i18next';
import './BrandEdit.scss';
import FormField from '../form/FormField';
import FormGroup from '../form/FormGroup';


export type ComponentProps = {
    brand: BrandAttribute,
    onBrandChange: (brand: BrandAttribute) => void
}

const BrandEdit: React.FC<ComponentProps> = ({ brand, onBrandChange }) => {

    const { t } = useTranslation();

    const brandNameChanged = (brandName: string) => {
        onBrandChange({ name: brandName, hasBrand: brandName.length > 0 });
    }

    const toggleBrand = (hasBrand: boolean) => {
        if (!hasBrand) {
            onBrandChange({ name: '', hasBrand: false });
        } else {
            onBrandChange({ ...brand, hasBrand });
        }
    }
    return (
        <FormGroup className='brand-edit'>
            <FormField label={t("add.brand.noBrand")} layout="inline">
                <Toggle toggled={!brand.hasBrand} onToggle={(hasNoBrand) => { toggleBrand(!hasNoBrand) }} />
            </FormField>
            <AutoSuggest value={brand.name || ''} onChange={brandNameChanged} database={brandDatabase} restrict={false} />
        </FormGroup>
    );
}

export default BrandEdit;