import React from 'react';
import { FurnitureLayoutAttribute, FurnitureLayoutAttributes } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import CheckOptions, { CheckOption } from '../controls/CheckOptions';

type ComponentProps = {
    layouts: FurnitureLayoutAttribute[] | undefined;
    onChange: (layouts: FurnitureLayoutAttribute[]) => void
}

const FurnitureLayoutEdit: React.FC<ComponentProps> = ({ layouts, onChange }) => {
    const { t } = useTranslation();

    const onToggle = (layout: FurnitureLayoutAttribute) => {
        if (layouts && layouts.includes(layout)) {
            onChange(layouts.filter(l => l !== layout));
        } else {
            onChange([...(layouts || []), layout]);
        }
    }
    const options: CheckOption[] = Object.keys(FurnitureLayoutAttributes).map(l => ({ label: t(`layouts.${l}`), value: l }));

    return (
        <div className="layouts-container">
            <CheckOptions values={layouts || []} onChange={onToggle}>
                {options}
            </CheckOptions>
        </div>
    );
}

export default FurnitureLayoutEdit;