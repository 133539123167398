import React from 'react';
import { useState } from 'react';
import './AutoSuggest.scss';
import { useEffect } from 'react';
import FormField from '../form/FormField';

type ComponentState = {
    value: string,
    suggestions: RegExpExecArray[],
    hasBeenSelected: boolean,
    hideSuggestions: boolean,
    highLightIndex: number,
}

type ComponentProps = {
    database: string[],
    value: string,
    onChange: (value: string) => void,
    className?: string,
    restrict?: boolean
}

const AutoSuggest: React.FC<ComponentProps> = ({ database, value, onChange, restrict }) => {
    const inputRef = React.createRef<HTMLInputElement>();
    const [state, setState] = useState<ComponentState>({
        value: value,
        suggestions: [],
        hasBeenSelected: false,
        hideSuggestions: false,
        highLightIndex: 0,
    });

    useEffect(() => {
        setState({ ...state, value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.trimLeft();
        let newState = { ...state, value: input };
        if (input.length > 0) {
            const matcher = new RegExp(`${input.toLowerCase()}`, 'i');
            const suggestions = database.map(e => matcher.exec(e)).filter(match => match !== null) as RegExpExecArray[];
            newState = { ...newState, suggestions: suggestions.sort((a, b) => (a.index - b.index)), hasBeenSelected: false, hideSuggestions: false };
        } else {
            newState = { ...newState, hideSuggestions: true };
        }

        setState(newState);
    }

    const onFocus = () => {
        if (!state.hasBeenSelected && state.value.length > 0) {
            setState({ ...state, hideSuggestions: false });
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.keyCode) {
            case 40:
                setState({ ...state, highLightIndex: Math.min(state.highLightIndex + 1, Math.min(state.suggestions.length - 1, 9)) });
                break;
            case 38:
                setState({ ...state, highLightIndex: Math.max(state.highLightIndex - 1, 0) });
                break;
            case 9:
            case 13:
                if (state.suggestions.length > 0) {
                    const selected = state.suggestions[state.highLightIndex].input;
                    selectSuggestion(selected);
                } else if (state.value.length > 0) {
                    onChange(state.value);
                }
        }
    }

    const onBlur = () => {
        setState({ ...state, hideSuggestions: true });
        if (state.value.length > 0 && restrict === false) {
            onChange(state.value);
        }
    }

    const selectSuggestion = (suggestion: string) => {
        setState({ ...state, value: suggestion, hasBeenSelected: true, highLightIndex: 0 });
        onChange(suggestion);
    }

    const suggestions = !state.hasBeenSelected && !state.hideSuggestions && state.suggestions.slice(0, 10).map((match, index) => {
        const suggestion = match.input;
        const formatedSuggestion =
            <>
                {(match.index > 0 ? suggestion.substr(0, match.index) : '')}
                <span>{match[0]}</span>
                {(match.index < suggestion.length - 1) ? suggestion.substr(match.index + match[0].length) : ''}
            </>
        return <li className={state.highLightIndex === index ? 'highlight' : ''} key={suggestion} onMouseDown={() => { selectSuggestion(suggestion); }}>{formatedSuggestion}</li>
    });

    return (
        <FormField className="auto-suggest">
            <input
                type="text"
                value={state.value}
                onChange={onInputChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                ref={inputRef}
            />
            <ul className={["suggestions", (!suggestions || suggestions.length > 0) && !state.hideSuggestions ? '' : 'empty'].join(' ')}>
                {suggestions}
            </ul>
        </FormField>
    );
}

export default AutoSuggest;