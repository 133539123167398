import React from 'react';
import Div100vh from 'react-div-100vh';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import './YouzdScreen.scss';
import { useMedia } from '../../hooks/useMedia';

type ComponentProps = {
    header?: boolean,
    footer?: boolean,
    className?: 'grey',
}

const YouzdScreen: React.FC<ComponentProps> = ({ header, footer, className, children }) => {
    const { isDesktop } = useMedia();
    const style = ["youzd-screen", className].join(' ');
    const content = (<>

        {children}
        {footer && 'TODO FOOOTER'}
    </>);
    return isDesktop ?
        <div className={style}>
            {header && <DesktopHeader />}
            {content}
        </div>
        :
        <Div100vh className={style}>
            {header && <MobileHeader />}
            {content}
        </Div100vh>
        ;
}

export default YouzdScreen;