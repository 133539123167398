import React from 'react';
import './Toggle.scss';

type ToggleProps = {
    toggled: boolean,
    onToggle: (value: boolean) => void,
}

const Toggle: React.FC<ToggleProps> = ({ toggled, onToggle }) => {


    return (
        <div className={'toggle-container' + (toggled ? ' toggled' : '')} onClick={() => { onToggle(!toggled) }}>
            <div className='toggle'></div>
        </div>
    )
}

export default Toggle;