import { loadYouzdDeliveryZipCodes } from '@youzd/ref-data';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyThunkDispatch, setYouzdDeliveryZipCodes } from './redux/actions';
import { AppState } from './redux/reducer';
import { doLoadUser } from './redux/userActions';

type OwnProps = {
    routesLoadingUser: string[];
}

type PropsFromState = {
    router: RouteComponentProps;
}

type DispatchProps = {
    onStart: (loadUser: boolean) => void
}

type ComponentProps = OwnProps & PropsFromState & DispatchProps;

const StartupContainer: React.FC<ComponentProps> = ({ onStart, router, routesLoadingUser, children }) => {
    useEffect(() => {
        const shouldLoadUser =
            !(routesLoadingUser.includes(router.location.pathname) ||
                routesLoadingUser.reduce<boolean>((acc, r) => {
                    const result = acc || router.location.pathname.indexOf(r) === 0
                    return result
                }, false));
        onStart(shouldLoadUser);
    });
    return (<>{children}</>)
}

const mapStateToPrpps = (state: AppState, routeProps: RouteComponentProps): PropsFromState => ({
    router: routeProps
})

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps): DispatchProps => ({
    onStart: (loadUser: boolean) => {
        loadYouzdDeliveryZipCodes.then(codes => dispatch(setYouzdDeliveryZipCodes(codes)));
        if (loadUser) dispatch(doLoadUser(routeProps, false));
    }
});

export default withRouter(connect(mapStateToPrpps, dispatchToProps)(StartupContainer));