import React from 'react';
import Logo from '../../assets/logo.png';
import './DesktopHeader.scss';
import MenuContainer from './MenuContainer';
import { Link } from 'react-router-dom';

const DesktopHeader: React.FC = () => {
    return (
        <header className="main desktop">
            <div className="logo">
                <Link to="/"><img src={Logo} alt="" /></Link>
            </div>
            <MenuContainer />
        </header>
    )
}

export default React.memo(DesktopHeader);