import Exif from 'exif-js';
import { ImageDto } from '@youzd/ref-data';

const THUMBNAIL = 120;
const MAX_IMAGE = 720;

export type AnyImageKind = ImageDto | PhotoData;

export type PhotoData = {
    data: string,
    thumb?: PhotoData
}

export type ImageType = 'image' | 'data';

export const getType = (image: AnyImageKind): ImageType => { return (image as PhotoData).data ? 'data' : 'image' }


export const getExifFromFileDataUrl = (dataUrl: string) =>
    (new Promise<any>((resolve, reject) => {
        try {
            Exif.getData(dataUrl, function (this: any) {
                resolve(Exif.getAllTags(this));
            });
        } catch (e) {
            reject(e);
        }
    }));

export const getThumbnail = (dataUrl: string) => {
    return resizeImage(dataUrl, THUMBNAIL, THUMBNAIL, true, false);
}

export const getBucketDomain = (bucket: string) => {
    switch (bucket) {
        case 'youzd-images':
            return process.env.NODE_ENV === 'production' ? 'https://images.youzd.fr/' : 'http://localhost:4572/youzd-images/';
        default:
            return process.env.NODE_ENV === 'production' ? 'https://static.youzd.fr/' : `http://localhost:4572/${bucket}/`;
    }
}

export const getImageUrl = (image: AnyImageKind): string => {
    if (getType(image) === 'data') {
        return (image as PhotoData).data
    } else {
        return getBucketDomain((image as ImageDto).bucket) + (image as ImageDto).name;
    }
}

export const getImageDataUrl = (image: ImageDto) => {
    return new Promise<string>((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = getImageUrl(image);
        if (img !== null) {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.translate(0, 0);
                    ctx.drawImage(img, 0, 0);
                    try {
                        resolve(canvas.toDataURL());
                    } catch (e) {
                        reject(e);
                    }
                } else { reject("failed to get context"); }
            }
        } else {
            reject("Cannot read image")
        }
    });
}

export const rotateImage = (image: AnyImageKind) => {
    return rotateImageWithAngle(image, Math.PI / 2);
}

export const rotateImageWithAngle = (image: AnyImageKind, angle: number) => {
    return new Promise<PhotoData>((resolve, reject) => {
        (getType(image) === 'image' ? getImageDataUrl(image as ImageDto).then(data => rotateData(data, angle)) : rotateData((image as PhotoData).data, angle))
            .then(rotated => {
                if (image.thumb) {
                    getThumbnail(rotated).then(thumb => resolve({ data: rotated, thumb: { data: thumb } }));
                } else {
                    resolve({ data: rotated });
                }
            });
    });
}

export const rotateData = (dataUrl: string, angle: number) => {
    return new Promise<string>((resolve, reject) => {
        const image = new Image();
        image.src = dataUrl;
        if (image !== null) {
            image.onload = () => {
                const canvas = document.createElement('canvas');
                if (angle === Math.PI / 2 || angle === -Math.PI / 2) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                } else {
                    canvas.width = image.width;
                    canvas.height = image.height;
                }
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.rotate(angle);
                    ctx.drawImage(image, 0, -image.height);
                    resolve(canvas.toDataURL());
                } else { reject("failed to get context"); }
            }
        } else {
            reject("Cannot read image")
        }
    });
}

export const resizeImage = (dataUrl: string, width: number, height: number, crop?: boolean, pad?: boolean, grow?: boolean) => {
    return new Promise<string>((resolve, reject) => {
        const image = new Image();
        image.src = dataUrl;
        if (image !== null) {
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ratio = grow ? Math.min(width / image.width, height / image.height) : Math.min(1, width / image.width, height / image.height);
                if (pad || crop) {
                    canvas.width = width;
                    canvas.height = height;
                } else {
                    canvas.width = image.width * ratio;
                    canvas.height = image.height * ratio;
                }
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.translate(0, 0);
                    const imageRatio = image.width / image.height;
                    const targetRatio = width / height;
                    if (crop) {
                        if (imageRatio > targetRatio) {
                            const sw = width * (image.height / height);
                            const sh = image.height;
                            const sy = 0;
                            const sx = (image.width - sw) / 2;
                            ctx.drawImage(image, sx, sy, sw, sh, 0, 0, width, height);
                        } else {
                            const sw = image.width;
                            const sh = height * (image.width / width);
                            const sy = (image.height - sh) / 2;
                            const sx = 0;
                            ctx.drawImage(image, sx, sy, sw, sh, 0, 0, width, height);
                        }
                    } else if (pad) {
                        if (imageRatio > targetRatio) {
                            const sw = image.width;
                            const sh = image.height;
                            const dy = (height - (image.height * width / image.width)) / 2
                            const dx = 0;
                            const dw = width
                            const dh = image.height * width / image.width;
                            ctx.drawImage(image, 0, 0, sw, sh, dx, dy, dw, dh);
                        } else {
                            const sw = image.width;
                            const sh = image.height;
                            const dx = (width - (image.width * height / image.height)) / 2
                            const dy = 0;
                            const dw = image.width * (height / image.height);
                            const dh = height;
                            ctx.drawImage(image, 0, 0, sw, sh, dx, dy, dw, dh);
                        }
                    } else {
                        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * ratio, image.height * ratio);
                    }
                    resolve(canvas.toDataURL());
                } else {
                    reject("failed to get context");
                }
            }
        } else {
            reject("Cannot read image")
        }
    });
}

export const pngDataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], { type: 'image/png' });
    return bb;
}

export const getFileDataUrl = (file: File) =>
    (new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => { reject('abort') }
        reader.onerror = () => { reject(reader.error) }
        reader.onloadend = () => { resolve(reader.result as string) }
        reader.readAsDataURL(file);
    }));

export const getFilesDataUrl = async (filesToLoad: FileList): Promise<string[]> => {
    const result: string[] = [];
    for (var i = 0; i < filesToLoad.length; i++) {
        const file = filesToLoad.item(i);
        if (file) {
            const fileDataUrl = await getFileDataUrl(file);
            const exif = await getExifFromFileDataUrl(file as unknown as string);
            const resizedImage = await resizeImage(fileDataUrl, MAX_IMAGE, MAX_IMAGE);
            if (exif && exif.Orientation) {
                switch (exif.Orientation) {
                    case 5:
                    case 6:
                        result.push(await rotateData(resizedImage, Math.PI / 2));
                        break;
                    case 7:
                    case 8:
                        result.push(await rotateData(resizedImage, -Math.PI / 2));
                        break;
                    case 3:
                    case 4:
                        result.push(await rotateData(resizedImage, Math.PI));
                        break;
                    default:
                        result.push(resizedImage);
                }
            } else {
                result.push(resizedImage);
            }
        }
    }
    return result;
}