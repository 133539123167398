import React from 'react';
import './RadioOptions.scss';

export type RadioOption = {
    label: string,
    value: string,
    description?: string,
}

type ComponentProps = {
    value: any,
    onChange: (v: any) => void,
    children: RadioOption[],
    layout?: 'inline' | 'vertical'
}

const RadioOptions: React.FC<ComponentProps> = ({ value, onChange, children, layout }) => {
    const layoutClass = (layout === 'vertical') ? 'vertical' : 'inline';
    const mappedChildren = children.map(o => (
        <li
            key={o.value}
            className={value === o.value ? 'selected' : ''}
            onClick={() => onChange(o.value)}>
            <label>{o.label}</label>
            {o.description && <span>{o.description}</span>}
        </li>
    ));
    return (
        <ul className={["radio-options", layoutClass].join(' ')}>
            {mappedChildren}
        </ul>
    )
}

export default RadioOptions;