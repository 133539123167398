import React from 'react';
import './FormField.scss';
import { FormMessage, getHighestMessageLevel, getErrors, getWarnings, getInfos } from '../../helpers/forms';

type ComponentProps = {
    label?: string | JSX.Element;
    layout?: 'inline' | 'reverse' | 'big';
    className?: string;
    messages?: FormMessage[];
    labelClick?: (e: React.MouseEvent) => void,
    hideMessages?: boolean;
    mandatory?: boolean;
}

const FormField: React.FC<ComponentProps> = ({ label, messages, className, layout, hideMessages, children, labelClick, mandatory }) => {
    const errors = getErrors(messages).map((m, index) => (<li key={index}>{m}</li>));
    const warnings = getWarnings(messages).map((m, index) => (<li key={index}>{m}</li>));
    const infos = getInfos(messages).map((m, index) => (<li key={index}>{m}</li>));
    return (
        <div className={["form-field-wrapper", className, getHighestMessageLevel(messages)].join(' ')}>
            <label className={["form-field", layout].join(' ')} onClick={labelClick}>
                {label && <span className="label">{label}{mandatory === true && <span className="mandatory">*</span>}</span>}
                {children}
            </label>
            {(errors && errors.length > 0 && !hideMessages) && (<ul className="errors">{errors}</ul>)}
            {(warnings && warnings.length > 0 && !hideMessages) && (<ul className="warnings">{warnings}</ul>)}
            {(infos && infos.length > 0 && !hideMessages) && (<ul className="infos">{infos}</ul>)}
        </div >
    )
}

export default FormField;