import React, { useState } from 'react';
import { UniverseName, UniverseNames } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import './SelectUniverses.scss';
import CheckOptions, { CheckOption } from '../controls/CheckOptions';
import Button from '../controls/Button';
import Modal from '../template/Modal';
import Caption from '../controls/Caption';

export type ComponentProps = {
    universes: UniverseName[],
    onUniversesChange: (universes: UniverseName[]) => void,
}

const MAX_UNIVERSES = 3;

const SelectUniverses: React.FC<ComponentProps> = ({ universes, onUniversesChange }) => {
    const [tooMany, setTooMany] = useState<boolean>(false);
    const { t } = useTranslation();

    const universesNames = Object.keys(UniverseNames);

    const toggleUniverse = (universe: string) => {
        const alreadyPresent = universes.find(u => u === universe);
        if (alreadyPresent) {
            onUniversesChange([...universes.filter(u => u !== universe)]);
        } else if (universes.length < MAX_UNIVERSES) {
            onUniversesChange([...universes, universe as UniverseName]);
        } else {
            setTooMany(true);
        }
    }

    const dismiss = () => {
        setTooMany(false);
    }

    const errorMessage = tooMany ?
        <Modal dismiss={dismiss}>
            <p>{t('add.universes.error.tooMany')}</p>
            <Button onClick={dismiss}>{t("popup.close")}</Button>
        </Modal> : null;

    const options: CheckOption[] = universesNames.map(u => ({ label: t(`universes.${u}`), value: u }));

    return (
        <div className="universes-container">
            <Caption safeHtml={t('add.universes.subtitle')} />
            {errorMessage}
            <CheckOptions values={universes} onChange={toggleUniverse}>
                {options}
            </CheckOptions>
        </div>
    );
}

export default SelectUniverses;