import React from 'react';
import { connect } from 'react-redux';
import { AnyThunkDispatch, doNext } from '../../redux/actions';
import { doSocialSignin, doLogin } from "../../redux/userActions";
import { UserCredentialsDto } from '@youzd/ref-data';
import { GoogleLoginCallback } from './GoogleLogin';
import Login from './Login';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { AppState } from '../../redux/reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';


type PropsFromState = {
    signingin: boolean,
    userLoggedIn: boolean,
    credentials: UserCredentialsDto | undefined,
    error: string,
    logingin: boolean,
}

type DispatchProps = {
    onGoogleLogin: GoogleLoginCallback,
    onFacebookLogin: (userInfo: ReactFacebookLoginInfo) => void,
    alreadyLoggedIn: () => void,
    onLogin: (credentials: UserCredentialsDto) => void,
}

type ComponentProps = PropsFromState & DispatchProps;

const LoginContainer: React.FC<ComponentProps> = ({ onGoogleLogin, onFacebookLogin, signingin, userLoggedIn, alreadyLoggedIn, credentials, error, onLogin, logingin }) => {
    if (userLoggedIn && !logingin && !signingin) alreadyLoggedIn();
    return (<Login
        onGoogleLogin={onGoogleLogin}
        onFacebookLogin={onFacebookLogin}
        signingin={signingin}
        credentials={credentials}
        error={error}
        onLogin={onLogin}
        logingin={logingin}
    />)
}

const mapStateToProps = (state: AppState): PropsFromState => ({
    signingin: state.pendingOperations.find(o => o === 'SOCIAL_SGININ') != null,
    userLoggedIn: state.user !== undefined,
    credentials: state.credentials,
    error: state.apiErrors.filter(e => e.operation === 'LOGIN').map(e => e.errorMessage).join(' '),
    logingin: state.pendingOperations.find(o => o === 'LOGIN') != null
})



const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps): DispatchProps => ({
    onGoogleLogin: (user: gapi.auth2.GoogleUser) => {
        const userCredentials: UserCredentialsDto = {
            email: user.getBasicProfile().getEmail(),
            password: '',
            googleToken: user.getAuthResponse().id_token
        }
        dispatch(doSocialSignin(userCredentials, routeProps));
    },
    onFacebookLogin: (info: ReactFacebookLoginInfo) => {
        const userCredentials: UserCredentialsDto = {
            email: info.email || '',
            password: '',
            facebookToken: info.accessToken,
        }
        dispatch(doSocialSignin(userCredentials, routeProps));
    },
    alreadyLoggedIn: () => {
        dispatch(doNext(routeProps));
    },
    onLogin: (credentials: UserCredentialsDto) => {
        dispatch(doLogin(credentials, routeProps));
    }
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(LoginContainer));