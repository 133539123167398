import { ArticleDetailsDto, BrandAttribute, WarrantyDto } from '@youzd/ref-data';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { sprintf } from 'sprintf-js';
import BacketIcon from '../../assets/button/basket.svg';
import { getImageUrl } from '../../helpers/photos';
import { apiToUtc } from '../../helpers/time';
import ArticleShippingOptionsContainer from '../article/ArticleShippingOptionsContainer';
import Likes from '../article/Likes';
import Price from '../article/Price';
import Share from '../article/Share';
import Button from '../controls/Button';
import PhotoHeader from '../controls/PhotoHeader';
import YouzdScreen from '../template/YouzdScreen';
import UserInfo from '../user/UserInfo';
import './Ad.scss';


type ComponentProps = {
    article: ArticleDetailsDto;
    liked: boolean,
    canLike: boolean,
    showTransactions: boolean,
    isOwner: boolean,
    setLiked: (liked: boolean) => void
}

type Detail = 'removable' | 'numberOfItems' | 'numberOfSeats' | 'state' | 'brand' | 'warranty'

const Ad: React.FC<ComponentProps> = ({ article, liked, setLiked, canLike, showTransactions, isOwner }) => {
    const { t } = useTranslation();


    const renderDetail = (detailType: Detail, value: any): JSX.Element | null => {
        if (value !== undefined && value !== null) {
            switch (detailType) {
                case 'removable':
                    return value ? <li key={detailType}>{t('ad.removable')}</li> : null;
                case 'numberOfItems':
                    return <li key={detailType}>{sprintf(t('ad.soldBy'), value)}</li>
                case 'numberOfSeats':
                    return <li key={detailType}>{sprintf(t('ad.seats'), value)}</li>
                case 'state':
                    return <li key={detailType}>{t(`article_state.${value}.buyLabel`)}</li>
                case 'brand':
                    const brand = (value as BrandAttribute);
                    return <li key={detailType}>{brand.hasBrand ? brand.name : t(`ad.noBrand`)}</li>
                case 'warranty':
                    const warranty = (value as WarrantyDto);
                    const warrantyDate = warranty.hasWarranty && warranty.warrantyEndDate ? moment(apiToUtc(warranty.warrantyEndDate)).local().format('MM-YYYY') : '';
                    return warranty.hasWarranty ? <li key={detailType}>{sprintf(t(`as.warranty`), warrantyDate)}</li> : null;
            }
        } else {
            return null;
        }
    }

    const renderDetails = (elements: (JSX.Element | null)[]) => (elements
        .filter((e: JSX.Element | null): e is JSX.Element => e !== null)
        .reduce<JSX.Element[]>((arr, e) => (arr.length > 0 ? [...arr, <li key={`sep${arr.length}`} className="sep"></li>, e] : [...arr, e]), []));

    const furnitureDetails = renderDetails([
        renderDetail('removable', article.removable),
        renderDetail('numberOfItems', article.numberOfItems),
        renderDetail('numberOfSeats', article.numberOfSeats)]);

    const stateDetails = renderDetails([
        renderDetail('brand', article.brand),
        renderDetail('state', article.state),
        renderDetail('warranty', article.warranty)
    ]);

    return (
        <YouzdScreen className="grey" header={true}>
            <div className="ad-container">
                <div className="ad-photo-wrapper">
                    <PhotoHeader photos={article.photos} mode="carousel" />
                    <Likes nb={article.nbLikes} isLiked={liked} canLike={canLike} onChange={setLiked} />
                </div>
                <section className="main">
                    <header>
                        <h2>{article.title}</h2>
                        <Share title={sprintf(t('ad.shareTitle'), article.title)} media={getImageUrl(article.photos[0])} />
                    </header>
                    <p>{article.city} {article.cp}</p>
                    <Price startPrice={article.startPrice} endPrice={article.endPrice} price={article.price} />
                    {showTransactions && <Link to={`/checkout/${article.uid}`}>
                        <Button className="buy" arrowIcon={BacketIcon}>{t('ad.buy')}</Button>
                    </Link>}
                    {!showTransactions && !isOwner && <p className="no-transaction">{t('ad.noTransaction')}</p>}
                    {isOwner && <p className="no-transaction">{t('ad.withYouzdCommission')}</p>}
                    <h3>{t('ad.shippingOptions')}</h3>
                    <ArticleShippingOptionsContainer article={article} />
                </section>
                <section>
                    <header>
                        <h3>{t('ad.details')}</h3>
                    </header>
                    {article.width && article.width.value && article.height && article.depth && <>
                        <article>
                            {t('ad.height')}: {article.height.value} {article.height.unit}, {t('ad.width')}: {article.width.value} {article.width.unit}, {t('ad.depth')}: {article.depth.value} {article.depth.unit} <span className='precision'>{t(`ad.precision.${article.precision}`)}</span>
                            {article.dimensionsComment && <p className="user-comment">{article.dimensionsComment}</p>}
                        </article>
                    </>
                    }
                    {furnitureDetails.length > 0 && <>
                        {article.width && article.width.value && <div className="divider"></div>}
                        <article>
                            <ul className="details">{furnitureDetails}</ul>
                        </article>
                    </>}
                    {stateDetails.length > 0 && <>
                        <div className="divider"></div>
                        <article>
                            <ul className="details">{stateDetails}</ul>
                        </article>
                    </>}
                    <div className="divider"></div>
                    <article className="description">
                        <span>{t('ad.description')} :</span>
                        <p className="user-comment">{article.description}</p>
                    </article>
                </section>
                <section>
                    <header><h3>{t('ad.owner')}</h3></header>
                    <article className="owner">
                        <UserInfo photo={article.owner.photo} nickName={article.owner.nickName} title={article.owner.title} />
                        {!isOwner && <Link to={`/chat/article/${article.uid}`}><Button mode='secondary'>{t('ad.ask')}</Button></Link>}
                    </article>
                </section>

            </div>

        </YouzdScreen>
    );
}

export default Ad;