import React from 'react';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';
import './Price.scss';

type ComponentProps = {
    startPrice: number,
    endPrice: number,
    price: number | undefined
}

type PriceMode = 'new' | 'normal' | 'fixed' | 'last';

const getPriceMode = (startPrice: number, endPrice: number, price: number | undefined): PriceMode => {
    if (startPrice === endPrice) {
        return 'fixed';
    }
    if (startPrice === price) {
        return 'new';
    }
    if (!price) {
        return 'last';
    }
    return 'normal';
}

const priceAmountFormater = (price: number | undefined) => {
    return price ? <>{sprintf('%d', Math.floor(price))}<span className="decimal">,{sprintf('%02.0f', (price % 1) * 100)}</span>&nbsp;&euro;</> : null;
}

const Price: React.FC<ComponentProps> = ({ startPrice, endPrice, price }) => {
    const mode = getPriceMode(startPrice, endPrice, price);
    const { t } = useTranslation();
    let priceTag = ((mode: PriceMode) => {
        switch (mode) {
            case 'normal':
                return (
                    <>
                        <div className="start"><div className='striked'>{priceAmountFormater(startPrice)}</div></div>
                        <div className="current">
                            <div className={`tag`}>{t(`price.mode.${mode}`)}</div>
                            <div className="current-price">{priceAmountFormater(price)}</div>
                        </div>
                    </>
                )
            case 'new':
                return (
                    <>
                        <div className="pad"></div>
                        <div className="current">
                            <div className={`tag`}>{t(`price.mode.${mode}`)}</div>
                            <div className="current-price">{priceAmountFormater(startPrice)}</div>
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="pad"></div>
                        <div className="current">
                            <div className={`tag`}>{t(`price.mode.${mode}`)}</div>
                            <div className="current-price">{priceAmountFormater(endPrice)}</div>
                        </div>
                    </>
                )
        }
    })(mode);
    return (<div className={`price-container ${mode}`}>{priceTag}</div>)
}

export default Price;