import { MangoPayCardRegisterDto, MangoPayUserDto, TransactionDetailsDto, UserDto } from '@youzd/ref-data';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addApiErrors, AnyThunkDispatch } from '../../redux/actions';
import { AppState } from '../../redux/reducer';
import { doGetCardRegister, doLoadTransaction, doPayWithCardRegister } from '../../redux/transactionActions';
import { doLoadUser } from '../../redux/userActions';
import { ApiError, emptyApiErrorsArray } from '../../service/api';
import { CardData, getCardToken } from '../../service/mangopay';
import Loader from '../template/Loader';
import YouzdScreen from '../template/YouzdScreen';
import AdCardForm from './AddCardForm';
import TransactionDetails from './TransactionDetails';

type PropsFromState = {
    user: UserDto | undefined,
    errors: ApiError[],
    cardRegisterData: MangoPayCardRegisterDto | undefined,
    loading: boolean,
    transaction: TransactionDetailsDto | undefined,
}

type DispatchProps = {
    getRegisterCardData: (mangoUser: MangoPayUserDto) => void,
    payWithResgisterCard: (cardRegisterdata: MangoPayCardRegisterDto, cardData: CardData) => void
    loadUser: () => void,
    loadTransaction: () => void,
}

type ComponentProps = PropsFromState & DispatchProps;

const PaymentContainer: React.FC<ComponentProps> = ({ user, transaction, cardRegisterData, getRegisterCardData, errors, loading, loadUser, loadTransaction, payWithResgisterCard }) => {
    const [userUid, setUserUid] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (!user) {
            loadUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user && user.uid !== userUid && !transaction) {
            setUserUid(user.uid);
            loadTransaction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    return user && transaction ? (
        <YouzdScreen header={true} className={"grey"}>
            <AdCardForm
                user={user}
                cardRegisterData={cardRegisterData}
                getRegisterCardData={getRegisterCardData}
                errors={errors}
                loading={loading}
                payWithResgisterCard={payWithResgisterCard}
                transaction={transaction}
            ><TransactionDetails transaction={transaction} /></AdCardForm>
        </YouzdScreen>
    ) : (<Loader />);
}

const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ uid: string }>): PropsFromState => {
    const theUser = state.user;
    const errors = state.apiErrors.filter(e => e.operation === 'GET_CARD_REGISTER' || e.operation === 'PAY_CARD_REGISTER');
    return {
        user: theUser,
        cardRegisterData: state.cardRegisterData,
        errors: errors || emptyApiErrorsArray,
        loading: state.pendingOperations.find(o => o === 'GET_CARD_REGISTER' || o === 'PAY_CARD_REGISTER') !== undefined,
        transaction: state.transaction,
    }
}

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps<{ uid: string }>): DispatchProps => ({
    getRegisterCardData: (mangoUser) => {
        dispatch(doGetCardRegister(mangoUser, routeProps));
    },
    payWithResgisterCard: (cardRegisterdata: MangoPayCardRegisterDto, cardData: CardData) => {
        getCardToken(cardData, cardRegisterdata.cardRegistrationURL).then((encryptedCard: string) => {
            if (encryptedCard.indexOf('data=') === 0) {
                dispatch(doPayWithCardRegister({ ...cardRegisterdata, registerData: encryptedCard }, routeProps));
            } else {
                dispatch(addApiErrors([{
                    operation: 'PAY_CARD_REGISTER',
                    errorType: 'FAILED',
                    errorMessage: 'technical'
                }]))
            }
        })
    },
    loadUser: () => {
        dispatch(doLoadUser(routeProps, true, '/add-card'));
    },
    loadTransaction: () => {
        dispatch(doLoadTransaction(routeProps.match.params.uid, routeProps));
    }
});


export default withRouter(connect(mapStateToProps, dispatchToProps)(PaymentContainer));