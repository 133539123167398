import React, { useState, useEffect } from 'react';
import { ClockPriceDto } from '@youzd/ref-data';
import moment, { Moment, utc } from 'moment';
import { useTranslation } from 'react-i18next';
import './ClockPriceEdit.scss';
import { FormMessageWithField, hasError, getFieldMessages, MessageLevel } from '../../helpers/forms';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import Caption from '../controls/Caption';
import FormGroup from '../form/FormGroup';
import { apiToUtc, utcToApi, html5DateToUtc } from '../../helpers/time';
import FormField from '../form/FormField';



type ComponentProps = {
    price: ClockPriceDto | undefined,
    onPriceChanged: (newPrice: ClockPriceDto) => void,
    onFocus?: () => void,
}

type FormField = 'start' | 'end' | 'until' | 'offset';

type ComponentState = {
    priceStart: number | undefined,
    priceEnd: number | undefined,
    startDate: Moment,
    endDate: Moment,
    offset: number | undefined,
}

const ClockPriceEdit: React.FC<ComponentProps> = ({ price, onPriceChanged, onFocus }) => {
    const { t } = useTranslation();
    const priceToState = (price: ClockPriceDto | undefined) => {
        const defaultStartDate = price && price.startDate ? apiToUtc(price.startDate) : utc();
        const defaultEndDate = price && price.endDate ? apiToUtc(price.endDate) : moment(defaultStartDate).add(15, 'days').endOf('month');
        return {
            priceStart: price ? price.startPrice : undefined,
            priceEnd: price ? price.endPrice : undefined,
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            offset: price ? price.offsetDays : 0,
        }
    }

    const [state, setState] = useState<ComponentState>(priceToState(price));
    const [messages, setMessages] = useFormMessageState<FormField>();

    useEffect(() => {
        setState(priceToState(price));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [price])

    const checkState = (state: ComponentState) => {
        const messages: FormMessageWithField<FormField>[] = [];

        if (state.priceEnd && state.priceEnd < 1) {
            messages.push({
                relatedFields: ['end'],
                message: { text: t('add.price.error.endPriceTooLow'), level: 'error' }
            });
        }
        if (state.priceStart && state.priceEnd && state.priceEnd > state.priceStart) {
            messages.push({
                relatedFields: ['start', 'end'],
                message: { text: t('add.price.error.endPriceBiggerThanStart'), level: 'error' }
            });
        }
        if (state.priceStart && state.priceEnd && state.priceEnd === state.priceStart) {
            messages.push({
                relatedFields: ['start', 'end'],
                message: { text: t('add.price.warning.noDecrease'), level: 'warning' }
            });
        }

        if (state.endDate && state.endDate.isBefore(state.startDate)) {
            messages.push({
                relatedFields: ['until'],
                message: { text: t('add.price.error.endDatePast'), level: 'error' }
            });
        }
        //use 23 hours in case we are juste before DST change
        if (state.endDate && state.endDate.diff(state.startDate, 'hours') < 23) {
            messages.push({
                relatedFields: ['until'],
                message: { text: t('add.price.error.shorterThan24h'), level: 'error' }
            });
        }
        if (state.endDate && state.offset && moment(state.startDate).add(state.offset, 'days').isAfter(state.endDate)) {
            messages.push({
                relatedFields: ['until', 'offset'],
                message: { text: t('add.price.error.offsetTooLate'), level: 'error' }
            });
        }

        if (!hasError(messages) && state.priceStart && state.priceEnd && state.endDate) {
            onPriceChanged({
                startPrice: state.priceStart,
                endPrice: state.priceEnd,
                startDate: utcToApi(state.startDate),
                endDate: utcToApi(state.endDate),
                offsetDays: state.offset || 0
            })
        }
        setMessages(messages);
    }

    const changeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPrice = parseFloat(event.target.value);
        const newState = { ...state, priceStart: newPrice ? newPrice : undefined };
        setState(newState);
        checkState(newState);
    }

    const changeEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPrice = parseFloat(event.target.value);
        const newState = { ...state, priceEnd: newPrice ? newPrice : undefined };
        setState(newState);
        checkState(newState);
    }

    const changeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEndDate = event.target.value ? html5DateToUtc(event.target.value) : undefined;
        if (newEndDate) {
            newEndDate.add(moment(state.startDate).diff(moment(state.startDate).startOf('day')));
            const newState = { ...state, endDate: newEndDate };
            setState(newState);
            checkState(newState);
        }
    }

    const isPriceMessage = (m: FormMessageWithField<FormField>, level: MessageLevel) => {
        return m.message.level === level && (
            m.relatedFields.includes('start') || m.relatedFields.includes('end')
        );
    }

    const pricesErrorMessages = messages.filter(m => isPriceMessage(m, 'error')).map((e, index) => (
        <li key={index}>{e.message.text}</li>
    ));
    const pricesWarningMessages = messages.filter(m => isPriceMessage(m, 'warning')).map((e, index) => (
        <li key={index}>{e.message.text}</li>
    ));

    return (
        <div className="price-edit">
            <header>
                <h2>{t('add.price.title')}</h2>
                <Caption safeHtml={t('add.price.caption')} />
            </header>
            <FormGroup className="prices" layout="inline">
                <FormField
                    className="start"
                    label={t('add.price.start')}
                    messages={getFieldMessages('start', messages)}
                    hideMessages={true}>
                    <input
                        type="number"
                        value={state.priceStart || ''}
                        onChange={changeStart}
                        placeholder="€" />
                </FormField>
                <FormField
                    className="end"
                    label={t('add.price.end')}
                    messages={getFieldMessages('end', messages)}
                    hideMessages={true}>
                    <input
                        type="number"
                        value={state.priceEnd || ''}
                        onChange={changeEnd}
                        placeholder="€" />
                </FormField>
            </FormGroup>
            <div className="messages">
                <ul className="errors">{pricesErrorMessages}</ul>
                <ul className="warnings">{pricesWarningMessages}</ul>
            </div>
            <FormField
                className="until"
                label={t('add.price.until')}
                messages={getFieldMessages('until', messages)}
                layout="inline">
                <div className="input-wrapper">
                    <input
                        type="date"
                        value={state.endDate?.format(moment.HTML5_FMT.DATE) || ''}
                        onChange={changeEndDate} />
                </div>
            </FormField>
        </div>
    );
}

export default ClockPriceEdit;