import { ShippingSize, sizesEligibleToPickup, youzdDeliveryPrices, ShippingOptionNames, ShippingOptionName } from "@youzd/ref-data";

export const isEligibleToYouzd = (shipSize: ShippingSize | undefined, zipCode: string | undefined, youzdZipCodes: string[] | undefined) => {
    const isShipsizeEligible = shipSize && youzdDeliveryPrices.map(p => p.size).includes(shipSize);
    const isPostCodeEligible = (youzdZipCodes !== undefined && zipCode !== undefined && youzdZipCodes.includes(zipCode));
    return isPostCodeEligible && isShipsizeEligible;
}

export const isEligibleToPickup = (shipSize: ShippingSize | undefined, zipCode: string | undefined, youzdZipCodes: string[] | undefined) => {
    const isShipsizeEligible = shipSize && sizesEligibleToPickup.includes(shipSize);
    const isPostCodeEligible = (youzdZipCodes !== undefined && zipCode !== undefined && youzdZipCodes.includes(zipCode));
    return isPostCodeEligible && isShipsizeEligible;
}

export const getEligibleShipingOptions = (shipSize: ShippingSize | undefined, zipCode: string | undefined, youzdZipCodes: string[]) => {
    return Object.keys(ShippingOptionNames).filter(o => {
        const optionType = o as ShippingOptionName;
        switch (optionType) {
            case ShippingOptionNames.youzdDelivery:
                return isEligibleToYouzd(shipSize, zipCode, youzdZipCodes);
            case ShippingOptionNames.pickup:
                return isEligibleToPickup(shipSize, zipCode, youzdZipCodes);
            default:
                return true;
        }
    });

}