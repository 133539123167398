import React from 'react';
import { connect } from 'react-redux';
import { ShippingOption, ShippingSize } from '@youzd/ref-data';
import ShippingOptionSelect from './ShippingOptionSelect';
import { AppState } from '../../redux/reducer';


type PropsFromState = {
    youzdZipCodes?: string[]

}

type OwnProps = {
    shippingOptions: ShippingOption[],
    onOptionsChanged: (options: ShippingOption[]) => void,
    endPrice?: number,
    zipCode?: string,
    shipSize?: ShippingSize,
}

type ComponentProps = PropsFromState & OwnProps;

const ShippingOptionsContainer: React.FC<ComponentProps> = ({ shippingOptions, onOptionsChanged, zipCode, youzdZipCodes, endPrice, shipSize }) => {
    return <ShippingOptionSelect
        shippingOptions={shippingOptions}
        onOptionsChanged={onOptionsChanged}
        zipCode={zipCode}
        youzdZipCodes={youzdZipCodes}
        endPrice={endPrice}
        shipSize={shipSize}
    />;
}

const mapStateToProps = (state: AppState): PropsFromState => ({
    youzdZipCodes: state.youzdDeliveryZipCodes
})

export default connect(mapStateToProps)(ShippingOptionsContainer);