import React from 'react';
import { useTranslation } from 'react-i18next';
import loaderSvg from '../../assets/loader.svg';
import './Loader.scss';

const Loader: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="loader-container">
            <img src={loaderSvg} alt="Oh hang on a minute" />
            {t('loading')}
        </div>)
}

export default Loader;
