import { MangoPayCardRegisterDto, MangoPayUserDto, TransactionDetailsDto, UserDto } from '@youzd/ref-data';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sprintf } from 'sprintf-js';
import SafeIcon from '../../assets/icons/safe.svg';
import nationalities from '../../assets/nationalities.json';
import { addError, checkValidLuhn, createError, FormMessagesWithField, getFieldErrors, getFieldMessages, hasError, MessageLevel } from '../../helpers/forms';
import { apiToUtc, html5DateToUtc, utcToApi, utcToHtml5Date } from '../../helpers/time';
import { useFormMessagesState } from '../../hooks/useFormMessagesState';
import { ApiError } from '../../service/api';
import { CardData } from '../../service/mangopay';
import Button from '../controls/Button';
import CheckBox from '../controls/CheckBox';
import Cta from '../controls/Cta';
import Form from '../form/Form';
import FormBody from '../form/FormBody';
import FormField from '../form/FormField';
import FormGroup from '../form/FormGroup';
import Loader from '../template/Loader';
import Modal from '../template/Modal';
import './AddCardForm.scss';

type ComponentProps = {
    user: UserDto,
    errors: ApiError[],
    loading: boolean,
    transaction: TransactionDetailsDto,
    cardRegisterData: MangoPayCardRegisterDto | undefined,
    getRegisterCardData: (mangoUser: MangoPayUserDto) => void,
    payWithResgisterCard: (cardRegisterdata: MangoPayCardRegisterDto, cardData: CardData) => void
}



export type FormField = 'firstName' | 'lastName' | 'birthDate' | 'countryOfResidence' | 'cardNumber' | 'expiryMonth' | 'expiryYear' | 'cvc';

const AddCardForm: React.FC<ComponentProps> = ({ user, cardRegisterData, errors, getRegisterCardData, loading, payWithResgisterCard, transaction, children }) => {
    const apiErrorsToMessages = (errors: ApiError[]): FormMessagesWithField<FormField> => errors.filter(e => e.operation === 'PAY_CARD_REGISTER').map(e => {
        switch (e.errorType) {
            case 'INVALID':
                return ((field) => {
                    switch (field) {
                        case 'cardNumber':
                        case 'cvc':
                            return {
                                relatedFields: [field as FormField],
                                message: {
                                    level: 'error' as MessageLevel,
                                    text: t(`addCard.error.invalid${field}`)

                                }
                            }
                        case 'expiryDate':
                            return {
                                relatedFields: ['expiryMonth', 'expiryYear'] as FormField[],
                                message: {
                                    level: 'error' as MessageLevel,
                                    text: t(`addCard.error.invalid${field}`)

                                }
                            }
                        default:
                            return {
                                relatedFields: ['all'] as (FormField | 'all')[],
                                message: {
                                    level: 'error' as MessageLevel,
                                    text: t('addCard.error.invalidUnkown')
                                }
                            }
                    }
                })(e.errorMessage.match(/Invalid:(.*)/)?.[1]);

            default: {
                return {
                    relatedFields: ['all'],
                    message: {
                        level: 'error',
                        text: t('addCard.error.invalidUnkown')
                    }
                }
            }
        }
    });
    const { t } = useTranslation();
    const userToMangoUser = (user: UserDto): MangoPayUserDto => ({
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        nationality: user.nationality || 'FR',
        countryOfResidence: user.countryOfResidence || '',
        birthDate: user.birthDate || ''

    })
    const [mangoUser, setMangoUser] = useState<MangoPayUserDto>(userToMangoUser(user));
    const [messages, setMessages] = useFormMessagesState<FormField>(apiErrorsToMessages(errors));
    const [prevErrors, setPrevErrors] = useState(errors.map(e => e.operation + e.errorType + e.errorMessage).join());
    const [secure, setSecure] = useState(false);
    const [saveCard, setSaveCard] = useState(false);

    const [cardInfo, setCardInfo] = useState<CardData>({
        cardNumber: '497248583040004',
        cardExpirationDate: '0221',
        cardCvx: '123',
        data: cardRegisterData?.registerData || '',
        accessKeyRef: cardRegisterData?.accessKeyRef || '',
    });

    useEffect(() => {
        const mangoUser = userToMangoUser(user);
        if (!user.mangoPayId) {
            setMangoUser(mangoUser);
        } else if (!cardRegisterData) {
            getRegisterCardData(mangoUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        const currentErrorsHash = errors.map(e => e.operation + e.errorType + e.errorMessage).join();
        if (errors.length > 0 && prevErrors !== currentErrorsHash) {
            setPrevErrors(currentErrorsHash);
            getRegisterCardData(mangoUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])


    useEffect(() => {
        if (cardRegisterData) {
            setCardInfo({ ...cardInfo, data: cardRegisterData.data || '', accessKeyRef: cardRegisterData.accessKeyRef });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardRegisterData])

    const changeFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = e.target.value.trimLeft();
        setMangoUser({ ...mangoUser, firstName });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('firstName'))];
        if (firstName.length === 0) {
            setMessages([...messages, createError(['firstName'], t('addCard.error.missingFirstName'))])
        } else {
            setMessages(otherErrors);
        }
    }

    const changeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = e.target.value.trimLeft();
        setMangoUser({ ...mangoUser, lastName });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('lastName'))];
        if (lastName.length === 0) {
            setMessages([...otherErrors, createError(['lastName'], t('addCard.error.missingLastName'))])
        } else {
            setMessages(otherErrors);
        }
    }
    const changeBirthDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const birthDateUtc = html5DateToUtc(e.target.value);
        setMangoUser({ ...mangoUser, birthDate: utcToApi(birthDateUtc) });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('birthDate'))];
        if (moment.utc().diff(birthDateUtc, 'years') < 18) {
            setMessages([...otherErrors, createError(['birthDate'], t('addCard.error.mustBe18'))])
        } else {
            setMessages(otherErrors);
        }
    }

    const changeNationality = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMangoUser({ ...mangoUser, nationality: e.target.value });
    }

    const changeCountryOfResidence = (checked: boolean) => {
        const countryOfResidence = checked ? 'FR' : '';
        setMangoUser({ ...mangoUser, countryOfResidence });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('countryOfResidence'))];
        if (countryOfResidence !== 'FR') {
            setMessages([...otherErrors, createError(['countryOfResidence'], t('addCard.error.mustBeFrenchResident'))])
        } else {
            setMessages(otherErrors);
        }
    }

    const checkMangoUser = () => {
        let messages: FormMessagesWithField<FormField> = [];
        if (mangoUser.firstName.length === 0) {
            messages = addError(messages, ['firstName'], t('addCard.error.missingFirstName'));
        }
        if (mangoUser.lastName.length === 0) {
            messages = addError(messages, ['lastName'], t('addCard.error.missingLastName'));
        }
        if (mangoUser.birthDate.length === 0) {
            messages = addError(messages, ['birthDate'], t('addCard.error.missingBirthDate'));
        }
        if (mangoUser.countryOfResidence !== 'FR') {
            messages = addError(messages, ['countryOfResidence'], t('addCard.error.mustBeFrenchResident'));
        }
        setMessages(messages);
        return !hasError(messages);
    }

    const formatedCardNumber = (cardNumber: string) => {
        return cardNumber.match(/.{1,4}/g)?.join(' ') || '';
    }

    const changeCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const caretStart = e.target.selectionStart || 0;
        const caretEnd = e.target.selectionEnd || 0;
        const cardNumber = e.target.value.replace(/\s/g, '');
        setCardInfo({ ...cardInfo, cardNumber });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('cardNumber'))];
        if (cardNumber.length === 0) {
            setMessages([...otherErrors, createError(['cardNumber'], t('addCard.error.missingCardNumber'))])
        } else if (cardNumber.length < 16 || !cardNumber.match(/[0-9]{16}/) || !checkValidLuhn(cardNumber)) {
            setMessages([...otherErrors, createError(['cardNumber'], t('addCard.error.invalidcardNumber'))])
        } else {
            setMessages(otherErrors);
        }
        e.target.value = formatedCardNumber(cardNumber);
        e.target.setSelectionRange(caretStart, caretEnd);
    }

    const changeCardExpiryMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const expiryMonth = e.target.value.trimLeft();
        const newExpiry = expiryMonth.length > 0 ? expiryMonth + cardInfo.cardExpirationDate.substring(2) : '';
        setCardInfo({ ...cardInfo, cardExpirationDate: newExpiry });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('expiryMonth'))];
        if (expiryMonth.length === 0) {
            setMessages([...otherErrors, createError(['expiryMonth'], t('addCard.error.missingExpiryMonth'))])
        } else {
            setMessages(otherErrors);
        }
    }

    const changeCardExpiryYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const expiryYear = e.target.value.trimLeft();
        const newExpiry = sprintf('%2s%2s', cardInfo.cardExpirationDate.substring(0, 2), expiryYear);
        setCardInfo({ ...cardInfo, cardExpirationDate: newExpiry });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('expiryYear'))];
        if (expiryYear.length === 0) {
            setMessages([...otherErrors, createError(['expiryYear'], t('addCard.error.missingExpiryYear'))])
        } else {
            setMessages(otherErrors);
        }
    }

    const changeCardCvc = (e: React.ChangeEvent<HTMLInputElement>) => {
        const cvc = e.target.value.trimLeft();
        setCardInfo({ ...cardInfo, cardCvx: cvc });
        const otherErrors = [...messages.filter(m => !m.relatedFields.includes('cvc'))];
        if (cvc.length === 0) {
            setMessages([...otherErrors, createError(['cvc'], t('addCard.error.missingCvc'))])
        } else if (cvc.length < 3 || !cvc.match(/[0-9]{3}/)) {
            setMessages([...otherErrors, createError(['cvc'], t('addCard.error.invalidcvc'))])
        } else {
            setMessages(otherErrors);
        }
    }

    const checkCardInfo = () => {
        let messages: FormMessagesWithField<FormField> = [];
        if (cardInfo.cardNumber.length === 0) {
            messages = addError(messages, ['cardNumber'], t('addCard.error.missingCardNumber'));
        }
        if (cardInfo.cardNumber.length < 16 || !cardInfo.cardNumber.match(/[0-9]{16}/) || !checkValidLuhn(cardInfo.cardNumber)) {
            messages = addError(messages, ['cardNumber'], t('addCard.error.invalidcardNumber'));
        }
        if (cardInfo.cardExpirationDate.substring(0, 2).trim().length === 0) {
            messages = addError(messages, ['expiryMonth'], t('addCard.error.missingExpiryMonth'));
        }
        if (cardInfo.cardExpirationDate.substring(2).trim().length === 0) {
            messages = addError(messages, ['expiryYear'], t('addCard.error.missingExpiryYear'));
        }
        if (cardInfo.cardExpirationDate.length === 4 && cardInfo.cardExpirationDate.substring(0, 2).trim().length !== 0) {
            const checkExpiry = cardInfo.cardExpirationDate.substring(2) + cardInfo.cardExpirationDate.substring(0, 2);
            const currentMonth = moment().format('YYMM');
            if (checkExpiry < currentMonth) {
                messages = addError(messages, ['expiryYear'], t('addCard.error.expiryInPast'));
            }
        }
        if (cardInfo.cardCvx.length === 0) {
            messages = addError(messages, ['cvc'], t('addCard.error.missingCvc'));
        }
        if (cardInfo.cardCvx.length < 3 || !cardInfo.cardCvx.match(/[0-9]{3}/)) {
            messages = addError(messages, ['cvc'], t('addCard.error.invalidcvc'));
        }
        setMessages(messages);
        return !hasError(messages);
    }

    const ctaAction = () => {
        if (!cardRegisterData) {
            if (checkMangoUser()) {
                getRegisterCardData(mangoUser);
            }
        } else {
            if (checkCardInfo()) {
                payWithResgisterCard({ ...cardRegisterData, saveCard, transactionUid: transaction.uid }, cardInfo);
            }
        }
    }

    const cardMonthOptions = [<option value={''} key="0">{t('addCard.expiryMonthPlaceholder')}</option>, ...Array.from(Array(12).keys()).map(m => {
        const v = sprintf('%02d', m + 1);
        return <option key={v} value={v}>{v}</option>
    })];

    const cardYearOptions = [<option value={''} key="0">{t('addCard.expiryYearPlaceholder')}</option>, ...Array.from(Array(3).keys()).map(y => {
        const v = moment().add(y, 'years').format('YY');
        return <option key={v} value={v}>{v}</option>
    })];

    const toggleSaveCard = () => {
        setSaveCard(!saveCard);
    }

    const expiryAndCvcMessages = [
        ...messages.filter(m => m.message.level === 'error' && (
            m.relatedFields.includes('all') ||
            m.relatedFields.includes('expiryMonth') ||
            m.relatedFields.includes('expiryYear') ||
            m.relatedFields.includes('cvc'))).map((m, index) => <li key={`exp-${index}`}>{m.message.text}</li>)
    ];

    const expiryAndCvcErrors = expiryAndCvcMessages.length > 0 ? <ul className="errors">{expiryAndCvcMessages}</ul> : null;

    return !cardRegisterData && user.mangoPayId ? <Loader /> : <Form className="add-card">
        <FormBody>
            <div className="transaction-summary">
                {children}
            </div>
            <div className="transaction-payment">
                <h2>{t('addCard.title')}</h2>
                <Button arrowIcon={SafeIcon} mode='secondary' onClick={() => setSecure(true)} className="secure">{t('addCard.secure')}</Button>
                {secure && <Modal contentId="secure" dismiss={() => setSecure(false)}>
                    <p className='intro' dangerouslySetInnerHTML={{ __html: t('addCard.intro') }} />
                    <Button mode="secondary" onClick={() => setSecure(false)}>{t('popup.close')}</Button>
                </Modal>}
                {!cardRegisterData && <>
                    <p className='intro' dangerouslySetInnerHTML={{ __html: t('addCard.user') }} />
                    <FormField
                        className="first-name"
                        label={t("profile.edit.firstname")}
                        messages={getFieldMessages('firstName', messages)}>
                        <input
                            type="text"
                            value={mangoUser.firstName}
                            onChange={changeFirstname}
                            placeholder={t("profile.edit.firstname")}
                            disabled={loading}
                        />
                    </FormField>
                    <FormField
                        className="last-name"
                        label={t("profile.edit.name")}
                        messages={getFieldMessages('lastName', messages)}>
                        <input
                            type="text"
                            value={mangoUser.lastName}
                            onChange={changeLastName}
                            placeholder={t("profile.edit.name")}
                            disabled={loading}
                        />
                    </FormField>
                    <FormField
                        className="birthdate"
                        label={t("profile.edit.birthdate")}
                        messages={getFieldMessages('birthdate', messages)}>
                        <input
                            type="date"
                            value={mangoUser.birthDate ? utcToHtml5Date(apiToUtc(mangoUser.birthDate)) : ''}
                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => { if (!mangoUser.birthDate) e.target.value = utcToHtml5Date(moment('1990-01-01')) }}
                            onChange={changeBirthDate}
                            disabled={loading}
                        />
                    </FormField>
                    <FormField
                        className="nationality"
                        label={t("profile.edit.nationality")}>
                        <select
                            value={mangoUser.nationality}
                            onChange={changeNationality}
                            disabled={loading}
                        >
                            {((nationalities as any).list as { iso: string, label: string }[]).map(n => <option key={n.iso} value={n.iso}>{n.label}</option>)}
                        </select>
                    </FormField>
                    <FormField
                        className="country-of-residence"
                        label={t("profile.edit.countryOfResidence")}
                        layout='reverse'
                        messages={getFieldMessages('countryOfResidence', messages)}
                        labelClick={() => { changeCountryOfResidence(mangoUser.countryOfResidence !== 'FR') }}>
                        <CheckBox checked={mangoUser.countryOfResidence === 'FR'} onChecked={changeCountryOfResidence} disabled={loading} />
                    </FormField>
                </>}
                {cardRegisterData && <>
                    <FormField
                        className="card-number"
                        label={t("addCard.cardNumber")}
                        messages={getFieldMessages('cardNumber', messages)}>
                        <input
                            type="text"
                            defaultValue={formatedCardNumber(cardInfo?.cardNumber || '')}
                            onChange={changeCardNumber}
                            placeholder={t("addCard.cardNumberPlaceholder")}
                            disabled={loading}
                        />
                    </FormField>
                    <FormGroup className="expiry-and-cvc" layout='inline'>
                        <FormGroup className="expiry" layout="inline">
                            <div className="expiry-label">{t('addCard.expiry')}</div>
                            <FormField
                                className="expiry-month"
                                label=""
                                messages={getFieldErrors('expiryMonth', messages)}
                                hideMessages={true}>
                                <select
                                    value={cardInfo?.cardExpirationDate.substring(0, 2).trim() || ''}
                                    onChange={changeCardExpiryMonth}
                                >
                                    {cardMonthOptions}
                                </select>
                            </FormField>
                            <div className="expiry-sep">/</div>
                            <FormField
                                className="expiry-year"
                                label=""
                                messages={getFieldErrors('expiryYear', messages)}
                                hideMessages={true}>
                                <select
                                    value={cardInfo?.cardExpirationDate.substring(2) || ''}
                                    onChange={changeCardExpiryYear}
                                >
                                    {cardYearOptions}
                                </select>
                            </FormField>
                        </FormGroup>
                        <FormField
                            className="card-cvc"
                            label={t('addCard.cvc')}
                            messages={getFieldErrors('cvc', messages)}
                            hideMessages={true}
                        >
                            <input
                                type="text"
                                value={cardInfo?.cardCvx || ''}
                                onChange={changeCardCvc}
                                placeholder={t("addCard.cvcPlaceholder")}
                                disabled={loading}
                            />
                        </FormField>
                        {expiryAndCvcErrors}
                    </FormGroup>
                    <FormField
                        className="save-card"
                        label={t('addCard.saveCard')}
                        labelClick={toggleSaveCard}
                        layout='reverse'>
                        <CheckBox checked={saveCard} onChecked={toggleSaveCard} disabled={loading} />
                    </FormField>

                </>}
            </div>
        </FormBody>
        <Cta label={cardRegisterData ? t('addCard.pay') : t('addCard.save')} onClick={ctaAction} />
    </Form>
}

export default AddCardForm;
