
export const MANGOPAY_CARD_EXPIRY_FORMAT = "MMYY";

export type CardData = {
    cardNumber: string,
    cardExpirationDate: string,
    cardCvx: string,
    data: string,
    accessKeyRef: string,
}

export const getCardToken = (card: CardData, url: string) => new Promise<string>(async (resolve, reject) => {
    const data = new URLSearchParams();
    Object.entries(card).forEach((entry) => { const [key, value] = entry; data.append(key, value) });
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data
    };
    await fetch(url, options).then(async r => {
        if (r.ok) {
            resolve(await r.text());
        } else {
            reject(r.statusText);
        }
    })
})
