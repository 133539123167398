import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { getFilesDataUrl, getThumbnail, rotateImage, AnyImageKind, PhotoData } from '../../helpers/photos';
import Photo from './Photo';
import DraggablePhotoEditorElement from './DraggablePhotoEditorElement';
import StaticPhotoEditorElement from './StaticPhotoEditorElement';
import { useTranslation } from 'react-i18next';
import './PhotosEditor.scss';
import rotateIcon from '../../assets/controls/rotate.svg';
import CameraIcon from '../../assets/controls/add-photo.svg';
import Modal from '../template/Modal';
import Button from './Button';
import { Preview } from 'react-dnd-multi-backend';
import Carousel from './Carousel';
import Loader from '../../assets/loader.svg';
import AddIcon from '../../assets/controls/addBig.svg';



type realDndPreviewProps = {
    itemType: string,
    item: any,
    style: CSSProperties,
}

type ComponentProps = {
    photos: AnyImageKind[];
    onPhotoChanged: (photos: AnyImageKind[]) => void,
    limit: number,
    label?: string,
    min?: number,
    mode: 'stripe' | 'gallery' | 'profile',
    profileIcon?: string
}

const PhotoEditor: React.FC<ComponentProps> = ({ photos, onPhotoChanged, limit, label, min, children, mode, profileIcon }) => {
    const { t } = useTranslation();
    const [nbAdded, setNbAdded] = useState(0);
    const [zoomed, setZoomed] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const photosSlider = useRef<HTMLUListElement>(null);
    const [nbLoading, setNbLoading] = useState(0);

    useEffect(() => {
        if (nbAdded !== 0 && nbAdded < photos.length && photosSlider.current) {
            const scrollPos = photos.length * 110;
            photosSlider.current.scrollTo({ left: scrollPos });
            setNbAdded(photos.length);
        } else if (nbAdded !== photos.length) {
            setNbAdded(photos.length);
        }
    }, [nbAdded, photosSlider, photos])

    const generatePreview = (props: string) => {
        const { item, style } = ((props as any) as realDndPreviewProps);
        return (<div style={{ ...style }}><Photo image={photos[item.index].thumb} /></div>)
    };

    const rotatePhoto = (index: number) => {
        const image = photos[index];
        rotateImage(image).then(rotated => {
            onPhotoChanged([...photos.map((p, i) => (i === index ? rotated : p))]);
        })
    }
    const deletePhoto = (index: number) => {
        onPhotoChanged([...photos.filter((p, i) => (i !== index))]);
    }

    const movePhoto = (from: number, dest: { before?: number, after?: number }) => {
        const allPhotos = [...photos];
        let to = from;
        if (dest.after !== undefined) {
            to = dest.after;
            if (from > dest.after && from !== to + 1) to++;

        } else if (dest.before !== undefined) {
            to = dest.before;
            if (from < dest.before && from !== to - 1) to--;
        }
        if (to !== from) {
            allPhotos.splice(to, 0, allPhotos.splice(from, 1)[0]);
            onPhotoChanged(allPhotos);
        }
    }
    const onPhotoUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        const files = e.target.files;
        if (files) {
            setNbLoading(files.length);
            getFilesDataUrl(files).then((dataUrls) => {
                const urlsToAdd = dataUrls.length > nbCanAdd ? dataUrls.slice(0, nbCanAdd) : dataUrls;
                Promise.all(
                    urlsToAdd.map(u => new Promise<PhotoData>((resolve) => {
                        getThumbnail(u).then((t) => resolve({ data: u, thumb: { data: t } }))
                    }))
                ).then(allPhotosAdded => { setLoading(false); setNbLoading(0); onPhotoChanged([...photos, ...allPhotosAdded]) });
            });
        }
    };
    const photoElements = photos.map((p, index) => (mode === 'gallery' ? <DraggablePhotoEditorElement
        key={index}
        p={p}
        index={index}
        canDelete={!min || photos.length > min}
        doZoom={setZoomed}
        doDelete={deletePhoto}
        doMove={movePhoto} />
        : <StaticPhotoEditorElement
            key={index}
            p={p}
            index={index}
            canDelete={!min || photos.length > min}
            doZoom={setZoomed}
            doDelete={deletePhoto} />));

    const nbCanAdd = limit - photos.length;

    if (nbCanAdd > 0) {
        if (!loading) {
            photoElements.push(<li key='add' className='add'>
                <div className="square">
                    <label>
                        <img src={mode === 'profile' && profileIcon ? profileIcon : CameraIcon} alt="" />
                        <input type="file" accept="image/*" onChange={onPhotoUploaded} multiple={nbCanAdd > 1} />
                    </label>
                </div>
            </li>)
        } else {
            let nbLoaders = Math.min(nbLoading, 6 - photoElements.length);
            while (nbLoaders--) {
                photoElements.push(<li key={'load-' + nbLoaders} className="load"><div className="square"><div className="img-wrapper"><img src={Loader} alt="" /></div></div></li>);
            }
        }
        while (mode === 'gallery' && photoElements.length < limit) {
            photoElements.push(<li key={'pad-' + photoElements.length} className="pad"><div className="square"></div></li>);
        }
    }

    const zoomedImage = zoomed !== undefined ? (
        <Modal dismiss={() => setZoomed(undefined)} contentId="zoom">
            <h2>{t(t('photo.edit'))}</h2>
            <div className='square'>
                <div className='carousel-wrapper'>
                    <Carousel photos={photos} onIndexChange={setZoomed} selected={zoomed} />
                </div>
            </div>
            <div className="modal-controls">
                <Button onClick={() => rotatePhoto(zoomed)} arrow={false}><img src={rotateIcon} alt="" /></Button>
                {t('photo.rotate')}
            </div>
            <Button className="close" onClick={() => setZoomed(undefined)} mode='secondary'>{t('photo.close')}</Button>
        </Modal>) : null;
    return (
        <div className="photo-editor">
            {children}
            {zoomedImage}
            {(mode === 'stripe') && <>
                <div className="photos-wrapper">
                    <ul className="photos" ref={photosSlider}>
                        {photoElements}
                    </ul>
                    <div className="left-shadow"></div>
                    <div className="right-shadow"></div>
                </div>
                {(nbCanAdd > 0 || limit > 1) && !children ? (
                    <Button disabled={nbCanAdd === 0} loading={loading}>
                        {label ? label : (nbCanAdd === 1 ? t('photo.uploadOne') : t('photo.upload'))}
                        <input type="file" accept="image/*" onChange={onPhotoUploaded} multiple={nbCanAdd > 1} />
                    </Button>
                ) : null
                }
            </>}
            {mode === 'profile' && <div className="single">
                <ul className="single-photo">
                    {photoElements}
                </ul>
                {(nbCanAdd > 0 || limit > 1) && !children ? (
                    <label className="add-link">
                        <img src={AddIcon} alt="" />{label ? label : (nbCanAdd === 1 ? t('photo.uploadOne') : t('photo.upload'))}
                        <input type="file" accept="image/*" onChange={onPhotoUploaded} multiple={nbCanAdd > 1} />
                    </label>
                ) : null
                }
            </div>}
            {mode === 'gallery' && <>
                <ul className="gallery" ref={photosSlider}>
                    {photoElements}
                </ul>
                <Preview generator={generatePreview} />
            </>}
        </div>
    );
}

export default PhotoEditor;