import React from 'react';
import GoogleLogin from './GoogleLogin';
import { GoogleLoginCallback } from './GoogleLogin';
import Button from '../controls/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import LoginForm from './LoginForm';
import Loader from '../template/Loader';
import './Login.scss';
import YouzdScreen from '../template/YouzdScreen';
import { UserCredentialsDto } from '@youzd/ref-data';

type ComponentProps = {
    onGoogleLogin: GoogleLoginCallback,
    onFacebookLogin: (userInfo: ReactFacebookLoginInfo) => void,
    signingin: boolean,
    credentials: UserCredentialsDto | undefined,
    error: string,
    onLogin: (credentials: UserCredentialsDto) => void,
    logingin: boolean,
}

const Login: React.FC<ComponentProps> = ({ onGoogleLogin, onFacebookLogin, signingin, credentials, error, onLogin, logingin }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return signingin ? (<Loader />) : (
        <YouzdScreen header={false} className='grey'>
            <div className="login-container">
                <div className="register">
                    <h2>{t('login.welcome')}<br />{t('login.create')}</h2>
                    <Button className="facebook-login" mode='white' arrow={false}>
                        <label>{t('login.with')}</label>
                        <FacebookLogin
                            callback={onFacebookLogin}
                            appId={'182037223042646'}
                            fields="name,email,picture"
                            size="medium"
                            icon="fa-facebook"
                            textButton=""
                            cssClass="" />
                    </Button>
                    <Button className="google-login" mode='white' arrow={false}>
                        <label>{t('login.with')}</label>
                        <GoogleLogin onGoogleLogin={onGoogleLogin} />
                    </Button>
                    <Button onClick={() => { history.push('/register') }} mode='white' arrow={false}>{t('login.createAccount')}</Button>
                </div>
                <div className="divider"><span>Ou</span></div>
                <div className="login-wrapper">
                    <h2>{t('login.already')}<br />{t('login.withCredentials')}</h2>
                    <LoginForm credentials={credentials} error={error} onLogin={onLogin} logingin={logingin} />
                </div>
            </div>
        </YouzdScreen>
    );
}

export default Login;