import { ImageDto, LocationDto } from "@youzd/ref-data";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { NullableArticleWithTempPhotos } from "../components/vendre/AddArticle";
import { getType, PhotoData } from "../helpers/photos";
import { ApiError } from "../service/api";
import { loadAd, saveArticle } from "../service/article";
import { saveArticlePhoto } from "../service/photos";
import { addApiErrors, apiFinish, apiStart, clearApiErrors, defferAction, setArticle, setDraftArticle } from "./actions";




export const doLoadArticleAd = (uid: string, location?: LocationDto) => (async (dispatch: Dispatch) => {
    dispatch(clearApiErrors('LOAD_AD'));
    dispatch(apiStart('LOAD_AD'));
    loadAd(uid, location)
        .then((article) => {
            dispatch(setArticle(article));
        })
        .catch((err) => {
            dispatch(addApiErrors([err]));
        })
        .finally(() => {
            dispatch(apiFinish('LOAD_AD'));
        });
});

export const doSaveArticle = (article: NullableArticleWithTempPhotos, routeProps: RouteComponentProps) => (async (dispatch: Dispatch) => {
    if (article) {
        const loginFirst = () => {
            dispatch(setDraftArticle(article));
            dispatch(defferAction('SAVE_ARTICLE'));
            routeProps.history.push('/login');
        };
        const manageApiErrors = (err: any) => {
            if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
                loginFirst();
            }
            else {
                dispatch(addApiErrors([err]));
            }
        };
        dispatch(clearApiErrors('SAVE_ARTICLE'));
        dispatch(apiStart('SAVE_ARTICLE'));
        const photosAsImagePromises = article.photos.map(p => new Promise<ImageDto>((resolve, reject) => {
            if (getType(p) === 'data') {
                saveArticlePhoto(article.uid, (p as PhotoData)).then(i => resolve(i)).catch(e => reject(e));
            }
            else {
                resolve((p as ImageDto));
            }
        }));
        const photos = await Promise.all(photosAsImagePromises).then(r => r).catch(manageApiErrors);
        if (photos && article.location && article.state && article.shippingSize && article.price) {
            const location = article.location;
            const state = article.state;
            const shippingSize = article.shippingSize;
            const price = article.price;
            saveArticle({ ...article, price, state, location, shippingSize, photos }).then(r => {
                dispatch(setDraftArticle(undefined));
                routeProps.history.push(`/ad/${article.uid}`);
            }).catch(manageApiErrors);
        }
        else {
            dispatch(addApiErrors([{ operation: 'SAVE_ARTICLE', errorType: 'INVALID', errorMessage: "Missing properties" }]));
        }
        dispatch(apiFinish('SAVE_ARTICLE'));
    }
});