/// <reference types="@types/googlemaps" />
import { LocationDto } from '@youzd/ref-data';
import $ from 'cash-dom';
import React, { useEffect, useState } from 'react';
import useScript from '../../hooks/useScript';
import './LocationEditor.scss';


type ComponentProps = {
    location: LocationDto | undefined,
    onLocationChanged: (newLocation: LocationDto) => void,
    disabled?: boolean;
}

type ComponentState = {
    googleLoaded: boolean,
    tempAddress: string,
    autocomplete: google.maps.places.Autocomplete | null,
    autocompleteListener: google.maps.MapsEventListener | null,
}

const LocationEditor: React.FC<ComponentProps> = ({ location, onLocationChanged, disabled }) => {

    const addressInputRef = React.createRef<HTMLInputElement>();

    const [state, setState] = useState<ComponentState>({
        googleLoaded: false,
        tempAddress: location?.address || '',
        autocomplete: null,
        autocompleteListener: null
    });

    const googleMapsCallback = () => {
        setState({ ...state, googleLoaded: true });
    };

    (window as any)['googleMapsCallback'] = googleMapsCallback;
    const gscript = useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDuR-IjX8mjSBmYVkxMJMIEmCbnn0Wpqvg&call&libraries=places&callback=googleMapsCallback');
    if (!state.googleLoaded && gscript.loaded && gscript.fromCache) {
        googleMapsCallback();
    }


    const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
        if (place.geometry && place.geometry.location) {
            const newLocation = {
                ...{
                    address: place.formatted_address || '',
                    latLng: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    },
                    cp: place.address_components?.find(c => c.types.includes('postal_code'))?.long_name || '',
                    city: place.address_components?.find(c => c.types.includes('locality'))?.long_name || '',
                }
            };
            onLocationChanged(newLocation);
        }
    }

    useEffect(() => {
        if (state.googleLoaded) {
            if (addressInputRef.current) {
                if (!state.autocomplete) {
                    const autoCompleteOptions = {
                        types: ['address'],
                        componentRestrictions: {
                            country: 'fr'
                        }
                    };
                    const autocomplete = new google.maps.places.Autocomplete(addressInputRef.current, autoCompleteOptions)
                    autocomplete.setFields(['formatted_address', 'geometry', 'address_components']);
                    setState({ ...state, autocomplete });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [state.googleLoaded, addressInputRef, state.autocomplete]);

    useEffect(() => {
        return () => {
            if (state.autocomplete) {
                state.autocomplete.unbindAll();
                $('.pac-container').remove();
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.autocomplete]);

    useEffect(() => {
        if (state.autocomplete) {
            const autocompleteListener = state.autocomplete.addListener('place_changed', () => {
                const place = state.autocomplete ? state.autocomplete.getPlace() : null;
                if (place && place.formatted_address) {
                    handlePlaceSelected(place);
                }
            });
            setState({ ...state, autocompleteListener, tempAddress: location?.address || '' });
            return () => {
                google.maps.event.removeListener(autocompleteListener);
                setState({ ...state, autocompleteListener: null });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [state.autocomplete, onLocationChanged]);


    const onChangeAddress = ((event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, tempAddress: event.target.value });
    });

    const onBlurAddress = (() => {
        setState({ ...state, tempAddress: location?.address || '' });
    });



    return (
        <input type="text" ref={addressInputRef} value={state.tempAddress} onChange={onChangeAddress} onBlur={onBlurAddress} disabled={disabled} />
    );
}

export default LocationEditor;