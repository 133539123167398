import { ArticleSummaryDto, DiscussionMessageDto, TransactionMessageDto, UserDto } from '@youzd/ref-data';
import { Moment } from 'moment';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiToUtc, utc2Human } from '../../helpers/time';
import ArticleSummary from '../article/ArticleSummary';
import Button from '../controls/Button';
import YouzdScreen from '../template/YouzdScreen';
import './Chat.scss';
import ChatMessage from './ChatMessage';

type ComponentProps = {
    article: ArticleSummaryDto,
    messages: (TransactionMessageDto | DiscussionMessageDto)[],
    user: UserDto,
    say: (message: string) => void,
}

const Chat: React.FC<ComponentProps> = ({ article, messages, user, say }) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState<string>('');
    let previousTimeStamp: Moment | undefined = undefined;
    const lastMessage = createRef<HTMLDivElement>();
    const messageElements = messages.map((m, index) => {
        const messageTimestamp = apiToUtc(m.timestamp);
        let divider: JSX.Element | null = null;
        if (!previousTimeStamp || messageTimestamp.diff(previousTimeStamp, 'minutes') >= 15) {
            divider = <div className="divider">{utc2Human(messageTimestamp, t)}</div>
        }
        previousTimeStamp = messageTimestamp;
        return <div key={m.timestamp} ref={index === messages.length - 1 ? lastMessage : null}>{divider}<ChatMessage message={m} user={user} /></div>
    })

    useEffect(() => {
        if (lastMessage && lastMessage.current) {
            lastMessage.current.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    const chatSend = () => {
        console.log('call say from chat', message.trim().length);
        if (message.trim().length > 0) {
            say(message);
        }
        setMessage('');
    }
    const autoGrow = (e: React.KeyboardEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement>) => {
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
    }
    return <YouzdScreen header={true} className="grey">
        <div className="chat-container">
            <ArticleSummary article={article} clickable={true} />
            <div className="chat-messages">{messageElements}</div>
            <div className="chat-input">
                <textarea
                    placeholder={t('chat.sendPlaceholder')}
                    value={message}
                    onKeyDown={autoGrow}
                    onFocus={autoGrow}
                    onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
                        e.target.style.height = '';
                    }}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setMessage(e.target.value.trimLeft())
                    }}
                />
                <Button className="chat-send" onClick={chatSend} />
            </div>
        </div>
    </YouzdScreen>
}

export default Chat;