import React from 'react';
import Loader from '../../assets/loader.svg';
import WhiteArrow from '../../assets/button/arrowWhite.svg';
import GreyArrow from '../../assets/button/arrowGrey.svg';
import './Button.scss';

type ComponentProps = {
    onClick?: (event: React.MouseEvent) => void,
    disabled?: boolean,
    mode?: 'primary' | 'secondary' | 'white',
    arrow?: boolean,
    className?: string,
    loading?: boolean,
    arrowIcon?: string,
}

const Button: React.FC<ComponentProps> = ({ onClick, children, className, disabled = false, mode = 'primary', loading = false, arrow = true, arrowIcon }) => {
    const disabledClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        return false
    }
    const arrowImage = disabled ? GreyArrow : WhiteArrow;

    const classToApply = ["button", (className ? className : ''), (disabled || loading ? ' disabled' : ''), mode, arrow ? 'arrow' : ''].join(' ');
    return onClick ?
        (<label className={classToApply} onClick={disabled || loading ? disabledClick : onClick}>
            {loading && (<img src={Loader} className="loader" alt="" />)}
            {children}
            {arrow && (<img src={arrowIcon || arrowImage} className="arrow" alt="" />)}
        </label>)
        :
        (<label className={classToApply} onClick={disabled || loading ? disabledClick : undefined}>
            {loading && (<img src={Loader} className="loader" alt="" />)}
            {children}
            {arrow && (<img src={arrowIcon || arrowImage} className="arrow" alt="" />)}
        </label>);
}

export default Button;