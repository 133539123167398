import React from 'react';
import { connect } from 'react-redux';
import { AnyThunkDispatch } from '../../redux/actions';
import { doChangePassword } from "../../redux/userActions";
import { AppState } from '../../redux/reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import { ApiError } from '../../service/api';

type PropsFromState = {
    loading: boolean,
    errors: ApiError[]
};

type DispatchProps = {
    changePassword: (password: string) => void
};

type ComponentProps = PropsFromState & DispatchProps;

const RegisterContainer: React.FC<ComponentProps> = ({ changePassword, loading, errors }) => {
    return (<ChangePassword errors={errors} changePassword={changePassword} loading={loading} />)
};

const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ token: string }>): PropsFromState => ({
    loading: state.pendingOperations.find(o => o === 'RESET_PASSWORD') !== undefined,
    errors: state.apiErrors.filter(e => e.operation === 'CHANGE_PASSWORD')
});

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps<{ token: string }>): DispatchProps => ({
    changePassword: async (password: string) => {
        dispatch(doChangePassword(password, routeProps, routeProps.match.params.token));
    }
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(RegisterContainer));