import React, { useState, useEffect } from 'react';
import './Starter.scss';
import LeftIcon from '../../assets/controls/left-white.svg';
import RightIcon from '../../assets/controls/right-white.svg';
import { getStarterSeen, setStarterSeen } from '../../service/cookies';
import Modal from '../template/Modal';
import { useTranslation } from 'react-i18next';
import LogoWhite from '../../assets/LogoWhite.svg';
import CloseIcon from '../../assets/controls/closeWhite.svg';
import { Swipeable } from 'react-swipeable';

type ComponentProps = {
    showSplash: boolean
}

const Starter: React.FC<ComponentProps> = ({ showSplash, children }) => {

    const { t } = useTranslation();

    const [current, setCurrent] = useState(0);
    const [displayModal, setDisplayModal] = useState(false);
    const [looped, setLooped] = useState(false);

    const nbChildren = React.Children.count(children);


    useEffect(() => {
        const hasSeenStarter = getStarterSeen();
        if (!hasSeenStarter && showSplash) {
            setDisplayModal(true);
            setStarterSeen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])



    useEffect(() => {
        let timer: NodeJS.Timeout;
        //timer = setTimeout(() => setCurrent(current < nbChildren - 1 ? current + 1 : 0), 5000);
        if (current === nbChildren - 1) {
            setLooped(true);
        }
        if (looped && displayModal && current === 0) {
            setDisplayModal(false);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [current]);

    const navigation = Array.from<number, number>({ length: nbChildren }, (v, k) => k).map(n => (
        <li key={n} className={n === current ? 'current' : ''} onClick={() => setCurrent(n)}></li>
    ));

    const slides = <>{React.Children.map(children, (child, index) => <li key={index} className={index === current ? 'current' : ''}>{child}</li>)}</>

    const onSwipedLeft = () => {
        if (current !== undefined && current < nbChildren - 1) {
            setCurrent(current + 1);
            if (looped) {
                setLooped(false);
            }
        }
    };
    const onSwipedRight = () => {
        if (current !== undefined && current >= 1) {
            setCurrent(current - 1);
            if (looped) {
                setLooped(false);
            }
        }
    };

    const modal = displayModal ? (<Modal dismiss={() => setDisplayModal(false)} contentId="starter">
        <div className="wrapper">
            <div className="logo">
                <div></div>
                <img src={LogoWhite} alt="" />
                <div className="close" onClick={() => setDisplayModal(false)}>
                    <img src={CloseIcon} alt="" />
                </div>
            </div>
            <div className="slides-wrapper">
                <ul className="slides">
                    {slides}
                </ul>
                <Swipeable className="swiper" onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight}></Swipeable>
            </div>
        </div>
        <ul className="navigation">
            {navigation}
        </ul>
        <div className="skip" onClick={() => setDisplayModal(false)}>{t('welcome.starter.skip')}</div>
    </Modal>) : null;


    return (
        <div className="starter">
            {modal}
            <div className="slider">
                <div className="left-control"><img src={LeftIcon} alt="" onClick={() => setCurrent(current > 0 ? current - 1 : nbChildren - 1)} /></div>
                <div className="header-wrapper">
                    <ul className="header">
                        {slides}
                    </ul>
                    <Swipeable className="swiper" onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight}></Swipeable>
                </div>
                <div className="right-control"><img src={RightIcon} alt="" onClick={() => setCurrent(current < nbChildren - 1 ? current + 1 : 0)} /></div>
            </div>
            <ul className="navigation">
                {navigation}
            </ul>
        </div>
    )
}

export default Starter;