import { ArticleDetailsDto, CreateTransactionDto, LocationDto, UserDto } from '@youzd/ref-data';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyThunkDispatch } from '../../redux/actions';
import { doLoadArticleAd } from '../../redux/articleActions';
import { AppState } from '../../redux/reducer';
import { doCreateTransaction } from '../../redux/transactionActions';
import { ApiError, emptyApiErrorsArray } from '../../service/api';
import Loader from '../template/Loader';
import Checkout from './Checkout';

type PropsFromState = {
    uid: string,
    article: ArticleDetailsDto | undefined;
    buyer?: UserDto,
    errors: ApiError[],
    loading: boolean
}

type DispatchProps = {
    createTransaction: (transaction: CreateTransactionDto) => void,
    reloadAd: (forLocation: LocationDto) => void,
    loadArticle: (uid: string) => void,
}

type ComponentProps = PropsFromState & DispatchProps;

const CheckoutContainer: React.FC<ComponentProps> = ({ uid, article, buyer, errors, loading, createTransaction, reloadAd, loadArticle }) => {
    const [userLoggedIn, setUserLoggedIn] = useState<string | undefined>(buyer?.uid);
    const [reloading, setReloading] = useState(false);

    const doReload = (forLocation: LocationDto) => {
        setReloading(true);
        reloadAd(forLocation);
    }

    useEffect(() => {
        if (article === undefined) {
            loadArticle(uid);
        }
        if (reloading) setReloading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article])

    useEffect(() => {
        if (article && buyer && buyer.uid && userLoggedIn !== buyer.uid) {
            setUserLoggedIn(buyer.uid);
            loadArticle(uid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyer]);

    if (!article || article.uid !== uid) {
        return (<Loader></Loader>);
    } else {
        return (<Checkout article={article} errors={errors} loading={loading || reloading} createTransaction={createTransaction} reloadAd={doReload} />)
    }
}

const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ uid: string }>): PropsFromState => {
    const articleUid = routeProps.match.params.uid
    const errors = state.apiErrors.filter(e => e.operation === 'CREATE_TRANSACTION');
    return ({
        uid: articleUid,
        article: state.article,
        buyer: state.user,
        errors: errors.length > 0 ? errors : emptyApiErrorsArray,
        loading: state.pendingOperations.includes('CREATE_TRANSACTION')
    })
}

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps<{ uid: string }>): DispatchProps => ({
    loadArticle: (uid: string) => {
        dispatch(doLoadArticleAd(uid));
    },
    reloadAd: (forLocation: LocationDto) => {
        const uid = routeProps.match.params.uid;
        dispatch(doLoadArticleAd(uid, forLocation));
    },
    createTransaction: (transaction: CreateTransactionDto) => {
        dispatch(doCreateTransaction(transaction, routeProps));
    }
});


export default connect(mapStateToProps, dispatchToProps)(CheckoutContainer);