import React from 'react';
import { connect } from 'react-redux';
import { FloorDto } from '@youzd/ref-data';
import FloorEditor from '././FloorEditor';
import { AppState } from '../../redux/reducer';



type ComponentProps = {
    value: FloorDto | undefined,
    onChange: (floor: FloorDto) => void,
}


const FloorEditorContainer: React.FC<ComponentProps> = ({ value, onChange }) => {
    return <FloorEditor
        value={value}
        onChange={onChange} />;
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps): ComponentProps => {
    const propsFloor = ownProps.value;
    const userFloor = state.user?.floor && state.user.hasElevator !== undefined ? {
        floor: state.user.floor,
        hasElevator: state.user.hasElevator
    } : undefined;
    return ({
        value: propsFloor || userFloor,
        onChange: ownProps.onChange
    })
}

export default connect(mapStateToProps)(FloorEditorContainer);