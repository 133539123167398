import React from 'react';
import Logo from '../../assets/logo.png';
import './MobileHeader.scss';
import MenuContainer from './MenuContainer';
import { Link } from 'react-router-dom';

const MobileHeader: React.FC = () => {
    return (
        <header className="main mobile">
            <MenuContainer />
            <div className="logo">
                <Link to="/"><img src={Logo} alt="" /></Link>
            </div>
            <div className="back">

            </div>
        </header>
    )
}

export default React.memo(MobileHeader);