import React, { useState, useEffect } from 'react';
import { UserCredentialsDto } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import Button from '../controls/Button';
import './Register.scss';
import YouzdScreen from '../template/YouzdScreen';
import { hasError, addError, FormMessagesWithField, getFieldMessages, createError } from '../../helpers/forms';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import FormField from '../form/FormField';
import CheckBox from '../controls/CheckBox';


export type CreateUserCallback = (user: UserCredentialsDto) => void;

type ComponentProps = {
    credentials: UserCredentialsDto | undefined,
    createUser: CreateUserCallback,
    emailError?: string,
    loading?: boolean,
}

export type FormField = 'email' | 'password'

const Register: React.FC<ComponentProps> = ({ credentials, createUser, emailError, loading }) => {
    const validEmailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { t } = useTranslation();
    const [messages, setMessages] = useFormMessageState<FormField>(emailError ? createError(['email'], t(emailError)) : undefined);
    const [email, setEmail] = useState(credentials?.email || '');
    const [password, setPassword] = useState(credentials?.password || '');
    const [cguAccepted, setCguAccepted] = useState(false);


    useEffect(() => {
        if (emailError) setMessages([createError(['email'], t(`register.${emailError}`))]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailError])

    useEffect(() => {
        if (email.length > 0 || password.length > 0) {
            validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, password]);


    const validate = () => {
        let newMessages: FormMessagesWithField<FormField> = [];
        if (!validEmailRe.test(email)) {
            newMessages = addError<FormField>(newMessages, ['email'], t('register.error.invalidEmail'));
        }
        if (password && password.length < 6) {
            newMessages = addError<FormField>(newMessages, ['password'], t('register.error.invalidPassword'));
        }
        if (newMessages.length > 0) {
            setMessages(newMessages);
        } else {
            setMessages([]);
        }
    }

    const canCreate = cguAccepted && email.length > 0 && password.length > 0 && !hasError(messages);

    return (
        <YouzdScreen header={true} className='grey'>
            <div className="register-container">
                <h2>{t('register.title')}</h2>
                <FormField label={t('register.email')} messages={getFieldMessages('email', messages)}>
                    <input
                        type="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trimLeft())}
                        value={email} />
                </FormField>
                <FormField label={t('register.password')} messages={getFieldMessages('password', messages)}>
                    <input
                        type="password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value.trimLeft())}
                        value={password} />
                </FormField>
                <FormField label={t('register.cgu')} layout='reverse' labelClick={() => { setCguAccepted(!cguAccepted) }}>
                    <CheckBox checked={cguAccepted} onChecked={setCguAccepted} />
                </FormField>
                <div className="bottom">
                    <Button onClick={() => { createUser({ email, password }) }} disabled={!canCreate} loading={loading}>{t('register.validate')}</Button>
                </div>
            </div>
        </YouzdScreen >
    );
}

export default Register;