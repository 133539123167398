import React from 'react';
import { AnyImageKind } from '../../helpers/photos';
import deleteIcon from '../../assets/controls/delete.svg';
import editIcon from '../../assets/controls/edit.svg';
import Photo from './Photo';
import { useDrag, useDrop } from 'react-dnd';
import { DraggableTypes } from '../../App';


type ComponentProps = {
    p: AnyImageKind,
    index: number,
    canDelete: boolean
    doZoom: (index: number) => void
    doDelete: (index: number) => void,
    doMove: (moved: number, dest: { before?: number, after?: number }) => void
}


const DraggablePhotoEditorElement: React.FC<ComponentProps> = ({ p, index, doZoom, canDelete, doDelete, doMove }) => {
    const ref = React.createRef<HTMLDivElement>();
    const [{ isDragging }, drag] = useDrag({
        item: { type: DraggableTypes.PHOTO, index },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });
    const [, drop] = useDrop({
        accept: DraggableTypes.PHOTO,
        drop: (item, monitor) => {
            const hoverPos = monitor.getClientOffset();
            if (hoverPos && ref && ref.current) {
                const myRect = ref.current.getBoundingClientRect();
                if (hoverPos.y >= myRect.y && hoverPos.y < myRect.y + myRect.height) {
                    if (hoverPos.x >= myRect.x && hoverPos.x < (myRect.x + myRect.width / 2)) {
                        doMove((item as any).index, { before: index });
                    };
                    if (hoverPos.x >= (myRect.x + myRect.width / 2) && hoverPos.x < (myRect.x + myRect.width)) {
                        doMove((item as any).index, { after: index });
                    }
                }
            }
        },

    })
    return (
        <li ref={drag} className={isDragging ? 'dragging' : ''}>
            <div ref={ref}>
                <div ref={drop} onClick={() => doZoom(index)}>
                    <Photo image={p.thumb} />
                </div>
            </div>
            <div className="controls">
                <div className="control edit" onClick={() => doZoom(index)}><img src={editIcon} alt="" /></div>
                {(canDelete) ? <div className="control delete" onClick={() => doDelete(index)}><img src={deleteIcon} alt="" /></div> : <div></div>}
            </div>
        </li>
    );
}

export default DraggablePhotoEditorElement;