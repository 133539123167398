import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../controls/Button';
import './Register.scss';
import YouzdScreen from '../template/YouzdScreen';
import { hasError, addError, FormMessagesWithField, getFieldMessages } from '../../helpers/forms';
import { useFormMessagesState } from '../../hooks/useFormMessagesState';
import FormField from '../form/FormField';
import { useHistory } from 'react-router-dom';
import './ChangePassword.scss';
import { ApiError } from '../../service/api';


type ComponentProps = {
    changePassword: (password: string) => void,
    loading: boolean,
    errors: ApiError[];
}

export type FormField = 'password'

const ChangePassword: React.FC<ComponentProps> = ({ errors, loading, changePassword }) => {
    const { t } = useTranslation();

    const apiErrorsToFormErrors = (apiErrors: ApiError[]): FormMessagesWithField<FormField> =>
        apiErrors.map(e => {
            return {
                relatedFields: ['password'],
                message: {
                    level: 'error',
                    text: t(`password.${e.errorMessage}`)
                }
            }
        });

    const [messages, setMessages] = useFormMessagesState<FormField>(apiErrorsToFormErrors(errors));
    const [password, setEmail] = useState('');

    const history = useHistory();

    useEffect(() => {
        if (password.length > 0) {
            validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    useEffect(() => {
        setMessages(apiErrorsToFormErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])


    const validate = () => {
        let newMessages: FormMessagesWithField<FormField> = [];
        if (password && password.length < 6) {
            newMessages = addError<FormField>(newMessages, ['password'], t('password.error.invalidPassword'));
        }
        setMessages(newMessages);
    }

    const canSend = password.length > 0 && !hasError(messages);

    return (
        <YouzdScreen header={true} className='grey'>
            <div className="change-container">
                <h2>{t('password.changeTitle')}</h2>
                <FormField label={t('password.password')} messages={getFieldMessages('password', messages)}>
                    <input
                        type="password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trimLeft())}
                        value={password} />
                </FormField>
                <div className="bottom">
                    <Button onClick={() => { changePassword(password) }} disabled={!canSend} loading={loading}>{t('password.validate')}</Button>
                    <Button onClick={() => { history.goBack() }} mode='secondary' >{t('password.cancel')}</Button>
                </div>
            </div>

        </YouzdScreen>
    );
}

export default ChangePassword;