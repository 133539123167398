import React from 'react';
import { ColorAttribute, ColorAttributes } from '@youzd/ref-data';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ColorPicker.scss';
import Modal from '../template/Modal';
import Caption from '../controls/Caption';


type ComponentProps = {
    colors: ColorAttribute[],
    onColorsChanged: (newColors: ColorAttribute[]) => void,
}

const ColorPicker: React.FC<ComponentProps> = ({ colors, onColorsChanged }) => {
    const { t } = useTranslation();
    const [tooMany, setTooMany] = useState(false)
    const allColors = Object.keys(ColorAttributes);
    const toggleColor = (color: ColorAttribute) => {
        if (colors.indexOf(color) !== -1) {
            const newColors = colors.filter(c => (c !== color));
            onColorsChanged(newColors);
        } else if (colors.length < 3) {
            const newColors = [...colors, color];
            onColorsChanged(newColors);
        } else {
            setTooMany(true);
        }
    }
    const colorEntries = allColors.map(color => (
        <li className={colors.includes(color as ColorAttribute) ? 'selected' : ''} onClick={() => toggleColor(color as ColorAttribute)} key={color}>
            <div className={color}></div>
            <label>{t(`color.${color}`)}</label>
        </li>
    ));


    const dismissColorError = () => {
        setTooMany(false);
    }

    const errorMessage = tooMany ?
        <Modal dismiss={dismissColorError}>
            <p>{t('add.colors.error.tooMany')}</p><div className="button" onClick={dismissColorError}>{t('popup.close')}</div>
        </Modal> : null;

    return (
        <div className="color-picker">
            <Caption safeHtml={t('add.colors.subtitle')} />
            {errorMessage}
            <ul className="colors">
                {colorEntries}
            </ul>
        </div>
    );
}

export default ColorPicker;