import React, { useState, useEffect } from 'react';
import { getAllAttributesFromCategories, CategoryName, ShippingOptionNames, sizesEligibleToPickup, youzdDeliveryPrices } from '@youzd/ref-data';
import ClockPriceEdit from './ClockPriceEdit';
import TitleEdit, { MIN_TITLE_LENGTH } from './TitleEdit';
import { NullableArticleWithTempPhotos } from '../vendre/AddArticle';
import { useTranslation } from 'react-i18next';
import './EditAttributes.scss';
import Form from '../form/Form';
import FormBody from '../form/FormBody';
import PhotoEditor from '../controls/PhotosEditor';
import { MAX_PHOTOS } from '../vendre/AddPhotos';
import Caption from '../controls/Caption';
import AttributesFrame from './AttributesFrame';
import SelectCategories from '../categories/SelectCategories';
import Button from '../controls/Button';
import SelectUniverses from '../categories/SelectUniverses';
import BrandEdit from './BrandEdit';
import ColorPicker from './ColorPicker';
import ArticleStateSelect from './ArticleStateSelect';
import DimensionsEditor from './DimensionsEditor';
import ShippingSizesSelect from './ShippingSizesSelect';
import FormField from '../form/FormField';
import Cta from '../controls/Cta';
import smoothscroll from 'smoothscroll-polyfill';
import WarrantyEdit from './WarrantyEdit';
import FurnitureLayoutEdit from './FurnitureLayoutEdit';
import { getShippingSize } from '../../helpers/shippingSizeHelpers';
import ShippingOptionsContainer from './ShippingOptionsContainer';
import LocationEditorContainer from '../geo/LocationEditorContainer';
import EditDescription, { MIN_DESCRIPTION_LENGTH } from './EditDescription';
import NbOfItems from './NbOfItemsEdit';
import NbOfSeats from './NbOfSeatsEdit';
import AttributesSection from './AttributesSection';
import { useMedia } from '../../hooks/useMedia';

// kick off the polyfill!
smoothscroll.polyfill();

type ComponentProps = {
    article: NullableArticleWithTempPhotos,
    onSaveArticle: (article: NullableArticleWithTempPhotos) => void,
    onUpdateArticle: (article: NullableArticleWithTempPhotos) => void,
    loading: boolean;
}

type Section = 'photos' | 'price' | 'title' | 'categories' | 'universes' | 'numberOfItems' | 'numberOfSeats' | 'brand' | 'state' | 'colors' | 'dimensions' | 'shipping' | 'description' | 'furnitureLayout';

const sectionsOrder: Section[] = [
    'photos',
    'price',
    'title',
    'categories',
    'universes',
    'furnitureLayout',
    'numberOfItems',
    'numberOfSeats',
    'brand',
    'state',
    'colors',
    'dimensions',
    'shipping',
    'description'
];

const EditAttributes: React.FC<ComponentProps> = ({ article, onSaveArticle, onUpdateArticle, loading }) => {
    const { t } = useTranslation();
    const [state, setState] = useState<NullableArticleWithTempPhotos>(article);
    const [section, setSectionRaw] = useState<Section | undefined>(undefined)
    const [stickyNext, setStickyNext] = useState<Section | undefined>(undefined)
    const [isDirty, setIsDirty] = useState(false);

    const { isMobile } = useMedia();

    const allAttributes = getAllAttributesFromCategories(state.categories);

    const setSection = (section: Section | undefined) => {
        setSectionRaw(section);
        setStickyNext(undefined);
    }

    const toggleSection = (sec: Section | undefined) => {
        const current = section;
        setSection(sec !== current ? sec : undefined);
        if (sec === 'furnitureLayout') {
            if (!state.furnitureLayout) {
                updateArticle({ furnitureLayout: [] })
            }
        }
    }

    const ensureStateConsistency = () => {
        const shipSize = state.shippingSize;
        if (shipSize) {
            const options = state.shippingOptions;
            const pickup = options.find(o => o.type === ShippingOptionNames.pickup);
            if (pickup && !sizesEligibleToPickup.includes(shipSize)) {
                setState({ ...state, shippingOptions: options.filter(o => o.type !== ShippingOptionNames.pickup) });
                return false;
            }
            const youzdDelivery = options.find(o => o.type === ShippingOptionNames.youzdDelivery);
            if (youzdDelivery && !youzdDeliveryPrices.map(p => p.size).includes(shipSize)) {
                setState({ ...state, shippingOptions: options.filter(o => o.type !== ShippingOptionNames.youzdDelivery) });
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        if (isDirty) {
            const canUpdate = ensureStateConsistency();
            if (canUpdate) {
                setIsDirty(false);
                onUpdateArticle(state);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [state, isDirty]);

    useEffect(() => {
        setState(article);
    }, [article]);

    const usedSections = sectionsOrder.filter(section => {
        switch (section) {
            case 'photos':
            case 'price':
            case 'title':
            case 'categories':
            case 'universes':
                return true;
            case 'dimensions':
                return allAttributes.has('width') || allAttributes.has('shippingSize');
            case 'shipping':
                return allAttributes.has('shippingOptions');
            default:
                return allAttributes.has(section);
        }
    });

    const hasSection = (section: Section) => {
        return usedSections.indexOf(section) >= 0;
    }

    const isValidSection = (section: Section): boolean => {
        switch (section) {
            case 'photos':
                return state.photos.length > 0;
            case 'price':
                return state.price !== undefined;
            case 'title':
                return state.title !== undefined && state.title.length >= MIN_TITLE_LENGTH;
            case 'categories':
                return state.categories.length > 0;
            case 'universes':
                return state.universes.length > 0;
            case 'brand':
                return state.brand !== undefined;
            case 'state':
                return state.state !== undefined;
            case 'colors':
                return state.colors !== undefined && (state.colors.length > 0);
            case 'dimensions':
                return (!allAttributes.has('width') || (state.width !== undefined && state.height !== undefined && state.depth !== undefined && state.precision !== undefined));
            case 'shipping':
                return state.shippingOptions.length > 0 && state.location !== undefined && state.shippingSize !== undefined;
            case 'numberOfItems':
                return state.numberOfItems !== undefined && state.numberOfItems > 0;
            case 'numberOfSeats':
                return state.numberOfSeats !== undefined && state.numberOfSeats > 0;
            case 'description':
                return state.description !== undefined && state.description.length >= MIN_DESCRIPTION_LENGTH;
            case 'furnitureLayout':
                return (state.furnitureLayout !== undefined);
        }
    }

    const nextSection = (fromSection: Section): Section | undefined => {
        if (stickyNext) {
            setStickyNext(undefined);
            return stickyNext;
        } else if (state.categories.length === 0) {
            return 'categories'
        } else {
            let currentIndex = usedSections.indexOf(fromSection);
            if (currentIndex >= 0 && currentIndex < usedSections.length - 1) {
                const possibleSections = usedSections.slice(currentIndex + 1);
                const nextSection = possibleSections.find(s => !isValidSection(s));
                return nextSection;
            } else {
                return undefined;
            }
        }
    }

    const updateArticle = (properties: any) => {
        setState({ ...state, ...properties });
        setIsDirty(true);
    }

    const updateDimension = (properties: any) => {
        const futureState = { ...state, properties };
        if (futureState.width && futureState.height && futureState.depth && !futureState.shippingSize) {
            updateArticle({
                ...properties,
                shippingSize: getShippingSize(futureState.width, futureState.height, futureState.depth)
            });
        } else {
            updateArticle(properties);
        }
    }

    const updateCategories = (categories: CategoryName[]) => {
        if (categories.indexOf('baby') >= 0 && state.universes && state.universes.indexOf('baby') === -1) {
            setState({ ...state, categories, universes: [...state.universes, 'baby'] });
            setStickyNext('universes');
        } else {
            updateArticle({ categories });
        }
    }

    const canSave = usedSections.reduce<boolean>((result, currentSection) => {
        const sectionValid = isValidSection(currentSection);
        return result && sectionValid;
    }, true);

    return (
        <Form className="edit-attributes">
            <FormBody>
                <div className="main-attributes">
                    <AttributesSection className="photos-container">
                        <PhotoEditor
                            min={1}
                            limit={MAX_PHOTOS}
                            photos={state.photos}
                            onPhotoChanged={(photos) => { updateArticle({ photos }); setSection('photos') }}
                            mode={isMobile ? 'stripe' : 'gallery'}
                        >
                            <header className="photo-action">
                                <h2>{t('add.photos.title')}</h2>
                                <Caption safeHtml={t('add.photos.caption')} />
                            </header>
                        </PhotoEditor>
                    </AttributesSection>
                    <AttributesSection>
                        <ClockPriceEdit
                            price={state.price}
                            onPriceChanged={(price) => { updateArticle({ price }); setSection('price'); }}

                        />
                    </AttributesSection>
                    <AttributesSection>
                        <h2>{t("add.title.title")}</h2>
                        <TitleEdit title={state.title} onTitleChange={(title) => { updateArticle({ title }); setSection('title') }} />
                    </AttributesSection>
                </div>
                <div className="details">
                    <h2>{t('add.details.title')}</h2>
                    <AttributesSection>
                        <AttributesFrame
                            name="categories"
                            onToggle={() => toggleSection('categories')}
                            active={section === 'categories'}
                            valid={isValidSection('categories')}
                            summary={state.categories.map(c => t(`categories.${c}`)).join(', ')}>
                            <SelectCategories
                                categories={state.categories}
                                onCategoriesChange={(categories) => updateCategories(categories)}
                            />
                            <Button mode='secondary' onClick={() => setSection(nextSection(('categories')))}>{t('add.validate')}</Button>
                        </AttributesFrame>
                    </AttributesSection>
                    <AttributesSection>
                        <AttributesFrame
                            name="universes"
                            onToggle={() => toggleSection('universes')}
                            active={section === 'universes'}
                            valid={isValidSection('universes')}
                            summary={state.universes.map(c => t(`universes.${c}`)).join(', ')}>
                            <SelectUniverses
                                universes={state.universes}
                                onUniversesChange={(universes) => updateArticle({ universes })}
                            />
                            <Button mode='secondary' onClick={() => setSection(nextSection(('universes')))}>{t('add.validate')}</Button>
                        </AttributesFrame>
                    </AttributesSection>
                    {hasSection('furnitureLayout') &&
                        <AttributesSection>
                            <AttributesFrame
                                name="furnitureLayout"
                                onToggle={() => toggleSection('furnitureLayout')}
                                active={section === 'furnitureLayout'}
                                valid={isValidSection('furnitureLayout')}
                                summary={state.furnitureLayout?.map(l => t(`layouts.${l}`)).join(', ')}>
                                <FurnitureLayoutEdit layouts={state.furnitureLayout} onChange={furnitureLayout => updateArticle({ furnitureLayout })} />
                                <Button mode='secondary' onClick={() => setSection(nextSection(('furnitureLayout')))}>{t('add.validate')}</Button>
                            </AttributesFrame>
                        </AttributesSection>
                    }
                    {hasSection('numberOfItems') &&
                        <AttributesSection>
                            <AttributesFrame
                                name="numberOfItems"
                                onToggle={() => toggleSection('numberOfItems')}
                                active={section === 'numberOfItems'}
                                valid={isValidSection('numberOfItems')}
                                summary={state.numberOfItems ? `${t('add.numberOfItems.by')} ${state.numberOfItems}` : ''}>
                                <NbOfItems numberOfItems={state.numberOfItems || 0} onNumberOfItems={(numberOfItems) => updateArticle({ numberOfItems })} />
                                <Button mode='secondary' onClick={() => setSection(nextSection(('numberOfItems')))}>{t('add.validate')}</Button>
                            </AttributesFrame>
                        </AttributesSection>
                    }
                    {hasSection('numberOfSeats') &&
                        <AttributesSection>
                            <AttributesFrame
                                name="numberOfSeats"
                                onToggle={() => toggleSection('numberOfSeats')}
                                active={section === 'numberOfSeats'}
                                valid={isValidSection('numberOfSeats')}
                                summary={state.numberOfSeats ? `${state.numberOfSeats} ${t('add.numberOfSeats.seats')}` : ''}>
                                <NbOfSeats numberOfSeats={state.numberOfSeats || 0} onNumberOfSeats={(numberOfSeats) => updateArticle({ numberOfSeats })} />
                                <Button mode='secondary' onClick={() => setSection(nextSection(('numberOfSeats')))}>{t('add.validate')}</Button>
                            </AttributesFrame>
                        </AttributesSection>
                    }
                    <AttributesSection>
                        <AttributesFrame
                            name="brand"
                            onToggle={() => toggleSection('brand')}
                            active={section === 'brand'}
                            valid={isValidSection('brand')}
                            summary={state.brand ? (state.brand.hasBrand ? state.brand.name : t('add.brand.noBrand')) : ''}>
                            <BrandEdit
                                brand={state.brand || { name: '', hasBrand: true }}
                                onBrandChange={(brand) => updateArticle({ brand })} />
                            <Button mode='secondary' onClick={() => setSection(nextSection(('brand')))}>{t('add.validate')}</Button>
                        </AttributesFrame>
                    </AttributesSection>
                    <AttributesSection>
                        <AttributesFrame
                            name="state"
                            onToggle={() => toggleSection('state')}
                            active={section === 'state'}
                            valid={isValidSection('state')}
                            summary={state.state ? t(`article_state.${state.state}.label`) : ''}>
                            <ArticleStateSelect articleState={state.state} onArticleStateChanged={(newState) => updateArticle({ state: newState })} />
                            {allAttributes.has('warranty') && <WarrantyEdit warranty={state.warranty} onChange={warranty => updateArticle({ warranty })} />}
                            <Button mode='secondary' onClick={() => setSection(nextSection(('state')))}>{t('add.validate')}</Button>
                        </AttributesFrame>
                    </AttributesSection>
                    {
                        hasSection('colors') &&
                        <AttributesSection>
                            <AttributesFrame
                                name="colors"
                                onToggle={() => toggleSection('colors')}
                                active={section === 'colors'}
                                valid={isValidSection('colors')}
                                summary={state.colors?.map(c => t(`color.${c}`)).join(', ') || ''}>
                                <ColorPicker colors={state.colors || []} onColorsChanged={(colors) => updateArticle({ colors })} />
                                <Button mode='secondary' onClick={() => setSection(nextSection(('colors')))}>{t('add.validate')}</Button>
                            </AttributesFrame>
                        </AttributesSection >
                    }
                    {
                        allAttributes.has('width') &&
                        <AttributesSection>
                            <AttributesFrame
                                name="dimensions"
                                onToggle={() => toggleSection('dimensions')}
                                active={section === 'dimensions'}
                                valid={isValidSection('dimensions')}
                                summary=''>
                                <DimensionsEditor
                                    width={state.width}
                                    height={state.height}
                                    depth={state.depth}
                                    precision={state.precision}
                                    comment={state.dimensionsComment}
                                    removable={state.removable}
                                    onCommentChanged={(dimensionsComment) => updateArticle({ dimensionsComment })}
                                    onWidthChanged={(width) => updateDimension({ width })}
                                    onHeightChanged={(height) => updateDimension({ height })}
                                    onDepthChanged={(depth) => updateDimension({ depth })}
                                    onPrecisionChanged={(precision) => updateArticle({ precision })}
                                    onRemovableChanged={(removable) => updateArticle({ removable })}
                                    useRemovable={allAttributes.has('removable')}
                                />
                                <Button mode='secondary' onClick={() => setSection(nextSection(('dimensions')))}>{t('add.validate')}</Button>
                            </AttributesFrame>
                        </AttributesSection >
                    }
                    <AttributesSection>
                        <AttributesFrame
                            name="shipping"
                            onToggle={() => toggleSection('shipping')}
                            active={section === 'shipping'}
                            valid={isValidSection('shipping')}
                            summary={state.shippingOptions.map(o => t(`shipOption.${o.type}.label`)).join(', ')}>
                            <FormField className="location" label={t('add.location.label')}>
                                <Caption safeHtml={t('add.location.caption')} />
                                <LocationEditorContainer location={state.location} onLocationChanged={(location) => updateArticle({ location })} />
                            </FormField>
                            <p>{t('add.shipping.size')}</p>
                            <ShippingSizesSelect
                                shippingSize={state.shippingSize}
                                onShippingSizeSelected={(shippingSize) => { updateArticle({ shippingSize }) }} />
                            <p>{t('add.shipping.options')}</p>
                            <Caption safeHtml={t('add.shipping.optionsHint')} />
                            <ShippingOptionsContainer
                                shippingOptions={state.shippingOptions}
                                onOptionsChanged={(shippingOptions) => updateArticle({ shippingOptions })}
                                zipCode={state.location?.cp}
                                shipSize={state.shippingSize}
                                endPrice={state.price?.endPrice}
                            />

                            <Button mode='secondary' onClick={() => setSection(nextSection(('shipping')))}>{t('add.validate')}</Button>
                        </AttributesFrame>
                    </AttributesSection >

                    <AttributesSection>
                        <AttributesFrame
                            name="description"
                            onToggle={() => toggleSection('description')}
                            active={section === 'description'}
                            valid={isValidSection('description')}
                            summary={state.description ? state.description.substr(0, 25) + (state.description.length > 25 ? '…' : '') : ''}>
                            <EditDescription description={state.description} onDescriptionChanged={(description) => updateArticle({ description })} />
                            <Button mode='secondary' onClick={() => setSection(nextSection(('description')))}>{t('add.validate')}</Button>
                        </AttributesFrame>
                    </AttributesSection >
                </div >
            </FormBody >
            <Cta onClick={() => onSaveArticle(state)} disabled={!canSave} loading={loading} label={t('add.validate')} layout="collapsable"></Cta>
        </Form >
    );
}

export default EditAttributes;