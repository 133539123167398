import React from 'react';
import Div100vh from 'react-div-100vh';
import './Modal.scss';

type ComponentProps = {
    dismiss: (e?: React.MouseEvent) => void
    contentId?: string,
}

const Modal: React.FC<ComponentProps> = ({ dismiss, contentId, children }) => {
    return (
        <div className="modal-container">
            <Div100vh>
                <div className="modal-overlay" onClick={(e: React.MouseEvent) => { e.stopPropagation(); dismiss(e) }}>
                    <div id={contentId} className={"modal-content"} onClick={(e: React.MouseEvent) => { e.stopPropagation() }}>
                        {children}
                    </div>
                </div>
            </Div100vh>
        </div>
    )
}

export default Modal;