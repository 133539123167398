import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './TitleEdit.scss';
import FormField from '../form/FormField';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import { FormMessagesWithField, addError, hasError, getFieldMessages } from '../../helpers/forms';

export const MIN_TITLE_LENGTH = 10;

export type ComponentProps = {
    title: string,
    onTitleChange: (newtitle: string) => void
}

type FormField = 'title';

const TitleEdit: React.FC<ComponentProps> = ({ title, onTitleChange }) => {
    const { t } = useTranslation();
    const [text, setText] = useState(title);
    const [messages, setMessages] = useFormMessageState<FormField>();

    useEffect(() => {
        setText(title);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [title]);

    const checkText = (text: string) => {
        let messages: FormMessagesWithField<FormField> = [];
        const isTooShort = (text.length > 0 && text.length < MIN_TITLE_LENGTH);
        const isEmpty = text.length === 0;
        if (isTooShort) {
            messages = addError<FormField>(messages, ['title'], t('add.title.error.tooShort'));
        }
        if (isEmpty) {
            messages = addError<FormField>(messages, ['title'], t('add.title.error.mandatory'));
        }
        setMessages(messages);
        return messages;
    }

    const changeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newText = e.target.value.trimLeft();
        setText(newText);
        const messages = checkText(newText);
        if (!hasError(messages)) {
            onTitleChange(newText);
        }
    }

    return (
        <FormField className="title-edit" messages={getFieldMessages<FormField>('title', messages)}>
            <input
                className={"title"}
                type="text" value={text}
                onChange={changeTitle}
                onBlur={() => { checkText(text) }}
                placeholder={t("add.title.placeholder")}
            />
        </FormField>
    );
}

export default TitleEdit