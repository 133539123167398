import { ArticleDetailsDto, MangoPayCardRegisterDto, TransactionDetailsDto, UserCredentialsDto, UserDto } from "@youzd/ref-data";
import { AnyAction, Reducer } from 'redux';
import { NullableArticleWithTempPhotos } from '../components/vendre/AddArticle';
import { ApiError, Operation } from '../service/api';
import { setAuth } from '../service/cookies';
import { getDeferredActionLocalStorage, getDraftArticleLocalStorage, getStashedRouteLocalStorage, persistDeferredActionToLocalstorage, persistDraftArticleToLocalstorage, persistStashedRouteToLocalStorage } from '../service/localstorage';
import { actions, DeferedAction } from './actions';

export type AppState = {
    article: ArticleDetailsDto | undefined,
    draftArticle: NullableArticleWithTempPhotos,
    credentials: UserCredentialsDto | undefined,
    user: UserDto | undefined,
    apiErrors: ApiError[],
    pendingOperations: Operation[],
    stashedRoute: string | undefined,
    deferredAction: DeferedAction,
    youzdDeliveryZipCodes: string[],
    cardRegisterData: MangoPayCardRegisterDto | undefined,
    transaction: TransactionDetailsDto | undefined,
}

const initialState = {
    article: undefined,
    draftArticle: getDraftArticleLocalStorage(),
    credentials: undefined,
    user: undefined,
    apiErrors: [],
    pendingOperations: [],
    stashedRoute: getStashedRouteLocalStorage(),
    deferredAction: getDeferredActionLocalStorage(),
    youzdDeliveryZipCodes: [],
    cardRegisterData: undefined,
    transaction: undefined,
}

const appReducer: Reducer<AppState> = (state = { ...initialState }, action: AnyAction) => {
    switch (action.type) {
        case actions.USED_CREDENTIALS:
            return { ...state, credentials: { ...action.credentials, password: '' } };
        case actions.USER_LOGGEDIN:
            setAuth(action.user);
            return { ...state, user: action.user.user };
        case actions.ADD_API_ERRORS:
            return { ...state, apiErrors: [...state.apiErrors, ...action.errors] }
        case actions.CLEAR_API_ERRORS:
            return { ...state, apiErrors: state.apiErrors.filter(e => e.operation !== action.operation) }
        case actions.API_START:
            return { ...state, pendingOperations: [...state.pendingOperations, action.operation] }
        case actions.API_FINISH:
            return { ...state, pendingOperations: state.pendingOperations.filter(o => o !== action.operation) }
        case actions.USER_UPDATED:
            return { ...state, user: action.user }
        case actions.RECORD_REDIRECT:
            persistStashedRouteToLocalStorage(action.route);
            return { ...state, stashedRoute: action.route }
        case actions.REDIRECTED:
            persistStashedRouteToLocalStorage(undefined);
            return { ...state, stashedRoute: undefined }
        case actions.DEFER_ACTION:
            persistDeferredActionToLocalstorage(action.deferedAction);
            return { ...state, deferredAction: action.deferedAction }
        case actions.CLEAR_DEFER_ACTION:
            persistDeferredActionToLocalstorage(undefined);
            return { ...state, deferredAction: '' }
        case actions.SET_DRAFT_ARTICLE:
            persistDraftArticleToLocalstorage(action.article);
            return { ...state, draftArticle: action.article }
        case actions.SET_TRANSACTION:
            return { ...state, transaction: action.transaction }
        case actions.SET_ARTICLE:
            return { ...state, article: action.article };
        case actions.SET_DELIVERY_ZIPCODES:
            return { ...state, youzdDeliveryZipCodes: action.codes };
        case actions.SET_CARD_REGISTER_DATA:
            return { ...state, cardRegisterData: action.data };
        default:
            if (!action.type.startsWith('@@')) {
                console.log(`ignored action ${action.type}`)
            }
            return state;
    }
}

export default appReducer;