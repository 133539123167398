import { TransactionDetailsDto } from '@youzd/ref-data';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../helpers/numbers';
import ArticleSummary from '../article/ArticleSummary';
import './TransactionDetails.scss';

type ComponentProps = {
    transaction: TransactionDetailsDto
}

const TransactionDetails: React.FC<ComponentProps> = ({ transaction }) => {
    const { t } = useTranslation();
    return <div className="transaction-details-container">
        <ArticleSummary article={transaction.article} />
        <div className="prices">
            <div className="article-price price-line">
                <span className="label">{t('transactionSummary.articlePrice')} :</span>
                <span className="amount">{formatAmount(transaction.articlePrice, true)}&nbsp;&euro;</span>
            </div>
            <div className="shipping price-line">
                <span className="label">{t('transactionSummary.shipping')} :</span>
                <span className="amount">{formatAmount(transaction.buyerDeliveryTTC, true)}&nbsp;&euro;</span>
            </div>
            <div className="comission price-line">
                <span className="label">{t('transactionSummary.commission')} :</span>
                <span className="amount">{formatAmount(transaction.youzdComissionTTC, true)}&nbsp;&euro;</span>
            </div>
            <div className="total price-line">
                <span className="label">{t('transactionSummary.total')} :</span>
                <span className="amount">{formatAmount(transaction.buyerPayinAmount, true)}&nbsp;&euro;</span>
            </div>
        </div>
    </div>;
}

export default React.memo(TransactionDetails);