import { CreateTransactionDto, MangoPayCardRegisterDto, MangoPayUserDto, TransactionDetailsDto, UserCard } from "@youzd/ref-data";
import { getAuthWithRefresh, handleError } from "./api";
import { API_HOST } from "./config";

export const saveLike = (articleUid: string) => {
    return new Promise<void>(async (resolve, reject) => {
        getAuthWithRefresh('SAVE_LIKE').then(async auth => {

            const url = `/api/like/${articleUid}`;
            const options: RequestInit = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                }
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('SAVE_LIKE', r))
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        }).catch(e => reject(e));
    });
}

export const deleteLike = (articleUid: string) => {
    return new Promise<void>(async (resolve, reject) => {
        getAuthWithRefresh('DELETE_LIKE').then(async auth => {
            const url = `/api/like/${articleUid}`;
            const options: RequestInit = {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                }
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('DELETE_LIKE', r))
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}

export const createTransaction = (transaction: CreateTransactionDto) => {
    return new Promise<TransactionDetailsDto>(async (resolve, reject) => {
        getAuthWithRefresh('CREATE_TRANSACTION').then(async auth => {
            const url = `/api/transaction`;
            const options: RequestInit = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify(transaction)
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('CREATE_TRANSACTION', r))
                .then(async r => await r.json())
                .then((transaction) => {
                    resolve(transaction);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}

export const getCardRegisterData = (mangoUserInfo: MangoPayUserDto) => {
    return new Promise<MangoPayCardRegisterDto>(async (resolve, reject) => {
        getAuthWithRefresh('GET_CARD_REGISTER').then(async auth => {
            const url = `/api/card`;
            const options: RequestInit = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify(mangoUserInfo)
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('GET_CARD_REGISTER', r))
                .then(async r => await r.json())
                .then((registerData) => {
                    resolve(registerData);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}

export const payWithCardRegisterData = (cardInfo: MangoPayCardRegisterDto) => {
    return new Promise<UserCard>(async (resolve, reject) => {
        getAuthWithRefresh('PAY_CARD_REGISTER').then(async auth => {
            const url = `/api/card`;
            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify(cardInfo)
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('PAY_CARD_REGISTER', r))
                .then(async r => await r.json())
                .then((payResult) => {
                    resolve(payResult);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}

export const loadTransaction = (transactionUid: String) => {
    return new Promise<TransactionDetailsDto>(async (resolve, reject) => {
        getAuthWithRefresh('LOAD_TRANSACTION').then(async auth => {
            const url = `/api/transaction/${transactionUid}`;
            const options: RequestInit = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                }
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('LOAD_TRANSACTION', r))
                .then(async r => await r.json())
                .then((transaction) => {
                    resolve(transaction);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}