import React from 'react';
import { ArticleState, ArticleStates } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import './ArticleStateSelect.scss';
import RadioOptions, { RadioOption } from '../controls/RadioOptions';
import FormField from '../form/FormField';


type ComponentProps = {
    articleState: ArticleState | undefined,
    onArticleStateChanged: (newState: ArticleState) => void,
}

const ArticleStateSelect: React.FC<ComponentProps> = ({ articleState, onArticleStateChanged }) => {

    const { t } = useTranslation();
    const states = Object.keys(ArticleStates);

    const stateOptions: RadioOption[] = states.map(s => ({
        label: t(`article_state.${s}.label`),
        value: s,
        description: t(`article_state.${s}.description`),
    }));
    return (
        <FormField className="article-state">
            <RadioOptions value={articleState} onChange={onArticleStateChanged} layout='vertical'>
                {stateOptions}
            </RadioOptions>
        </FormField>

    )
}

export default ArticleStateSelect;