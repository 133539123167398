import React from 'react';
import './Tip.scss';

type ComponentProps = {
    title: string,
    text: string
}

const Tip: React.FC<ComponentProps> = ({ title, text }) => {
    return (<div className='tip'><h3>{title}</h3><span>{text}</span></div>)
}

export default Tip;