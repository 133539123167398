import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonFr from './fr/common.json';

i18n.use(initReactI18next).init({
    resources: {
        fr: { translation: commonFr }
    },
    lng: "fr",
    //keySeparator: false,
    interpolation: {
        escapeValue: false
    }
})