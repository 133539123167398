import React from 'react';
import Button from './Button';
import './Cta.scss';

type ComponentProps = {
    label: string,
    onClick?: (event: React.MouseEvent) => void,
    disabled?: boolean,
    className?: string,
    loading?: boolean,
    layout?: 'fixed' | 'collapsable'
}

const Cta: React.FC<ComponentProps> = ({ onClick, label, children, className, disabled = false, loading = false, layout }) => {
    const layoutClassName = layout === 'collapsable' ? ['collapsable', disabled ? 'collapsed' : 'expanded'] : ['fixed'];
    return (
        <div className={["cta", ...layoutClassName].join(' ')}>
            <Button onClick={onClick} disabled={disabled} className={className} loading={loading}>{label}</Button>
            {children}
        </div>
    );
}

export default Cta;