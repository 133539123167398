import React from 'react';
import { useMedia } from '../../hooks/useMedia';

type ComponentProps = {
    help?: string,
    className?: string,
    active?: boolean,
}

const AttributesSection: React.FC<ComponentProps> = ({ help, className, active, children }) => {
    const { isDesktop } = useMedia();
    return (
        <div className={["attributes-section", className ? className : '', active ? 'active' : ''].join(' ')}>
            <div className="attributes-content">
                {children}
            </div>
            {isDesktop && help && (
                <div className="attributes-help" dangerouslySetInnerHTML={{ __html: help }} />
            )}

        </div>
    );
}

export default AttributesSection;