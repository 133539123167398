import React from 'react';
import { AnyImageKind } from '../../helpers/photos';
import deleteIcon from '../../assets/controls/delete.svg';
import editIcon from '../../assets/controls/edit.svg';
import Photo from './Photo';

type ComponentProps = {
    p: AnyImageKind,
    index: number,
    canDelete: boolean
    doZoom: (index: number) => void
    doDelete: (index: number) => void,
}


const StaticPhotoEditorElement: React.FC<ComponentProps> = ({ p, index, doZoom, canDelete, doDelete }) => {
    return (
        <li>
            <div onClick={() => doZoom(index)}>
                <Photo image={p.thumb} />
            </div>
            <div className="controls">
                <div className="control edit" onClick={() => doZoom(index)}><img src={editIcon} alt="" /></div>
                {(canDelete) ? <div className="control delete" onClick={() => doDelete(index)}><img src={deleteIcon} alt="" /></div> : <div></div>}
            </div>
        </li>
    );
}

export default StaticPhotoEditorElement;