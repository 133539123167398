import { ArticleDetailsDto, UserDto } from '@youzd/ref-data';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AnyThunkDispatch } from '../../redux/actions';
import { doLoadArticleAd } from "../../redux/articleActions";
import { AppState } from '../../redux/reducer';
import { doDeleteLike, doSaveLike } from "../../redux/transactionActions";
import Loader from '../template/Loader';
import Ad from './Ad';

type PropsFromState = {
    uid: string;
    article: ArticleDetailsDto | undefined,
    liked: boolean,
    canLike: boolean,
    user: UserDto | undefined,
};

type DispatchProps = {
    loadArticle: (uid: string) => void,
    setLiked: (liked: boolean) => void,
}

type ComponentProps = PropsFromState & DispatchProps;

const AdContainer: React.FC<ComponentProps> = ({ uid, article, loadArticle, liked, setLiked, canLike, user }) => {
    const [userLoggedIn, setUserLoggedIn] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (article && user && user.uid && userLoggedIn !== user.uid) {
            setUserLoggedIn(user.uid);
            loadArticle(uid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    if (!article || article.uid !== uid) {
        loadArticle(uid);
        return (<Loader></Loader>);
    } else {
        const isOwner = article.owner.uid === user?.uid;
        return (<Ad
            article={article}
            liked={liked}
            setLiked={setLiked}
            canLike={canLike}
            showTransactions={article.adStatus === 'Published' && !isOwner}
            isOwner={isOwner}
        />)
    }
}

const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ uid: string }>): PropsFromState => {
    const articleUid = routeProps.match.params.uid
    const hasLike = state.user?.likes.find(l => l.articleUid === articleUid) !== undefined;
    return ({
        canLike: state.user !== undefined,
        uid: articleUid,
        article: state.article,
        liked: hasLike,
        user: state.user
    })
}

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps<{ uid: string }>): DispatchProps => ({
    loadArticle: (uid: string) => {
        dispatch(doLoadArticleAd(uid));
    },
    setLiked: (liked: boolean) => {
        const articleUid = routeProps.match.params.uid;
        if (liked) {
            dispatch(doSaveLike(articleUid, routeProps));
        } else {
            dispatch(doDeleteLike(articleUid, routeProps));
        }
    }
});

export default connect(mapStateToProps, dispatchToProps)(AdContainer);