import React from 'react';
import { getImageUrl, AnyImageKind } from '../../helpers/photos';
import './Photo.scss'

type ComponentProps = {
    image: AnyImageKind | undefined;
    alt?: string;
    className?: string;
}

const Photo: React.FC<ComponentProps> = ({ image, alt, className }) => {
    const src = image && getImageUrl(image);
    return (<>
        {src && <img className={["photo", className].join(' ')} src={src} alt={alt} />}
    </>
    );
}

export default React.memo(Photo);