
export type MessageLevel = 'error' | 'warning' | 'info';

export type FormMessage = {
    text: string,
    level: MessageLevel
}

export type FormMessageWithField<F> = {
    relatedFields: (F | 'all')[],
    message: FormMessage,
}

export type FormMessagesWithField<F> = FormMessageWithField<F>[];

function createMessage<F>(fields: (F | 'all')[], text: string, level: MessageLevel): FormMessageWithField<F> {
    return ({
        relatedFields: fields,
        message: {
            text,
            level
        }
    });
}

export function createError<F>(fields: (F | 'all')[], text: string): FormMessageWithField<F> {
    return createMessage(fields, text, 'error');
}
export function createWarning<F>(fields: (F | 'all')[], text: string): FormMessageWithField<F> {
    return createMessage(fields, text, 'warning');
}
export function createInfo<F>(fields: (F | 'all')[], text: string): FormMessageWithField<F> {
    return createMessage(fields, text, 'info');
}

function addMessage<F>(messages: FormMessagesWithField<F>, fields: (F | 'all')[], text: string, level: MessageLevel) {
    return [...messages, createMessage(fields, text, level)];
}

export function hasError<F>(messages: FormMessagesWithField<F>): boolean {
    return messages.find(m => m.message.level === 'error') !== undefined;
}

export function addError<F>(messages: FormMessagesWithField<F>, fields: (F | 'all')[], text: string) {
    return addMessage(messages, fields, text, 'error')
}
export function addWarning<F>(messages: FormMessagesWithField<F>, fields: (F | 'all')[], text: string) {
    return addMessage(messages, fields, text, 'warning')
}
export function addInfo<F>(messages: FormMessagesWithField<F>, fields: (F | 'all')[], text: string) {
    return addMessage(messages, fields, text, 'info')
}


export function getFieldMessages<F>(field: F, allMessages: FormMessagesWithField<F>): FormMessage[] {
    return allMessages.filter(m => m.relatedFields.includes(field)).map(f => f.message);
}

export function getFieldErrors<F>(field: F, allMessages: FormMessagesWithField<F>): FormMessage[] {
    return allMessages.filter(m => m.relatedFields.includes(field) && m.message.level === 'error').map(f => f.message);
}

function getMessagesWithLevel(messages: FormMessage[] | undefined, level: MessageLevel): string[] {
    return messages ? messages.filter(m => m.level === level).map(m => m.text) : [];
}

export function getErrors(messages: FormMessage[] | undefined): string[] {
    return getMessagesWithLevel(messages, 'error');
}

export function getWarnings(messages: FormMessage[] | undefined): string[] {
    return getMessagesWithLevel(messages, 'warning');
}

export function getInfos(messages: FormMessage[] | undefined): string[] {
    return getMessagesWithLevel(messages, 'info');
}

export function getHighestMessageLevel(messages: FormMessage[] | undefined): MessageLevel | undefined {
    return messages ? messages.reduce<MessageLevel | undefined>((max, m) => {
        let level: MessageLevel;
        switch (m.level) {
            case 'error':
                level = 'error';
                break;
            case 'warning':
                level = max !== 'error' ? 'warning' : max;
                break;
            case 'info':
                level = max !== 'error' && max !== 'warning' ? 'info' : max;
                break;
        }
        return level;
    }, undefined) : undefined;
}



export function checkValidLuhn(num: string) {
    var ca, sum = 0, mul = 0;
    var len = num.length;
    while (len--) {
        ca = parseInt(num.charAt(len), 10) << mul; // multiply by two if an even position. All ints, use bite shifting
        sum += ca - (ca > 9 ? 1 : 0) * 9; //if more than 10, sum of digits is number minus 9

        mul ^= 1; //switch
    };
    return (sum % 10 === 0) && (sum > 0);
};
