import React, { useState, useEffect } from 'react';
import { WarrantyDto } from '@youzd/ref-data';
import FormField from '../form/FormField';
import Toggle from '../controls/Toggle';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import { FormMessageWithField, getFieldMessages, hasError } from '../../helpers/forms';
import { useTranslation } from 'react-i18next';
import { utc } from 'moment';
import { API_DATETIME_FORMAT } from '../../service/api';
import { apiToUtc, html5DateToUtc, utcToHtml5Date } from '../../helpers/time';

type ComponentProps = {
    warranty: WarrantyDto | undefined,
    onChange: (warranty: WarrantyDto) => void
}

type FormField = 'hasWarranty' | 'warrantyEndDate';

const WarrantyEdit: React.FC<ComponentProps> = ({ warranty, onChange }) => {
    const { t } = useTranslation();

    const warrantyToState = (warranty: WarrantyDto | undefined) => ({
        hasWarranty: false,
        warrantyEndDate: warranty?.warrantyEndDate ? apiToUtc(warranty?.warrantyEndDate) : undefined
    });

    const [state, setState] = useState(warrantyToState(warranty));
    const [changed, setChanged] = useState(false);
    const [dateEdited, setDateEdited] = useState(false);

    const [messages, setMessages] = useFormMessageState<FormField>();

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [state, changed, dateEdited])

    const validate = () => {
        const messages: FormMessageWithField<FormField>[] = [];

        if (state.hasWarranty && state.warrantyEndDate && state.warrantyEndDate.isSameOrBefore(utc())) {
            messages.push({
                relatedFields: ['warrantyEndDate'],
                message: { text: t('add.warranty.error.past'), level: 'error' }
            });
        }

        if (dateEdited && state.hasWarranty && !state.warrantyEndDate) {
            messages.push({
                relatedFields: ['warrantyEndDate'],
                message: { text: t('add.warranty.error.mandatory'), level: 'error' }
            })
        }
        setMessages(messages)
        if ((changed || dateEdited) && (!state.hasWarranty || state.warrantyEndDate) && !hasError(messages)) {
            onChange({
                hasWarranty: state.hasWarranty,
                warrantyEndDate: state.warrantyEndDate?.format(API_DATETIME_FORMAT) || ''
            });
            setChanged(false);
            setDateEdited(false);
        }
    }

    const changeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEndDate = event.target.value ? html5DateToUtc(event.target.value) : undefined;
        if (newEndDate) {
            setState({ ...state, warrantyEndDate: newEndDate });
        }
        setChanged(true);
    }

    const toggleWarranty = (hasWarranty: boolean) => {
        setState({ ...state, hasWarranty });
        setChanged(true);
    }

    return (
        <div className="warranty">
            <FormField className="has-warranty" label={t('add.warranty.label')} layout='inline'>
                <Toggle toggled={state.hasWarranty} onToggle={toggleWarranty} />
            </FormField>
            {state.hasWarranty &&
                <FormField
                    className="end-date"
                    label={t('add.warranty.until')}
                    messages={getFieldMessages('warrantyEndDate', messages)} layout="inline"
                >
                    <input
                        type="date" value={state.warrantyEndDate ? utcToHtml5Date(state.warrantyEndDate) : ''}
                        onChange={changeEndDate}
                        onFocus={() => setDateEdited(false)}
                        onBlur={() => setDateEdited(true)}
                    />
                </FormField>
            }
        </div>
    );
};

export default WarrantyEdit;