import { UserCredentialsDto, UserDto, UserLoggedInDto } from '@youzd/ref-data';
import { getAuthWithRefresh, handleError } from './api';
import { API_HOST } from './config';

export const loadUser = () => {
    return new Promise<UserDto>(async (resolve, reject) => {

        getAuthWithRefresh('LOAD_PROFILE').then(async auth => {
            const url = `${API_HOST}/api/user`;
            const options: RequestInit = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                }
            };
            await fetch(url, options)
                .then(r => handleError('LOAD_PROFILE', r))
                .then(async r => {
                    const result = await r.json()
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        }).catch(e => reject(e));

    });
}

export const getChatToken = () => {
    return new Promise<string>(async (resolve, reject) => {

        getAuthWithRefresh('GET_CHAT_TOKEN').then(async auth => {
            const url = `${API_HOST}/api/chatToken`;
            const options: RequestInit = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                }
            };
            await fetch(url, options)
                .then(r => handleError('GET_CHAT_TOKEN', r))
                .then(async r => {
                    const result = await r.text()
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        }).catch(e => reject(e));

    });
}

export const resetPassword = (email: string) => {
    return new Promise<void>(async (resolve, reject) => {
        const url = `${API_HOST}/api/password`;
        const options: RequestInit = {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
        };
        await fetch(url, options)
            .then(r => handleError('RESET_PASSWORD', r))
            .then(async r => {
                resolve();
            })
            .catch((err) => {
                reject(err);
            });
    })
}


export const changePassword = (password: string, token?: string) => {
    return new Promise<UserLoggedInDto>(async (resolve, reject) => {
        getAuthWithRefresh('CHANGE_PASSWORD', token).then(async auth => {
            const url = `${API_HOST}/api/password`;
            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify({ password })
            };
            await fetch(url, options)
                .then(r => handleError('CHANGE_PASSWORD', r))
                .then(async r => {
                    const result = await r.json()
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                });
        }).catch(reason => reject());
    })
}

export const saveUser = (user: UserDto) => {
    return new Promise<void>(async (resolve, reject) => {
        getAuthWithRefresh('SAVE_PROFILE').then(async auth => {
            const url = `${API_HOST}/api/user`;
            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify(user)
            };
            await fetch(url, options)
                .then(r => handleError('SAVE_PROFILE', r))
                .then(async r => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        })
    });
}


export const refresh = (credentials: UserCredentialsDto) =>
    new Promise<UserLoggedInDto>(async (resolve, reject) => {
        const url = `${API_HOST}/api/login`;
        const postOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials)
        };
        await fetch(url, postOptions)
            .then(r => handleError('REFRESH_TOKEN', r))
            .then(async (r) => {
                resolve(await r.json());
            }).catch(err => reject(err));
    });


export const registerUser = (user: UserCredentialsDto) => {
    return new Promise<UserLoggedInDto>(async (resolve, reject) => {
        const uri = '/api/user';
        const method = 'PUT';
        const putOptions: RequestInit = {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        };
        await fetch(`${API_HOST}${uri}`, putOptions)
            .then(r => handleError('REGISTER_USER', r))
            .then(async r => {
                const result = await r.json()
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export const socialSignin = (user: UserCredentialsDto) => {
    return new Promise<UserLoggedInDto & { created: boolean }>(async (resolve, reject) => {
        const url = `${API_HOST}/api/social`;
        const method = 'POST';
        const postOptions: RequestInit = {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        };
        await fetch(url, postOptions)
            .then(r => handleError('SOCIAL_SGININ', r))
            .then(async r => {
                const result = await r.json()
                result.created = r.status === 201;
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
    });
}

export const loginUser = (user: UserCredentialsDto) => {
    return new Promise<UserLoggedInDto>(async (resolve, reject) => {
        const uri = '/api/login';
        const method = 'POST';
        const postOptions: RequestInit = {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        };
        await fetch(`${API_HOST}${uri}`, postOptions)
            .then(r => handleError('LOGIN', r))
            .then(async r => {
                const result = await r.json()
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
    });
}
