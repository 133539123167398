import React from 'react';
import { connect } from 'react-redux';
import BurgerMenu from './BurgerMenu';
import { AppState } from '../../redux/reducer';
import { useMedia } from '../../hooks/useMedia';
import FlatMenu from './FlatMenu';
import { UserDto } from '@youzd/ref-data';


type StateProps = {
    logged: boolean,
    user?: UserDto,
}

type ComponentProps = StateProps


const MenuContainer: React.FC<ComponentProps> = ({ logged, user }) => {
    const { isDesktop } = useMedia();
    return isDesktop ? (<FlatMenu logged={logged} user={user} />) : (<BurgerMenu logged={logged} />);
}

const mapStateToProps = (state: AppState): StateProps => ({
    logged: state.user !== undefined,
    user: state.user
})


export default connect(mapStateToProps)(MenuContainer);