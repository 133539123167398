import React from 'react';
import { ShippingOption, ShippingOptionName, ShippingSize } from '@youzd/ref-data';
import './ShippingOptionSelect.scss';
import ShippingOptionToggle from './ShippingOptionToggle';
import { getEligibleShipingOptions } from '../../helpers/shippingOptionsHelpers';

export type ComponentProps = {
    shippingOptions: ShippingOption[],
    onOptionsChanged: (options: ShippingOption[]) => void,
    endPrice?: number,
    zipCode?: string,
    shipSize?: ShippingSize,
    youzdZipCodes?: string[],
}

export type ComponentState = {
    optionNames: ShippingOptionName[],
    optionLimit: number | '',
    optionPrice: number | ''
}

const ShippingOptionSelect: React.FC<ComponentProps> = ({ shippingOptions, onOptionsChanged, zipCode, youzdZipCodes, shipSize, endPrice }) => {
    const possibleOptionTypes = getEligibleShipingOptions(shipSize, zipCode, youzdZipCodes || []);

    const shippingOptionsEntries = possibleOptionTypes.map((o, index) => {
        const optionType = o as ShippingOptionName;
        return <div key={optionType}>
            {index !== 0 && <hr />}
            <ShippingOptionToggle
                type={optionType}
                option={shippingOptions.find(o => o.type === optionType)}
                changeOption={(option) => onOptionsChanged([...shippingOptions.filter(o => o.type !== option.type), option])}
                removeOption={(type) => onOptionsChanged([...shippingOptions.filter(o => o.type !== type)])}
                endPrice={endPrice}
                shipSize={shipSize}
            />

        </div>
    });
    return (
        <div className="shipping-options-container">
            {shippingOptionsEntries}
        </div>
    );
}

export default ShippingOptionSelect;