import React, { useEffect, useState } from 'react';
import { ShippingOption, ShippingOptionName, ShippingOptionNames, SellerDelivery, ShippingSize, YouzdDelivery, PickAtSellerDelivery, youzdDeliveryPrices, FloorDto } from '@youzd/ref-data';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import { FormMessagesWithField, addError, addWarning, getFieldMessages } from '../../helpers/forms';
import { useTranslation } from 'react-i18next';
import FormField from '../form/FormField';
import { sprintf } from 'sprintf-js';
import FloorEditorContainer from '../geo/FloorEditorContainer';
import CheckBox from '../controls/CheckBox';

type ComponentProps = {
    type: ShippingOptionName,
    option: ShippingOption | undefined,
    changeOption: (option: ShippingOption) => void,
    removeOption: (type: ShippingOptionName) => void,
    endPrice?: number,
    shipSize?: ShippingSize,
}

const getEmptyOption = (type: ShippingOptionName | undefined, shipSize?: ShippingSize): ShippingOption => {
    switch (type) {
        case ShippingOptionNames.pickAtSeller:
            return {
                type: ShippingOptionNames.pickAtSeller,
                floor: 0,
                hasElevator: false
            }
        case ShippingOptionNames.pickup:
            return {
                type: ShippingOptionNames.pickup,
            }
        case ShippingOptionNames.sellerDelivery:
            return {
                type: ShippingOptionNames.sellerDelivery,
                shippingLimit: 10,
                shippingPrice: 19,
            }
        case ShippingOptionNames.youzdDelivery:
            const price = youzdDeliveryPrices.find(p => p.size === shipSize);
            if (price) {
                return {
                    type: ShippingOptionNames.youzdDelivery,
                    buyerCost: price.buyerCost,
                    sellerCost: price.sellerCost,
                }
            } else {
                throw new Error('invalid size');
            }
        default:
            throw new Error('invalid type');
    }
}

type FormFields = 'limit' | 'price'

const ShippingOptionToggle: React.FC<ComponentProps> = ({ type, option, changeOption, removeOption, endPrice, shipSize }) => {
    const optionToLimit = (option: ShippingOption | undefined) => {
        return (option as SellerDelivery)?.shippingLimit || 0;
    }
    const optionToPrice = (option: ShippingOption | undefined) => {
        return (option as SellerDelivery)?.shippingPrice || 0;
    }
    const [messages, setMessages] = useFormMessageState<FormFields>();
    const [limit, setLimit] = useState(optionToLimit(option));
    const [price, setPrice] = useState(optionToPrice(option));
    const { t } = useTranslation();

    const onToggle = (present: boolean) => {
        if (present) {
            if (!option) {
                changeOption(getEmptyOption(type, shipSize));
            }
        } else {
            setMessages([]);
            removeOption(type);
        }
    }

    useEffect(() => {
        if (type === ShippingOptionNames.youzdDelivery && option) {
            changeOption(getEmptyOption(type, shipSize));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [shipSize])


    useEffect(() => {
        if (option && type === ShippingOptionNames.youzdDelivery) {
            let messages: FormMessagesWithField<FormFields> = [];
            const youzdDelivery = (option as YouzdDelivery);
            if (endPrice && youzdDelivery.sellerCost > endPrice) {
                messages = addWarning(messages, ['all'], t(`shipOption.youzdDelivery.minPriceWarning`));
            }
            setMessages(messages);
        }
        if (option && type === ShippingOptionNames.sellerDelivery) {
            setLimit(optionToLimit(option));
            setPrice(optionToPrice(option));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [option, endPrice]);


    const changeFloor = (floor: FloorDto) => {
        const pickAtSeller = (option as PickAtSellerDelivery);
        changeOption({ ...pickAtSeller, floor: floor.floor, hasElevator: floor.hasElevator });
    }

    const changeLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        let messages: FormMessagesWithField<FormFields> = [];
        const shippingLimit = parseInt(e.target.value);
        setLimit(shippingLimit);
        if (isNaN(shippingLimit) || shippingLimit < 1) {
            messages = addError(messages, ['limit'], t(`shipOption.sellerDelivery.errors.minLimit`));
        } else {
            const sellerDelivery = (option as SellerDelivery);
            changeOption({ ...sellerDelivery, shippingLimit })
        }
        setMessages(messages);
    }

    const changePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        let messages: FormMessagesWithField<FormFields> = [];
        const shippingPrice = parseFloat(e.target.value);
        setPrice(shippingPrice);
        if (shippingPrice < 0) {
            messages = addError(messages, ['price'], t(`shipOption.sellerDelivery.errors.minPrice`));
        } else {
            const sellerDelivery = (option as SellerDelivery);
            changeOption({ ...sellerDelivery, shippingPrice })
        }
        setMessages(messages);
    }

    const isPresent = option !== undefined;

    return (<div className={["ship-option", type].join(" ")}>
        <FormField
            messages={getFieldMessages('all', messages)}
            className="option"
            layout='inline'
        >
            <CheckBox checked={isPresent} onChecked={onToggle} />
            <label onClick={() => onToggle(option === undefined)}>{t(`shipOption.${type}.label`)}</label>
            <span className="description">{t(`shipOption.${type}.description`)}</span>
        </FormField>
        {type === ShippingOptionNames.pickAtSeller && isPresent &&
            <>
                <FloorEditorContainer
                    value={{
                        floor: (option as PickAtSellerDelivery).floor,
                        hasElevator: (option as PickAtSellerDelivery).hasElevator
                    }}
                    onChange={changeFloor} />
                <span className="description">{t(`shipOption.pickAtSeller.comment`)}</span>
            </>
        }
        {type === ShippingOptionNames.sellerDelivery && isPresent &&
            <>
                <FormField
                    className="limit"
                    label={t('shipOption.sellerDelivery.max')}
                    layout="reverse"
                    messages={getFieldMessages('limit', messages)}
                >
                    <input type="number"
                        value={limit || ''}
                        onChange={changeLimit}
                    />
                </FormField>
                <FormField
                    className="price"
                    label={t('shipOption.sellerDelivery.price')}
                    layout="reverse"
                    messages={getFieldMessages('price', messages)}
                >
                    <input type="number"
                        value={price || ''}
                        onChange={changePrice}
                    />
                </FormField>
            </>
        }
        {type === ShippingOptionNames.youzdDelivery && isPresent &&
            <>
                <span className="explanation" dangerouslySetInnerHTML={{ __html: t(`shipOption.youzdDelivery.explanation`) }} />
                <span className="price" dangerouslySetInnerHTML={{ __html: sprintf(t(`shipOption.youzdDelivery.price`), (option as YouzdDelivery).sellerCost) }} />
            </>
        }

    </div>);
}

export default ShippingOptionToggle;