import React, { useState, useEffect } from 'react';
import { UserCredentialsDto } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import Button from '../controls/Button';
import './LoginForm.scss';
import FormField from '../form/FormField';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import { hasError, addError, FormMessagesWithField, getFieldMessages } from '../../helpers/forms';
import { Link } from 'react-router-dom';

type ComponentState = {
    login: string,
    password: string,
}

type ComponentProps = {
    credentials: UserCredentialsDto | undefined,
    error: string,
    onLogin: (credentials: UserCredentialsDto) => void,
    logingin: boolean,
}

export type FormField = 'login' | 'password'

const Login: React.FC<ComponentProps> = ({ credentials, error, onLogin, logingin }) => {
    const { t } = useTranslation();
    const [state, setState] = useState<ComponentState>({
        login: credentials?.email || '',
        password: ''
    });
    const [messages, setMessages] = useFormMessageState<FormField>(error ? t(`login.${error}`) : undefined);

    useEffect(() => {
        if (error) {
            let messages: FormMessagesWithField<FormField> = [];
            messages = addError<FormField>(messages, ['all'], t(`login.${error}`));
            messages = addError<FormField>(messages, ['login', 'password'], '');
            setMessages(messages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const changeLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, login: event.target.value.trimLeft() });
        setMessages([]);
    }

    const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, password: event.target.value.trimLeft() });
        setMessages([]);
    }

    const canLogin = !hasError(messages) && state.login && state.password;
    const errorMessage = messages.length > 0 ? (<div className="error">{messages.map(m => m.message.text).join(' ')}</div>) : null;
    return (<div className="login-form">
        <FormField className="login" label="" messages={getFieldMessages<FormField>('login', messages)}>
            <input type="email" value={state.login} onChange={changeLogin} placeholder={t("login.email")} />
        </FormField>
        <FormField className="password" label="" messages={getFieldMessages<FormField>('password', messages)}>
            <input type="password" value={state.password} onChange={changePassword} placeholder={t("login.password")} />
        </FormField>
        {errorMessage}
        <div className="wrapper">
            <Button onClick={() => onLogin({ email: state.login, password: state.password })} disabled={!canLogin} loading={logingin}>{t('login.doLogin')}</Button>
        </div>
        <Link to={`/forgot/${state.login}`}>{t('login.forgot')}</Link>
    </div>);
}

export default Login;