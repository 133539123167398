import { ArticleDetailsDto, ArticleDto, LocationDto } from "@youzd/ref-data";
import { getAuthWithRefresh, handleError } from "./api";
import { API_HOST } from "./config";
import { getAuth } from "./cookies";

export const saveArticle = (article: ArticleDto) => {
    return new Promise<void>(async (resolve, reject) => {
        getAuthWithRefresh('SAVE_ARTICLE').then(async auth => {
            const url = '/api/article';
            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify(article)
            };
            await fetch(`${API_HOST}${url}`, options)
                .then(r => handleError('SAVE_ARTICLE', r))
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        }).catch(e => reject(e));
    });

}

export const loadAd = (uid: string, location?: LocationDto) => {
    return new Promise<ArticleDetailsDto>(async (resolve, reject) => {
        const url = `/api/ad/${uid}`;
        const cookieAuth = getAuth();
        const options: RequestInit = {
            method: location ? 'POST' : 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...(cookieAuth ? { 'Authorization': `Bearer ${cookieAuth.token}` } : {})
            },
            ...(location ? { body: JSON.stringify(location) } : {})
        };
        await fetch(`${API_HOST}${url}`, options)
            .then(r => handleError('LOAD_AD', r))
            .then(async r => await r.json())
            .then((article) => {
                resolve(article);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
