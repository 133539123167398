import React from 'react';
import './Form.scss';

type ComponentProps = {
    className?: string;
}

const Form: React.FC<ComponentProps> = ({ className, children }) => {
    return (
        <div className={["youzd-form", className].join(' ')}>
            {children}
        </div>
    );
}

export default Form;