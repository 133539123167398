import { PickAtSellerDelivery, ShippingOptionNames, UserDto } from '@youzd/ref-data';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyThunkDispatch } from '../../redux/actions';
import { AppState } from '../../redux/reducer';
import { doLoadUser, doSaveUser } from "../../redux/userActions";
import { ApiError } from '../../service/api';
import Loader from '../template/Loader';
import EditProfileForm, { ProfileDto } from './EditProfileForm';

type PropsFromState = {
    user: UserDto | undefined,
    errors: ApiError[],
    loading: boolean,
}

type DispatchProps = {
    onSave: (profile: ProfileDto) => void,
    loadUser: () => void,
}

type ComponentProps = PropsFromState & DispatchProps;

const EditProfileFormContainer: React.FC<ComponentProps> = ({ user, onSave, errors, loading, loadUser }) => {
    useEffect(() => {
        if (!user) {
            loadUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return user ? (
        <EditProfileForm user={user} onSave={onSave} errors={errors} loading={loading} />
    ) : (<Loader />);
}

const mapStateToProps = (state: AppState): PropsFromState => {
    const theUser = state.user;
    if (theUser && (!theUser.address || !theUser?.address?.address) && state.draftArticle && state.draftArticle.location) {
        theUser.address = state.draftArticle.location;
    }
    const articlePickOption = state.draftArticle && state.draftArticle.shippingOptions.find(o => o.type === ShippingOptionNames.pickAtSeller) as PickAtSellerDelivery
    if (theUser && !theUser.floor && articlePickOption) {
        theUser.floor = articlePickOption.floor;
        theUser.hasElevator = articlePickOption.hasElevator;
    }
    return {
        user: theUser,
        errors: state.apiErrors.filter(e => e.operation === 'SAVE_PROFILE'),
        loading: state.pendingOperations.find(o => o === 'SAVE_PROFILE') != null,
    }
}

const dispatchToProps = (dispatch: AnyThunkDispatch, routeProps: RouteComponentProps): DispatchProps => ({
    onSave: (profile: ProfileDto) => {
        dispatch(doSaveUser(profile, routeProps));
    },
    loadUser: () => {
        dispatch(doLoadUser(routeProps, true, '/my-profile'));
    }
});


export default withRouter(connect(mapStateToProps, dispatchToProps)(EditProfileFormContainer));