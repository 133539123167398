import React from 'react';
import './CheckBox.scss';

type CheckBoxProps = {
    checked: boolean,
    onChecked: (value: boolean) => void,
    disabled?: boolean,
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChecked, disabled }) => {
    const click = () => {
        if (!disabled) onChecked(!checked);
    }
    return (<div className={["check-box", checked ? 'checked' : ''].join(' ')} onClick={click}></div>)
}

export default CheckBox;