import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../controls/Button';
import './Register.scss';
import YouzdScreen from '../template/YouzdScreen';
import { hasError, addError, FormMessagesWithField, getFieldMessages } from '../../helpers/forms';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import FormField from '../form/FormField';
import { useHistory } from 'react-router-dom';
import './PasswordReset.scss';


type ComponentProps = {
    resetPassword: (email: string) => void,
    loading: boolean,
    loginEmail?: string,
}

export type FormField = 'email'

const PasswordReset: React.FC<ComponentProps> = ({ loginEmail, loading, resetPassword }) => {
    const validEmailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { t } = useTranslation();
    const [messages, setMessages] = useFormMessageState<FormField>(undefined);
    const [email, setEmail] = useState(loginEmail || '');
    const [done, setDone] = useState(false);
    const [doing, setDoing] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (email.length > 0) {
            validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    useEffect(() => {
        if (!doing && loading) {
            setDoing(true);
        }
        if (doing && !loading) {
            setDoing(false);
            setDone(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    useEffect(() => {
        if (loginEmail && loginEmail !== email) {
            setEmail(loginEmail);
            setDone(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginEmail])

    const validate = () => {
        let newMessages: FormMessagesWithField<FormField> = [];
        if (!validEmailRe.test(email)) {
            newMessages = addError<FormField>(newMessages, ['email'], t('password.error.invalidEmail'));
        }
        if (newMessages.length > 0) {
            setMessages(newMessages);
        } else {
            setMessages([]);
        }
    }

    const canSend = email.length > 0 && !hasError(messages);

    return (
        <YouzdScreen header={true} className='grey'>
            <div className="reset-container">
                {!done && <>
                    <h2>{t('password.title')}</h2>
                    <FormField label={t('password.email')} messages={getFieldMessages('email', messages)}>
                        <input
                            type="email"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trimLeft())}
                            value={email} />
                    </FormField>
                    <div className="bottom">
                        <Button onClick={() => { resetPassword(email) }} disabled={!canSend} loading={loading}>{t('password.validate')}</Button>
                    </div>
                </>
                }
                {done && <>
                    <h2>{t('password.title')}</h2>
                    <p>
                        {t('password.done')}
                    </p>
                    <div className="bottom">
                        <Button onClick={() => { history.push('/login') }}>{t('password.back')}</Button>
                    </div>
                </>}
            </div>

        </YouzdScreen>
    );
}

export default PasswordReset;