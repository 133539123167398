import React from 'react';
import './FormGroup.scss';

type ComponentProps = {
    layout?: 'inline';
    className?: string,
}

const FormGroup: React.FC<ComponentProps> = ({ layout, className, children }) => {
    return (
        <div className={["form-group", className, layout].join(' ')}>
            {children}
        </div>
    )
}

export default FormGroup;