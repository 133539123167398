import { UserCredentialsDto, UserDto } from "@youzd/ref-data";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { ProfileDto } from "../components/user/EditProfileForm";
import { getType, PhotoData } from "../helpers/photos";
import { ApiError, getAuthWithRefresh } from "../service/api";
import { saveUserPhoto } from "../service/photos";
import { changePassword, loadUser, loginUser, registerUser, resetPassword, saveUser, socialSignin } from "../service/user";
import { addApiErrors, AnyThunkDispatch, apiFinish, apiStart, clearApiErrors, doNext, recordRedirect, usedCredentials, userChanged, userLoggedIn } from "./actions";
import { AppState } from "./reducer";

export const doLogin = (credentials: UserCredentialsDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    dispatch(clearApiErrors('LOGIN'));
    dispatch(apiStart('LOGIN'));
    loginUser(credentials)
        .then(async (loggedInUser) => {
            dispatch(userLoggedIn(loggedInUser));
            dispatch(doNext(routeProps));
        })
        .catch((err) => {
            dispatch(addApiErrors([err]));
        })
        .finally(() => {
            dispatch(apiFinish('LOGIN'));
        });
});
export const doSocialSignin = (credentials: UserCredentialsDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    dispatch(usedCredentials(credentials));
    dispatch(apiStart('SOCIAL_SGININ'));
    socialSignin(credentials)
        .then(async (loggedin) => {
            const { created, ...userLogin } = loggedin;
            dispatch(userLoggedIn(userLogin));
            if (created) {
                routeProps.history.push('/my-profile');
            }
            else {
                dispatch(doNext(routeProps));
            }
        })
        .catch((err) => { dispatch(addApiErrors([err])); })
        .finally(() => {
            dispatch(apiFinish('SOCIAL_SGININ'));
        });
});
export const doRegisterUser = (credentials: UserCredentialsDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    dispatch(usedCredentials(credentials));
    dispatch(clearApiErrors('REGISTER_USER'));
    dispatch(apiStart('REGISTER_USER'));
    registerUser(credentials)
        .then(loggedInUser => {
            dispatch(userLoggedIn(loggedInUser));
            routeProps.history.push('/my-profile');
        })
        .catch((err) => {
            dispatch(addApiErrors([err as ApiError]));
        })
        .finally(() => {
            dispatch(apiFinish('REGISTER_USER'));
        });
});

export const doLoadUser = (routeProps: RouteComponentProps, doRedirect?: boolean, redirectIfLogin?: string) => (async (dispatch: Dispatch) => {
    const redirectToLogin = () => {
        if (redirectIfLogin) {
            dispatch(recordRedirect(redirectIfLogin));
        }
        routeProps.history.push('/login');
    };
    getAuthWithRefresh('LOAD_PROFILE')
        .then(auth => {
            if (auth.token) {
                dispatch(clearApiErrors('LOAD_PROFILE'));
                dispatch(apiStart('LOAD_PROFILE'));
                loadUser()
                    .then((user) => {
                        dispatch(userChanged(user));
                    })
                    .catch((err) => {
                        if ((err as ApiError).errorType === 'LOGIN_REQUIRED' && doRedirect) {
                            redirectToLogin();
                        }
                        dispatch(addApiErrors([err]));
                    })
                    .finally(() => {
                        dispatch(apiFinish('LOAD_PROFILE'));
                    });
            }
            else {
                doRedirect && redirectToLogin();
            }
        })
        .catch(e => {
            doRedirect && redirectToLogin()
        });
});
export const doSaveUser = (profile: ProfileDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    if (profile) {
        dispatch(clearApiErrors('SAVE_PROFILE'));
        dispatch(apiStart('SAVE_PROFILE'));
        if (profile.photo && getType(profile.photo) === 'data') {
            profile.photo = await saveUserPhoto(profile.uid, (profile.photo as PhotoData));
        }
        const user: UserDto = profile;
        saveUser(user)
            .then(async () => {
                dispatch(userChanged(user));
                dispatch(doNext(routeProps, '/my-profile'));
            })
            .catch((err) => {
                dispatch(addApiErrors([err]));
            })
            .finally(() => {
                dispatch(apiFinish('SAVE_PROFILE'));
            });
    }
});
export const doResetPassword = (email: string) => (async (dispatch: Dispatch) => {
    if (email) {
        dispatch(clearApiErrors('RESET_PASSWORD'));
        dispatch(apiStart('RESET_PASSWORD'));
        resetPassword(email)
            .finally(() => {
                dispatch(apiFinish('RESET_PASSWORD'));
            });
    }
});
export const doChangePassword = (password: string, routeProps: RouteComponentProps, token?: string) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    if (password) {
        dispatch(clearApiErrors('CHANGE_PASSWORD'));
        dispatch(apiStart('CHANGE_PASSWORD'));
        changePassword(password, token)
            .then(async (loggedInUser) => {
                dispatch(userLoggedIn(loggedInUser));
                dispatch(doNext(routeProps));
            })
            .catch(e => dispatch(addApiErrors([e])))
            .finally(() => {
                dispatch(apiFinish('CHANGE_PASSWORD'));
            });
    }
});
