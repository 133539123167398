import React from 'react';
import './NbOfItemsEdit.scss';
import Item from '../../assets/icons/item.svg';

type ComponentProps = {
    numberOfItems: number,
    onNumberOfItems: (numberOfItems: number) => void
}

const NbOfItems: React.FC<ComponentProps> = ({ numberOfItems, onNumberOfItems }) => {
    const values = ['--', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13+'];
    const options = values.map(option => (
        <option key={option} value={option}>{option}</option>
    ));

    const nbToOption = (nb: number) => values[nb];
    const valueToNb = (v: string) => values.indexOf(v);

    return (
        <div className="nb-items-containter">
            <div className={["items", numberOfItems === 0 ? 'empty' : ''].join(' ')}>
                {Array.from(Array(Math.max(1, numberOfItems)).keys()).map((i) => (<div key={i} className="item"><img src={Item} alt="" /></div>))}

            </div>
            <div className="decrease" onClick={() => onNumberOfItems(Math.max(1, numberOfItems - 1))}>-</div>
            <select className="number"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onNumberOfItems(valueToNb(e.target.value))}
                value={nbToOption(numberOfItems)}
            >{options}</select>
            <div className="increase" onClick={() => onNumberOfItems(Math.min(numberOfItems + 1, values.length - 1))}>+</div>

        </div>
    );
}

export default NbOfItems;