import React from 'react';
import './NbOfSeatsEdit.scss';
import Left from '../../assets/icons/sofa-left.svg';
import Right from '../../assets/icons/sofa-right.svg';
import Center from '../../assets/icons/sofa-center.svg';

type ComponentProps = {
    numberOfSeats: number,
    onNumberOfSeats: (numberOfSeats: number) => void
}

const NbOfSeats: React.FC<ComponentProps> = ({ numberOfSeats, onNumberOfSeats }) => {
    const values = ['--', '2', '3', '4', '5', '6+'];
    const options = values.map(option => (
        <option key={option} value={option}>{option}</option>
    ));

    const nbToOption = (nb: number) => values[nb === 0 ? nb : nb - 1];
    const valueToNb = (v: string) => { const value = values.indexOf(v); return value === 0 ? 0 : value + 1 };

    return (
        <div className="nb-seats-containter">
            <div className={["seats", numberOfSeats === 0 ? 'empty' : ''].join(' ')}>
                <div className="left"><img src={Left} alt="" /></div>
                {numberOfSeats > 0 && Array.from(Array(numberOfSeats - 2).keys()).map((i) => (<div key={i} className="middle"><img src={Center} alt="" /></div>))}
                <div className="right"><img src={Right} alt="" /></div>

            </div>
            <div className="decrease" onClick={() => onNumberOfSeats(Math.max(2, numberOfSeats - 1))}>-</div>
            <select className="number"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onNumberOfSeats(valueToNb(e.target.value))}
                value={nbToOption(numberOfSeats)}
            >{options}</select>
            <div className="increase" onClick={() => onNumberOfSeats(Math.max(2, Math.min(numberOfSeats + 1, 6)))}>+</div>

        </div>
    );
}

export default NbOfSeats;