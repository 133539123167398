import { DiscussionMessageDto, TransactionMessageDto, UserDto } from '@youzd/ref-data';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ChatMessage.scss';

type ComponentProps = {
    message: DiscussionMessageDto | TransactionMessageDto;
    user: UserDto,
}

const ChatMessage: React.FC<ComponentProps> = ({ message, user }) => {
    const { t } = useTranslation();
    return message.type === 'USER_MESSAGE' ?
        <div className={["chat-message-container", message.author === user.uid ? 'self' : 'other'].join(' ')}>
            <pre>{message.message}</pre>
        </div>
        : <div className="chat-message-concainer youzd">
            <p>{t(`chat.youzd.${message.eventType}`)}</p>
        </div>
}

export default ChatMessage;