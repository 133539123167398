import React from 'react';
import { connect } from 'react-redux';
import { AnyThunkDispatch } from '../../redux/actions';
import { doResetPassword } from "../../redux/userActions";
import { AppState } from '../../redux/reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PasswordReset from './PasswordReset';

type PropsFromState = {
    loginEmail: string,
    loading: boolean
};

type DispatchProps = {
    resetPassword: (email: string) => void
};

type ComponentProps = PropsFromState & DispatchProps;

const RegisterContainer: React.FC<ComponentProps> = ({ loginEmail, resetPassword, loading }) => {
    return (<PasswordReset loginEmail={loginEmail} resetPassword={resetPassword} loading={loading} />)
};

const mapStateToProps = (state: AppState, routeProps: RouteComponentProps<{ loginEmail: string }>): PropsFromState => ({
    loginEmail: routeProps.match.params.loginEmail,
    loading: state.pendingOperations.find(o => o === 'RESET_PASSWORD') !== undefined
});

const dispatchToProps = (dispatch: AnyThunkDispatch): DispatchProps => ({
    resetPassword: async (email: string) => {
        dispatch(doResetPassword(email));
    }
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(RegisterContainer));