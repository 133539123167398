import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDto } from '@youzd/ref-data';
import { useFormMessagesState } from '../../hooks/useFormMessagesState';
import { getFieldMessages, addError, hasError, FormMessagesWithField, checkValidLuhn } from '../../helpers/forms';
import Toggle from '../controls/Toggle';
import RadioOptions from '../controls/RadioOptions';
import LocationEditor from '../geo/LocationEditor';
import FloorEditor from '../geo/FloorEditor';
import FormField from '../form/FormField';
import PhotoEditor from '../controls/PhotosEditor';
import Cta from '../controls/Cta';
import Form from '../form/Form';
import FormBody from '../form/FormBody';
import YouzdScreen from '../template/YouzdScreen';
import './EditProfileForm.scss';
import Caption from '../controls/Caption';
import { AnyImageKind } from '../../helpers/photos';
import { apiToUtc, utcToHtml5Date, html5DateToUtc, utcToApi } from '../../helpers/time';
import { sprintf } from 'sprintf-js';
import { ApiError } from '../../service/api';
import moment from 'moment';
import MaleAvatar from '../../assets/avatar-m.svg';
import FemaleAvatar from '../../assets/avatar-f.svg';

export type ProfileDto = Omit<UserDto, "photo"> & {
    photo: AnyImageKind | undefined,
};

export type ProfileField = 'nickName' | 'siren'

type ComponentProps = {
    user: UserDto,
    onSave: (profile: ProfileDto) => void,
    errors: ApiError[],
    loading: boolean,
}

const EditProfileForm: React.FC<ComponentProps> = ({ user, errors, onSave, loading }) => {
    const { t } = useTranslation();

    const apiErrorsToFormErrors = (errors: ApiError[]): FormMessagesWithField<ProfileField> =>
        errors.map(e => {
            switch (e.errorMessage) {
                case 'error.duplicateNickname':
                    return {
                        relatedFields: ['nickName'],
                        message: {
                            level: 'error',
                            text: sprintf(t(`profile.edit.${e.errorMessage}`), e.errorData),
                        }
                    }
                default:
                    return {
                        relatedFields: ['all'],
                        message: {
                            level: 'error',
                            text: t(`profile.edit.${e.errorMessage}`)
                        }
                    }
            }
        });

    const [profile, setProfile] = useState<ProfileDto>({ ...user, photo: user.photo });
    const [messages, setMessages] = useFormMessagesState<ProfileField>(apiErrorsToFormErrors(errors));
    const [sirenEdited, setSirenEdited] = useState(false);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        const fieldErrors = apiErrorsToFormErrors(errors);
        setMessages(fieldErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, sirenEdited])

    useEffect(() => {
        setProfile({ ...user, photo: user.photo });
        setDirty(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    const changeProfile = (newProfile: ProfileDto) => {
        setProfile(newProfile);
        setDirty(true);
    }

    const togglePro = (toggle: boolean) => {
        changeProfile({ ...profile, isPro: toggle });
    }

    const changeTitle = (v: any) => {
        changeProfile({ ...profile, title: v });
    }

    const validate = () => {
        let newMessages: FormMessagesWithField<ProfileField> = [];
        if (profile.isPro) {
            if (!profile.siren && sirenEdited) {
                newMessages = addError<ProfileField>(newMessages, ['siren'], t('profile.edit.error.sirenMandatory'));
            } else if (profile.siren && !checkValidLuhn(profile.siren)) {
                newMessages = addError<ProfileField>(newMessages, ['siren'], t('profile.edit.error.sirenInvalid'));

            }
        }
        if (!profile.nickName || profile.nickName.length < 1) {
            newMessages = addError<ProfileField>(newMessages, ['nickName'], t('profile.edit.error.nickMandatory'));
        }
        setMessages(newMessages);
    }

    const canSave = !hasError<ProfileField>(messages) && profile.nickName && profile.nickName.length > 0 && (!profile.isPro || profile.siren);


    return (
        <YouzdScreen header={true} className={"grey"}>
            <Form className="edit-profile">
                <FormBody>
                    <FormField label={t('profile.edit.isPro')} className="is-pro" layout="inline">
                        <Toggle toggled={profile.isPro || false} onToggle={togglePro} />
                    </FormField>
                    <Caption safeHtml={t("profile.edit.isProWarning")} />
                    {(profile.isPro || false) && (
                        <FormField label={t('profile.edit.siren')}
                            className="siren"
                            messages={getFieldMessages<ProfileField>('siren', messages)}
                            mandatory={true}>
                            <input
                                type="text"
                                value={profile.siren || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    changeProfile({ ...profile, siren: e.currentTarget.value.trimLeft() })
                                }}
                                onFocus={() => setSirenEdited(false)}
                                onBlur={() => { setSirenEdited(true) }}
                            />
                        </FormField>
                    )}
                    <hr />
                    <PhotoEditor
                        photos={profile.photo ? [profile.photo] : []}
                        limit={1}
                        onPhotoChanged={([photo, ...others]) => changeProfile({ ...profile, photo })}
                        label={t('profile.edit.addPhoto')}
                        mode="profile"
                        profileIcon={profile.title === 'M.' ? MaleAvatar : FemaleAvatar}

                    />
                    <FormField className="nickname" label={t("profile.edit.nickname")} mandatory={true} messages={getFieldMessages<ProfileField>('nickName', messages)}>
                        <input
                            type="text"
                            value={profile.nickName || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeProfile({ ...profile, nickName: e.target.value.trimLeft() })}
                            placeholder={t("profile.edit.nickname")}
                        />
                    </FormField>
                    <hr />
                    <FormField className="title" label={t('profile.edit.title')}>
                        <RadioOptions value={profile.title} onChange={changeTitle}>{[{
                            value: 'M.',
                            label: t('title.M'),
                        }, {
                            value: 'Mme',
                            label: t('title.Mme'),
                        }]}</RadioOptions>
                    </FormField>
                    <FormField className="first-name" label={t("profile.edit.firstname")}>
                        <input
                            type="text"
                            value={profile.firstName || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeProfile({ ...profile, firstName: e.target.value.trimLeft() })}
                            placeholder={t("profile.edit.firstname")}
                        />
                    </FormField>
                    <FormField className="last-name" label={t("profile.edit.name")}>
                        <input
                            type="text"
                            value={profile.lastName || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeProfile({ ...profile, lastName: e.target.value.trimLeft() })}
                            placeholder={t("profile.edit.name")}
                        />
                    </FormField>
                    <FormField className="birthdate" label={t("profile.edit.birthdate")}>
                        <input
                            type="date"
                            value={profile.birthDate ? utcToHtml5Date(apiToUtc(profile.birthDate)) : ''}
                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => { if (!profile.birthDate) e.target.value = utcToHtml5Date(moment('1990-01-01')) }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeProfile({ ...profile, birthDate: e.target.value ? utcToApi(html5DateToUtc(e.target.value)) : '' })}
                        />
                    </FormField>

                    <FormField className="address" label={t("profile.edit.address")}>
                        <LocationEditor location={profile.address} onLocationChanged={(location) => changeProfile({ ...profile, address: location })} />
                        <Caption safeHtml={t('profile.edit.addressHint')} />
                    </FormField>
                    <FloorEditor
                        value={{ floor: profile?.floor || 0, hasElevator: profile?.hasElevator || false }}
                        onChange={(floor) => changeProfile({ ...profile, floor: floor.floor, hasElevator: floor.hasElevator })} />
                    <FormField className="phone" label={t("profile.edit.phone")}>
                        <input
                            type="text"
                            value={profile.phone || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeProfile({ ...profile, phone: e.target.value.trimLeft() })}
                            placeholder={t("profile.edit.phone")}
                        />
                        <Caption safeHtml={t('profile.edit.phoneHint')} />
                    </FormField>
                </FormBody>
                {(dirty || !canSave) && <Cta onClick={() => onSave(profile)} disabled={!canSave} loading={loading} label={t('profile.edit.save')} />}
            </Form>
        </YouzdScreen>
    );
}

export default EditProfileForm;