import React from 'react';
import './CheckOptions.scss';


export type CheckOption = {
    label: string,
    value: string
}

type ComponentProps = {
    values: any[],
    onChange: (v: any) => void,
    children: CheckOption[]
}

const CheckOptions: React.FC<ComponentProps> = ({ values, onChange, children }) => {
    const mappedChildren = children.map(o => (
        <li
            key={o.value}
            className={values.indexOf(o.value) > -1 ? 'selected' : ''}
            onClick={() => onChange(o.value)}>
            {o.label}
        </li>
    ));
    return (
        <ul className="check-options">
            {mappedChildren}
        </ul>
    )
}

export default CheckOptions;