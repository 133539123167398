import React, { useEffect, useState } from 'react';
import './AttributesFrame.scss';
import { useTranslation } from 'react-i18next';
import expandIcon from '../../assets/controls/expand.svg';
import collapseIcon from '../../assets/controls/collapse.svg';
import validIcon from '../../assets/valid.svg';


type ComponentProps = {
    active: boolean,
    valid: boolean,
    name: string,
    summary?: string
    onToggle: (name: string) => void
}

const AttributesFrame: React.FC<ComponentProps> = ({ active, name, summary, onToggle, valid, children }) => {
    const { t } = useTranslation();
    const ref = React.createRef<HTMLElement>();
    const [wasActive, setWasActive] = useState(false);
    useEffect(() => {
        if (active && !wasActive && ref && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setWasActive(true);
        } else if (active === false) {
            setWasActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [active, ref])
    return (
        <section
            className={["attributes-frame", name, active ? "active" : "", valid ? "valid" : ""].join(' ')}
            ref={ref}
        >
            <header onClick={() => onToggle(name)}>
                <div className="title">
                    <h3>{t(`add.section.${name}`)}</h3>
                    {active ? null : <div className="summary">{summary}</div>}
                </div>
                <div className="arrow">
                    {!active && !valid && <img src={expandIcon} alt="" />}
                    {active && <img src={collapseIcon} alt="" />}
                    {valid && !active && <img src={validIcon} alt="" />}
                </div>
            </header>
            <div className={["content", active ? 'visible' : 'hidden'].join(' ')}>{active && children}</div>
        </section>
    )
}
export default AttributesFrame;