import React from 'react';
import './Caption.scss';

type ComponentProps = {
    safeHtml: string
}

const Caption: React.FC<ComponentProps> = ({ safeHtml }) => {
    return (
        <span className="caption" dangerouslySetInnerHTML={{ __html: safeHtml }} />
    )
}

export default React.memo(Caption);


