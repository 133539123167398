import React, { useState, useEffect } from 'react';
import './EditDescription.scss';
import FormField from '../form/FormField';
import { useTranslation } from 'react-i18next';
import { useFormMessageState } from '../../hooks/useFormMessagesState';
import { getFieldMessages, FormMessagesWithField, addError, hasError } from '../../helpers/forms';

export const MIN_DESCRIPTION_LENGTH = 5;

type ComponentProps = {
    description: string,
    onDescriptionChanged: (description: string) => void
}

type FormField = 'description'

const DescriptionEditor: React.FC<ComponentProps> = ({ description, onDescriptionChanged }) => {
    const [text, setText] = useState(description);
    const { t } = useTranslation();

    const [messages, setMessages] = useFormMessageState<FormField>();

    useEffect(() => {
        setText(description);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [description])

    const checkText = (text: string) => {
        let messages: FormMessagesWithField<FormField> = [];
        if ((!text || text.length < 1)) {
            messages = addError(messages, ['description'], t('add.description.error.mandatory'))
        }
        if (text && text.length > 0 && text.length < MIN_DESCRIPTION_LENGTH) {
            messages = addError(messages, ['description'], t('add.description.error.tooShort'))
        }
        setMessages(messages);
        return messages;
    }

    const changeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = e.target.value.trimLeft();
        setText(newText);
        const messages = checkText(newText);
        if (!hasError(messages)) {
            onDescriptionChanged(newText);
        }
    }

    return (
        <FormField className="description-edit" messages={getFieldMessages<FormField>('description', messages)}>
            <textarea
                className="description"
                onChange={changeDescription}
                onBlur={() => checkText(text)}
                value={text}
                placeholder={t("add.description.placeholder")}></textarea>
        </FormField>
    );
}

export default DescriptionEditor;