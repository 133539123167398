import { ArticleSummaryDto } from '@youzd/ref-data';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Photo from '../controls/Photo';
import './ArticleSummary.scss';

type ComponentProps = {
    article: ArticleSummaryDto,
    clickable?: boolean,
}

const ArticleSummary: React.FC<ComponentProps> = ({ article, clickable }) => {
    const history = useHistory();
    return <div className="article-summary-container" onClick={() => { clickable && history.push(`/ad/${article.uid}`) }}>
        <div className="image">
            <Photo image={article.photos[0].thumb} />
        </div>
        <div className="article-title-and-location">
            <h2>{article.title}</h2>
            <p>{article.cp} {article.city}</p>
        </div>
    </div >
}

export default React.memo(ArticleSummary);