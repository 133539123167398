import React, { useState } from 'react';
import ShareIcon from '../../assets/icons/share.svg';
import { useTranslation } from 'react-i18next';
import Modal from '../template/Modal';
import './Share.scss'
import { FacebookShareButton, FacebookIcon, PinterestShareButton, PinterestIcon } from 'react-share';
import Button from '../controls/Button';


type ComponentProps = {
    url?: string,
    title: string,
    media: string,
}

const Share: React.FC<ComponentProps> = ({ url = window.location.href, title, media }) => {
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation();

    return <div className="share-container">
        {opened && <Modal dismiss={() => setOpened(false)} contentId="share">
            <h2>{t('share.title')}</h2>
            <p>{t('share.intro')}</p>
            <ul>
                <li>
                    <FacebookShareButton
                        url={url}
                        quote={title}
                        hashtag='#youzd' resetButtonStyle={false}>
                        {t('share.onFacebook')}<FacebookIcon size={32} />
                    </FacebookShareButton>
                    <PinterestShareButton
                        url={url}
                        media={media}
                        resetButtonStyle={false}
                    >
                        {t('share.onPinterest')}<PinterestIcon size={32} />
                    </PinterestShareButton>
                </li>
            </ul>
            <Button mode='secondary' onClick={() => setOpened(false)}>{t('popup.close')}</Button>
        </Modal>}
        <div className="share-link" onClick={() => setOpened(true)}>
            {t('share.label')}
            <img src={ShareIcon} alt="" />
        </div>
    </div>;
}

export default Share;