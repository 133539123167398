import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { API_DATETIME_FORMAT } from '../service/api';

export const apiToUtc = (apiTime: string): Moment => {
    return moment.utc(apiTime, API_DATETIME_FORMAT);
}

export const utcToApi = (utc: Moment): string => {
    return utc.format(API_DATETIME_FORMAT);
}

export const utcToHtml5Date = (utc: Moment): string => {
    return moment(utc).local().format(moment.HTML5_FMT.DATE);
}
export const utcToHtml5DateTime = (utc: Moment): string => {
    return moment(utc).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
}

export const html5DateToUtc = (html5: string): Moment => {
    return moment(html5, moment.HTML5_FMT.DATE).utc();
}

export const html5DateTimeToUtc = (html5: string): Moment => {
    return moment(html5, moment.HTML5_FMT.DATETIME_LOCAL).utc();
}

export const utc2Human = (utc: Moment, t: TFunction): string => {
    const now = moment.utc();
    const startOfDay = moment().startOf('day');
    const local = moment(utc).local();
    if (utc.isAfter(startOfDay)) {
        return `${t('time.today')} ${t('time.at')} ${local.format('HH:mm')}`
    } else {
        const startOfYesterday = moment().startOf('day').add(-1, 'day');
        if (utc.isAfter(startOfYesterday)) {
            return `${t('time.yesterday')} ${t('time.at')} ${local.format('HH:mm')}`
        } else {
            const diffInDays = now.diff(utc, 'days');
            if (diffInDays < 7) {
                return `${t(`time.weekday.${local.format('E')}`)} ${t('time.at')} ${local.format('HH:mm')}`
            } else {
                return `${t('time.on')} ${local.format('DD/MM/YYYY')} ${t('time.at')} ${local.format('HH:mm')}`
            }
        }
    }
}