import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../controls/Button';
import YouzdScreen from './YouzdScreen';

const TechnicalError: React.FC = () => {
    const { t } = useTranslation();
    return <YouzdScreen header={true} className='grey'>
        <div className="technical-error-container">
            <h2>{t('technicalError.title')}</h2>
            <p dangerouslySetInnerHTML={{ __html: t('technicalError.message') }} />
            <Link to='/'><Button>{t('technicalError.back')}</Button></Link>
        </div>
    </YouzdScreen>
}

export default TechnicalError;