import { ArticleDetailsDto, CreateTransactionDto, MangoPayCardRegisterDto, MangoPayUserDto, TransactionDetailsDto, UserCredentialsDto, UserDto, UserLoggedInDto } from "@youzd/ref-data";
import { RouteComponentProps } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { NullableArticleWithTempPhotos } from "../components/vendre/AddArticle";
import { ApiError, Operation } from "../service/api";
import { doSaveArticle } from "./articleActions";
import { AppState } from "./reducer";
import { doCreateTransaction, doGetCardRegister, doSaveLike } from "./transactionActions";

type Extends<T, U extends T> = U;
export type DeferableAction = Extends<Operation, 'SAVE_ARTICLE' | 'SAVE_LIKE' | 'DELETE_LIKE' | 'CREATE_TRANSACTION' | 'GET_CARD_REGISTER' | 'PAY_CARD_REGISTER'> | undefined;

export type DeferedAction = {
    name: DeferableAction,
    data?: any
}

export enum actions {
    'USED_CREDENTIALS' = 'USED_CREDENTIALS',
    'USER_LOGGEDIN' = 'USER_LOGGEDIN',
    'ADD_API_ERRORS' = 'ADD_API_ERRORS',
    'CLEAR_API_ERRORS' = 'CLEAR_API_ERRORS',
    'API_START' = 'API_START',
    'API_FINISH' = 'API_FINISH',
    'USER_UPDATED' = 'USER_UPDATED',
    'RECORD_REDIRECT' = 'RECORD_REDIRECT',
    'REDIRECTED' = 'REDIRECTED',
    'DEFER_ACTION' = 'DEFER_ACTION',
    'CLEAR_DEFER_ACTION' = 'CLEAR_DEFFER_ACTION',
    'SET_DRAFT_ARTICLE' = 'SET_DRAFT_ARTICLE',
    'SET_ARTICLE' = 'SET_ARTICLE',
    'SET_DELIVERY_ZIPCODES' = 'SET_DELIVERY_ZIPCODES',
    'SET_CARD_REGISTER_DATA' = 'SET_CARD_REGISTER_DATA',
    'SET_TRANSACTION' = 'SET_TRANSACTION',
}

export type AnyThunkDispatch = ThunkDispatch<any, AppState, AnyAction>;

export const usedCredentials = (credentials: UserCredentialsDto) => ({ type: actions.USED_CREDENTIALS, credentials });
export const userLoggedIn = (user: UserLoggedInDto) => ({ type: actions.USER_LOGGEDIN, user });
export const userChanged = (user: UserDto) => ({ type: actions.USER_UPDATED, user });
export const addApiErrors = (errors: ApiError[]) => ({ type: actions.ADD_API_ERRORS, errors });
export const clearApiErrors = (operation: Operation) => ({ type: actions.CLEAR_API_ERRORS, operation });
export const apiStart = (operation: Operation) => ({ type: actions.API_START, operation });
export const apiFinish = (operation: Operation) => ({ type: actions.API_FINISH, operation });
export const recordRedirect = (route: string) => ({ type: actions.RECORD_REDIRECT, route });
export const redirected = () => ({ type: actions.REDIRECTED });
export const setDraftArticle = (article: NullableArticleWithTempPhotos | undefined) => ({ type: actions.SET_DRAFT_ARTICLE, article });
export const defferAction = (actionName: DeferableAction, data?: any) => ({ type: actions.DEFER_ACTION, deferedAction: { name: actionName, data: data } });
export const clearDefferAction = () => ({ type: actions.DEFER_ACTION, });
export const setArticle = (article: ArticleDetailsDto) => ({ type: actions.SET_ARTICLE, article });
export const setYouzdDeliveryZipCodes = (codes: string[]) => ({ type: actions.SET_DELIVERY_ZIPCODES, codes });
export const setCardRegisterData = (data: MangoPayCardRegisterDto | undefined) => ({ type: actions.SET_CARD_REGISTER_DATA, data });
export const setTransaction = (transaction: TransactionDetailsDto) => ({ type: actions.SET_TRANSACTION, transaction });

export const doNext = (routeProps: RouteComponentProps, defaultRoute: string = '/') => async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const stashedRoute = getState().stashedRoute;
    if (getState().deferredAction) {
        await executeDefferedAction(routeProps, dispatch, getState);
    } else if (stashedRoute) {
        routeProps.history.push(stashedRoute);
        dispatch(redirected());
    } else {
        routeProps.history.push(defaultRoute);
    }
}

const executeDefferedAction = async (routeProps: RouteComponentProps, dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const action = getState().deferredAction;
    if (action && action.name === 'SAVE_ARTICLE') {
        dispatch(doSaveArticle(getState().draftArticle, routeProps));
        dispatch(clearDefferAction());
    }
    if (action && action.name === 'SAVE_LIKE' && action.data) {
        const articleUid = action.data;
        dispatch(doSaveLike(articleUid, routeProps));
        routeProps.history.push(`/ad/${articleUid}`);
        dispatch(clearDefferAction());
    }
    if (action && action.name === 'CREATE_TRANSACTION' && action.data) {
        const transaction: CreateTransactionDto = action.data;
        dispatch(doCreateTransaction(transaction, routeProps));
        dispatch(clearDefferAction());
    }
    //TODO: replay get card register
    if (action && action.name === 'GET_CARD_REGISTER' && action.data) {
        const mangopayUser: MangoPayUserDto = action.data;
        dispatch(doGetCardRegister(mangopayUser, routeProps));
        dispatch(clearDefferAction());
        routeProps.history.push('/add-card')
    }
    //TODO: replay finish card register
}
