import React from 'react';
import { ShippingOption, LocationDto } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import './ArticleShippingOptions.scss';
import ArticleShippingOption from './ArticleShippingOption';

type ComponentProps = {
    options: ShippingOption[],
    deliveryLocation: LocationDto | undefined,
    userLoggedIn: boolean,
    doAddressInput: () => void,
}

const ArticleShippingOptions: React.FC<ComponentProps> = ({ options, deliveryLocation, doAddressInput, userLoggedIn }) => {
    const { t } = useTranslation();


    const optionsToDisplay = options.map(o => <li key={o.type}><ArticleShippingOption option={o} hasDelivery={deliveryLocation !== undefined} /></li>)

    return (
        <div className="article-shipping-container">
            <ul className="article-shipping">
                {optionsToDisplay}
            </ul>
            {!deliveryLocation && <div className="please-login" onClick={doAddressInput}>{userLoggedIn ? t('ad.shipping.inputAddress') : t('ad.shipping.loginPlease')}</div>}
        </div>
    );
}

export default ArticleShippingOptions;