import React from 'react';
import { connect } from 'react-redux';
import { UserCredentialsDto } from '@youzd/ref-data';
import Register, { CreateUserCallback } from './Register';
import { AnyThunkDispatch } from '../../redux/actions';
import { doRegisterUser } from "../../redux/userActions";
import { AppState } from '../../redux/reducer';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type PropsFromState = {
    credentials: UserCredentialsDto | undefined,
    emailError: string,
    loading: boolean,
};

type DispatchProps = {
    createUser: CreateUserCallback
};

type ComponentProps = PropsFromState & DispatchProps;

const RegisterContainer: React.FC<ComponentProps> = ({ createUser, credentials, emailError, loading }) => {
    return (<Register createUser={createUser} credentials={credentials} emailError={emailError} loading={loading} />)
};

const mapStateToProps = (state: AppState): PropsFromState => ({
    credentials: state.credentials,
    emailError: state.apiErrors.filter(e => e.operation === 'REGISTER_USER').map(e => e.errorMessage).join(''),
    loading: state.pendingOperations.find(o => o === 'REGISTER_USER') !== undefined
});

const dispatchToProps = (dispatch: AnyThunkDispatch, ownProps: RouteComponentProps): DispatchProps => ({
    createUser: async (credentials: UserCredentialsDto) => {
        dispatch(doRegisterUser(credentials, ownProps));
    }
});

export default withRouter(connect(mapStateToProps, dispatchToProps)(RegisterContainer));