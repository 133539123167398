import { CreateTransactionDto, MangoPayCardRegisterDto, MangoPayUserDto } from "@youzd/ref-data";
import { RouteComponentProps } from "react-router-dom";
import { ApiError } from "../service/api";
import { createTransaction, deleteLike, getCardRegisterData, loadTransaction, payWithCardRegisterData, saveLike } from "../service/transactions";
import { addApiErrors, AnyThunkDispatch, apiFinish, apiStart, clearApiErrors, defferAction, setCardRegisterData, setTransaction } from "./actions";
import { doLoadArticleAd } from "./articleActions";
import { AppState } from "./reducer";
import { doLoadUser } from "./userActions";

export const doSaveLike = (articleUid: string, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const loginFirst = () => {
        dispatch(defferAction('SAVE_LIKE', articleUid));
        routeProps.history.push('/login');
    };
    const manageApiErrors = (err: any) => {
        if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
            loginFirst();
        }
        else {
            dispatch(addApiErrors([err]));
        }
    };
    dispatch(clearApiErrors('SAVE_LIKE'));
    dispatch(apiStart('SAVE_LIKE'));
    saveLike(articleUid)
        .then(() => {
            dispatch(doLoadArticleAd(articleUid));
            dispatch(doLoadUser(routeProps));
        })
        .catch(manageApiErrors)
        .finally(() => {
            dispatch(apiFinish('SAVE_LIKE'));
        });
});

export const doDeleteLike = (articleUid: string, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const loginFirst = () => {
        dispatch(defferAction('DELETE_LIKE', articleUid));
        routeProps.history.push('/login');
    };
    const manageApiErrors = (err: any) => {
        if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
            loginFirst();
        }
        else {
            dispatch(addApiErrors([err]));
        }
    };
    dispatch(clearApiErrors('DELETE_LIKE'));
    dispatch(apiStart('DELETE_LIKE'));
    deleteLike(articleUid).then(() => {
        dispatch(doLoadArticleAd(articleUid));
        dispatch(doLoadUser(routeProps));
    })
        .catch(manageApiErrors)
        .finally(() => {
            dispatch(apiFinish('DELETE_LIKE'));
        });
});

export const doCreateTransaction = (transaction: CreateTransactionDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const loginFirst = () => {
        dispatch(defferAction('CREATE_TRANSACTION', transaction));
        routeProps.history.push('/login');
    };
    const manageApiErrors = (err: any) => {
        if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
            loginFirst();
        }
        else {
            dispatch(addApiErrors([err]));
        }
    };
    dispatch(clearApiErrors('CREATE_TRANSACTION'));
    dispatch(apiStart('CREATE_TRANSACTION'));
    createTransaction(transaction)
        .then((transaction) => {
            dispatch(setTransaction(transaction));
            routeProps.history.push(`/pay/${transaction.uid}`);
        })
        .catch(manageApiErrors)
        .finally(() => {
            dispatch(apiFinish('CREATE_TRANSACTION'));
        });
});


export const doGetCardRegister = (mangoUser: MangoPayUserDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const loginFirst = () => {
        dispatch(defferAction('GET_CARD_REGISTER', mangoUser));
        routeProps.history.push('/login');
    };
    const manageApiErrors = (err: any) => {
        if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
            loginFirst();
        }
        else {
            dispatch(addApiErrors([err]));
        }
    };
    dispatch(clearApiErrors('GET_CARD_REGISTER'));
    dispatch(apiStart('GET_CARD_REGISTER'));
    getCardRegisterData(mangoUser)
        .then((cardRegisterData) => {
            dispatch(setCardRegisterData(cardRegisterData));
        })
        .catch(manageApiErrors)
        .finally(() => {
            dispatch(apiFinish('GET_CARD_REGISTER'));
        });
});

export const doPayWithCardRegister = (cardRegisterData: MangoPayCardRegisterDto, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const manageApiErrors = (err: any) => {
        if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
            routeProps.history.push('/login');
        }
        else {
            dispatch(addApiErrors([err]));
        }
    };
    dispatch(clearApiErrors('PAY_CARD_REGISTER'));
    dispatch(apiStart('PAY_CARD_REGISTER'));
    payWithCardRegisterData(cardRegisterData)
        .then((payResult) => {

            dispatch(setCardRegisterData(undefined));
        })
        .catch(manageApiErrors)
        .finally(() => {
            dispatch(apiFinish('PAY_CARD_REGISTER'));
        });
});

export const doLoadTransaction = (transactionUid: string, routeProps: RouteComponentProps) => (async (dispatch: AnyThunkDispatch, getState: () => AppState) => {
    const manageApiErrors = (err: any) => {
        if ((err as ApiError).errorType === 'LOGIN_REQUIRED') {
            routeProps.history.push('/login');
        }
        else {
            dispatch(addApiErrors([err]));
        }
    };
    dispatch(clearApiErrors('LOAD_TRANSACTION'));
    dispatch(apiStart('LOAD_TRANSACTION'));
    loadTransaction(transactionUid)
        .then((transaction) => {
            dispatch(setTransaction(transaction));
        })
        .catch(manageApiErrors)
        .finally(() => {
            dispatch(apiFinish('LOAD_TRANSACTION'));
        });
});