import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducer';


const middleware = [reduxThunk];
const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(...middleware),
    ),
);

export default store;