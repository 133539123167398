import React from 'react';
import './DimensionsEditor.scss';
import { DimensionAttribute, DimensionsPrecision } from '@youzd/ref-data';
import { useTranslation } from 'react-i18next';
import FormField from '../form/FormField';
import ScaleVertical from '../../assets/icons/scaleVertical.svg'
import ScaleDiagonal from '../../assets/icons/scaleDiagonal.svg'
import RadioOptions from '../controls/RadioOptions';
import Tip from '../controls/Tip';
import Toggle from '../controls/Toggle';


export type NullableDimension = DimensionAttribute | undefined;
export type NullablePrecision = DimensionsPrecision | undefined;


type ComponentProps = {
    width: NullableDimension,
    height: NullableDimension,
    depth: NullableDimension,
    precision: NullablePrecision,
    removable?: boolean,
    comment: string | undefined,
    onWidthChanged: (newWidth: NullableDimension) => void,
    onHeightChanged: (newWidth: NullableDimension) => void,
    onDepthChanged: (newWidth: NullableDimension) => void,
    onPrecisionChanged: (newPrecision: NullablePrecision) => void,
    onCommentChanged: (comment: string) => void,
    onRemovableChanged: (removable: boolean) => void,
    useLength?: boolean,
    useRemovable?: boolean
}

const DimensionsEditor: React.FC<ComponentProps> = ({
    width,
    height,
    depth,
    onWidthChanged,
    onHeightChanged,
    onDepthChanged,
    precision,
    onPrecisionChanged,
    comment,
    onCommentChanged,
    removable,
    onRemovableChanged,
    useLength,
    useRemovable }) => {
    const { t } = useTranslation();

    const changeHeight = ((event: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(event.target.value);
        if (val > 0) {
            onHeightChanged({ unit: 'cm', value: val });
        } else {
            onHeightChanged(undefined);
        }
    });
    const changeWidth = ((event: React.ChangeEvent<HTMLInputElement>) => {

        const val = parseFloat(event.target.value);
        if (val > 0) {
            onWidthChanged({ unit: 'cm', value: val });
        } else {
            onWidthChanged(undefined);
        }
    });
    const changeDepth = ((event: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(event.target.value);
        if (val > 0) {
            onDepthChanged({ unit: 'cm', value: val });
        } else {
            onDepthChanged(undefined);
        }
    });

    const changeComment = ((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newComment = event.target.value.trimLeft();
        onCommentChanged(newComment);
    });

    return (
        <div className="dimensions">
            <FormField className="precision">
                <RadioOptions value={precision} onChange={onPrecisionChanged} layout='vertical'>
                    {[
                        { label: t('add.dimensions.precise'), value: 'precise' },
                        { label: t('add.dimensions.approx'), value: 'approx' },
                    ]}
                </RadioOptions>
                <Tip title={t('add.dimensions.tip.title')} text={t('add.dimensions.tip.text')} />
            </FormField>
            <FormField label={(<><img src={ScaleVertical} alt="" />{t('add.dimensions.height')}</>)} className="height" layout='reverse'>
                <input type="number" value={height?.value || ''} onChange={changeHeight} />
            </FormField>
            <FormField label={(<><img src={ScaleVertical} alt="" />{t('add.dimensions.width')}</>)} className="width" layout='reverse'>
                <input type="number" value={width?.value || ''} onChange={changeWidth} />
            </FormField>
            <FormField label={(<><img src={ScaleDiagonal} alt="" />{useLength ? t('add.dimensions.length') : t('add.dimensions.depth')}</>)} className="depth" layout='reverse'>
                <input type="number" value={depth?.value || ''} onChange={changeDepth} />
            </FormField>
            {useRemovable &&
                <FormField className='removable' label={t('add.removable.label')} layout="inline">
                    <Toggle toggled={removable || false} onToggle={onRemovableChanged} />
                </FormField>
            }
            <FormField label={t('add.dimensions.comment')} className="comment" layout='big'>
                <textarea value={comment || ''} onChange={changeComment} placeholder={t('add.dimensions.commentPlaceholder')} />
            </FormField>
        </div>
    );
}

export default DimensionsEditor;