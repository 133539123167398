import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ShippingSize, ShippingSizes } from '@youzd/ref-data';
import letterShipIcon from '../../assets/icons/shipSize-letter.svg';
import shoeBoxShipIcon from '../../assets/icons/shipSize-shoeBox.svg';
import boxShipIcon from '../../assets/icons/shipSize-box.svg';
import carShipIcon from '../../assets/icons/shipSize-car.svg';
import truckShipIcon from '../../assets/icons/shipSize-truck.svg';
import utilityShipIcon from '../../assets/icons/shipSize-utility.svg';
import './ShippingSizesSelect.scss';

type ComponentProps = {
    shippingSize: ShippingSize | undefined,
    onShippingSizeSelected: (newShippingSize: ShippingSize) => void,
}

const ShippingSizesSelect: React.FC<ComponentProps> = ({ shippingSize, onShippingSizeSelected }) => {
    const [size, setSize] = useState<ShippingSize | undefined>(
        shippingSize
    );
    useEffect(() => {
        setSize(shippingSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [shippingSize])
    const { t } = useTranslation();
    const shipSizeimages: any = {};
    shipSizeimages["xs"] = letterShipIcon;
    shipSizeimages["s"] = shoeBoxShipIcon;
    shipSizeimages["m"] = boxShipIcon;
    shipSizeimages["l"] = carShipIcon;
    shipSizeimages["xl"] = utilityShipIcon;
    shipSizeimages["xxl"] = truckShipIcon;

    const shipSizeSelect = (newSize: ShippingSize) => {
        setSize(newSize);
        onShippingSizeSelected(newSize);
    }
    const shippingSizeEntries = Object.keys(ShippingSizes).map(sSize => (
        <li key={sSize} onClick={() => shipSizeSelect(sSize as ShippingSize)} className={size === sSize ? 'selected' : ''}>
            <div className="square">
                <div className="wrapper">
                    <img src={shipSizeimages[sSize]} alt={t(`shippingSize.${sSize}`)} />
                    <span>{t(`shippingSize.${sSize}`)}</span>
                </div>
            </div>
        </li>
    ))
    return (
        <ul className="shipping-sizes">
            {shippingSizeEntries}
        </ul>
    );
}

export default ShippingSizesSelect;