import React from 'react';
import Webcam from 'react-webcam';
import { AnyImageKind, PhotoData, getThumbnail } from '../../helpers/photos';
import { useTranslation } from 'react-i18next';
import './AddPhotos.scss';
import Form from '../form/Form';
import FormBody from '../form/FormBody';
import PhotoEditor from '../controls/PhotosEditor';
import Cta from '../controls/Cta';
import Caption from '../controls/Caption';


type ComponentState = {
    photos: AnyImageKind[],
    mode: 'webcam' | 'upload',
    loading: boolean,
}

type ComponentProps = {
    uid: string,
    onValidate: (photos: AnyImageKind[]) => void,
    photos: AnyImageKind[]
}
export const MAX_PHOTOS = 6;

const AddPhotos: React.FC<ComponentProps> = ({ onValidate, photos }) => {
    const { t } = useTranslation();


    const [state, setState] = React.useState<ComponentState>({
        photos: photos,
        mode: 'upload',
        loading: false
    });

    const webcamRef = React.createRef<Webcam & HTMLVideoElement>();
    const onWebcamSnap = () => {
        if (webcamRef.current) {
            const data = webcamRef.current.getScreenshot();
            if (data) {
                getThumbnail(data).then((thumb) => {
                    const newPhoto: PhotoData = {
                        data: data,
                        thumb: { data: thumb }
                    }
                    setState({ ...state, photos: [...state.photos, newPhoto] });
                });
            }
        }
    }
    const onWebCamError = (err: any) => {
        setState({ ...state, mode: 'upload' });
    }
    const videoConstraints = {
        autoGainControl: { ideal: true },
        noiseSuppression: { ideal: true },
        height: 720,
        width: 720,
        resizeMode: 'crop-and-scale',
        facingMode: { exact: "environment" }
    }
    let capture = state.photos.length < MAX_PHOTOS && state.mode === 'webcam' ? (
        <div className="capture-wrapper">
            <Webcam className="capture"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMediaError={onWebCamError}
            />
            <div className="control" onClick={onWebcamSnap}></div>
        </div>
    ) : null;

    return (
        <Form className="add-photos">
            <FormBody className={capture ? 'has-capture' : ''}>
                <h2>{t('add.photos.title')}</h2>
                <Caption safeHtml={t('add.photos.caption')} />
                {capture}
                <PhotoEditor
                    limit={MAX_PHOTOS}
                    label={(state.mode === 'webcam') ? t('add.photos.phone') : ''}
                    photos={state.photos}
                    onPhotoChanged={(photos) => setState({ ...state, photos })}
                    mode='gallery' />
            </FormBody>
            <Cta
                label={t('add.photos.validate')}
                disabled={state.photos.length === 0}
                onClick={() => onValidate(state.photos)}></Cta>
        </Form>
    );
}

export default AddPhotos;