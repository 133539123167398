import { API_HOST } from './config'
import { ImageDto, ImagePutUrlDto } from '@youzd/ref-data';
import uuid from 'uuid/v4';
import { handleError, getAuthWithRefresh } from './api';
import { PhotoData, pngDataURItoBlob } from '../helpers/photos';


const uploadPhotoData = (photoData: PhotoData, imagePath: string) => {
    return new Promise<ImageDto>((resolve, reject) => {

        getAuthWithRefresh('UPLOAD_PHOTO').then(async auth => {
            const url = `${API_HOST}/api/imagePutUrl`;
            const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify({
                    name: imagePath
                })
            }
            return fetch(url, options)
                .then(r => handleError('UPLOAD_PHOTO', r))
                .then(async r => {
                    const putUrl: ImagePutUrlDto = await r.json();
                    return putUrl;
                })
                .then(putUrl => {
                    const putOptions: RequestInit = {
                        method: 'PUT',
                        body: pngDataURItoBlob(photoData.data)
                    }
                    return fetch(putUrl.url, putOptions).then(r => ({ response: r, image: putUrl.image }))
                })
                .then((r) => {
                    if (!r.response.ok) {
                        reject(r.response.statusText);
                    }
                    resolve(r.image);
                });
        }).catch(e => reject(e))
    })
}

export const saveUserPhoto = (uid: string, photo: PhotoData) => {
    return savePhoto(`users/${uid}/`, `${uuid()}.png`, photo);
}


export const saveArticlePhoto = (uid: string, photo: PhotoData) => {
    return savePhoto(`article/${uid}/`, `${uuid()}.png`, photo);
}

export const savePhoto = (path: string, name: string, photo: PhotoData) => {
    return new Promise<ImageDto>((resolve, reject) => {
        uploadPhotoData(photo, path + name).then(image => {
            if (photo.thumb) {
                uploadPhotoData(photo.thumb, path + 't_' + name)
                    .then(thumb => resolve({ ...image, thumb: thumb }))
                    .catch(err => reject(err))
            } else {
                resolve(image);
            }
        }).catch(err => reject(err));
    })
}
