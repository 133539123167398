import React from 'react';
import { FloorDto } from '@youzd/ref-data/build/dto';
import { useTranslation } from 'react-i18next';
import Toggle from '../controls/Toggle';
import './FloorEditor.scss';
import FormGroup from '../form/FormGroup';
import FormField from '../form/FormField';

type ComponentProps = {
    value: FloorDto | undefined,
    onChange: (floor: FloorDto) => void,
}

const FloorEditor: React.FC<ComponentProps> = ({ value, onChange }) => {
    const { t } = useTranslation();

    const floorOptionToNumber = (option: string): number => {
        return option === 'RDC' ? 0 : option === '10+' ? 10 : parseInt(option);
    }

    const floorNumberToOption = (floor: number): string => {
        return floor === 0 ? 'RDC' : floor === 10 ? '10+' : `${floor}`;
    }

    const floorOptions = ['RDC', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'].map(f => (
        <option key={f} value={f}>{f}</option>
    ));

    const onChangeFloor = ((event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange({ ...(value ? value : { hasElevator: false }), floor: floorOptionToNumber(event.target.value) });
    });

    const onChangeElevator = (hasElevator: boolean) => {
        onChange({ ...(value ? value : { floor: 0 }), hasElevator })
    }

    return (
        <FormGroup className="floor-edit" layout="inline">
            <FormField label={t("floor.edit.floor")}>
                <select
                    onChange={onChangeFloor}
                    value={floorNumberToOption(value?.floor || 0)}
                >{floorOptions}</select>
            </FormField>
            <FormField label={t("floor.edit.elevator")}>
                <Toggle toggled={value?.hasElevator || false} onToggle={onChangeElevator} />
            </FormField>
        </FormGroup>
    )
}

export default FloorEditor;