/// <reference types="@types/gapi" />
/// <reference types="@types/gapi.auth2" />

import React from 'react';
import useScript from '../../hooks/useScript';
import { useState } from 'react';
import GoogleLogo from '../../assets/google-logo.svg';


export type GoogleLoginCallback = (googleUser: gapi.auth2.GoogleUser) => void;

type ComponentProps = {
    onGoogleLogin: GoogleLoginCallback
}


const GoogleLogin: React.FC<ComponentProps> = ({ onGoogleLogin }) => {
    const [loaded, setLoaded] = useState(false);
    const [failed, setFailed] = useState(false);
    const [auth, setAuth] = useState<gapi.auth2.GoogleAuth | undefined>();
    const googleApiCallback = () => {
        gapi.load('auth2', () => {
            gapi.auth2.init({
                client_id: '68474289733-6ev1fokdkuvf64qrvicur2a6t0ki3vkt.apps.googleusercontent.com'
            }).then((auth) => {
                setAuth(auth);
                setLoaded(true);
                if (auth.isSignedIn.get()) {
                    onGoogleLogin(auth.currentUser.get());
                }
            }).catch((e: any) => {
                setFailed(true);
            });
        });
    }

    (window as any)['googleApiCallback'] = googleApiCallback;
    const gscript = useScript('https://apis.google.com/js/platform.js?onload=googleApiCallback');
    if (!loaded && gscript.loaded && gscript.fromCache) {
        googleApiCallback();
    }
    const loginClickHandler = () => {
        auth?.signIn().then((user) => { onGoogleLogin(user) }).catch((e) => { console.log(e) });
    }
    return (<>
        {failed ? null :
            <div>
                <button onClick={loaded ? loginClickHandler : () => { }} >
                    <img src={GoogleLogo} alt="Google Logo" style={!loaded ? { filter: 'grayscale(100%)' } : {}} />
                </button>
            </div>
        }
    </>
    );
}


export default GoogleLogin;
