import React from 'react';
import { connect } from 'react-redux';
import AddArticle, { NullableArticleWithTempPhotos } from './AddArticle';
import { AppState } from '../../redux/reducer';
import uuid from 'uuid/v4';
import { AnyThunkDispatch, setDraftArticle } from '../../redux/actions';
import { doSaveArticle } from "../../redux/articleActions";
import { RouteComponentProps } from 'react-router-dom';

const emptyArticle = {
    uid: uuid(),
    photos: [],
    categories: [],
    universes: [],
    title: '',
    description: '',
    location: undefined,
    state: undefined,
    shippingOptions: [],
    price: undefined,
    shippingSize: undefined
}


type PropsFromState = {
    article: NullableArticleWithTempPhotos,
    recover: boolean,
    loading: boolean
}

type DispatchProps = {
    onArticleSave: (article: NullableArticleWithTempPhotos) => void,
    onArticleUpdate: (article: NullableArticleWithTempPhotos) => void,
    onReset: () => void,
}

type ComponentProps = PropsFromState & DispatchProps;

const AddArticleContainer: React.FC<ComponentProps> = ({ article, onArticleSave, onArticleUpdate, recover, onReset, loading }) => {
    return <AddArticle article={article} onArticleSave={onArticleSave} onArticleUpdated={onArticleUpdate} recover={recover} onReset={onReset} loading={loading} />;
}

const mapStateToProps = (state: AppState): PropsFromState => {
    const recover = state.draftArticle !== undefined;
    const article = state.draftArticle || emptyArticle;
    const loading = state.pendingOperations.includes('SAVE_ARTICLE');
    return ({
        article,
        recover,
        loading
    });
}



const dispatchToProps = (dispatch: AnyThunkDispatch, ownProps: RouteComponentProps): DispatchProps => ({
    onArticleSave: async (article: NullableArticleWithTempPhotos) => {
        dispatch(doSaveArticle(article, ownProps));
    },
    onArticleUpdate: (article: NullableArticleWithTempPhotos) => {
        dispatch(setDraftArticle(article));
    },
    onReset: () => {
        dispatch(setDraftArticle(undefined));
    }
});


export default connect(mapStateToProps, dispatchToProps)(AddArticleContainer);