import * as ls from 'local-storage';
import { NullableArticleWithTempPhotos } from '../components/vendre/AddArticle';
import { DeferedAction } from '../redux/actions';

type PeristTimeout = {
    draftArticle: any | undefined,
    stashedRoute: any | undefined,
    deferedAction: any | undefined,
}

const persistTimeout: PeristTimeout = {
    draftArticle: undefined,
    stashedRoute: undefined,
    deferedAction: undefined

};

export const persistDraftArticleToLocalstorage = (article: NullableArticleWithTempPhotos | undefined) => {
    if (persistTimeout.draftArticle) {
        clearTimeout(persistTimeout.draftArticle);
    }
    if (article) {
        persistTimeout.draftArticle = setTimeout(() => {
            ls.set('youzd-draft-article', JSON.stringify(article));
        }, 1000);
    } else {
        ls.remove('youzd-draft-article');
    }
}

export const getDraftArticleLocalStorage = (): NullableArticleWithTempPhotos => {
    const draftArticleJson = ls.get<string>('youzd-draft-article');
    return draftArticleJson ? JSON.parse(draftArticleJson) : undefined;
}

export const persistDeferredActionToLocalstorage = (deferredAction: DeferedAction | undefined) => {
    if (persistTimeout.deferedAction) {
        clearTimeout(persistTimeout.deferedAction);
    }
    if (deferredAction) {
        persistTimeout.deferedAction = setTimeout(() => {
            ls.set('youzd-deferred-action', JSON.stringify(deferredAction));
        }, 1000);
    } else {
        ls.remove('youzd-deferred-action');
    }
}

export const getDeferredActionLocalStorage = (): DeferedAction => {
    const deferredAction = ls.get<string>('youzd-deferred-action');
    return deferredAction ? (JSON.parse(deferredAction)) : undefined;
}

export const persistStashedRouteToLocalStorage = (stashedRoute: string | undefined) => {
    if (persistTimeout.stashedRoute) {
        clearTimeout(persistTimeout.stashedRoute);
    }
    if (stashedRoute) {
        persistTimeout.stashedRoute = setTimeout(() => {
            ls.set('youzd-stashed-route', stashedRoute);
        }, 1000);
    } else {
        ls.remove('youzd-stashed-route');
    }
}

export const getStashedRouteLocalStorage = (): string | undefined => {
    const stashedRoute = ls.get<string>('youzd-stashed-route');
    return stashedRoute ? stashedRoute : undefined;
}