import React from 'react';
import { useTranslation } from 'react-i18next';
import './Welcome.scss';
import Starter from './Starter';
import YouzdScreen from '../template/YouzdScreen';
import DeliveryIcon from '../../assets/icons/livraison.svg'
import AutomaticIcon from '../../assets/icons/automatic.svg'
import SearchIcon from '../../assets/icons/search.svg'
import LoveIcon from '../../assets/icons/love.svg'
import Button from '../controls/Button';
import { Link } from 'react-router-dom';
import { useMedia } from '../../hooks/useMedia';
import VendreIcon from '../../assets/icons/stroller.svg';


const Welcome: React.FC = () => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = useMedia();
    return (
        <YouzdScreen header={true} className="grey">
            <div className="welcome">
                <div className="starter-container">
                    <Starter showSplash={isMobile}>
                        <div className="slide">
                            <img src={DeliveryIcon} alt="" />
                            <h2>{t('welcome.starter.delivery')}</h2>
                        </div>
                        <div className="slide">
                            <img src={AutomaticIcon} alt="" />
                            <h2>{t('welcome.starter.automatic')}</h2>
                        </div>
                        <div className="slide">
                            <img src={SearchIcon} alt="" />
                            <h2>{t('welcome.starter.search')}</h2>
                        </div>
                        <div className="slide">
                            <img src={LoveIcon} alt="" />
                            <h2>{t('welcome.starter.love')}</h2>
                        </div>
                    </Starter>
                </div>
                <div className="main">
                    <div className="vendre">
                        {isDesktop && <div className="intro">
                            <img src={VendreIcon} alt="" />
                            <h2>{t('welcome.addTitle')}<br />
                                <strong>{t('welcome.addSubTitle')}</strong>
                            </h2>
                        </div>}
                        <Link to="/add-article"><Button>{t('welcome.add')}</Button></Link>
                    </div>
                </div>
            </div>
        </YouzdScreen>
    )
}

export default React.memo(Welcome);