import React from 'react';

type ComponentProps = {
    className?: string;
}

const FormBody: React.FC<ComponentProps> = ({ className, children }) => {
    return (
        <div className="scroller">
            <div className="shade"></div>
            <div className={["form-body", className].join(' ')}>{children}</div>
        </div>
    );
}

export default FormBody;