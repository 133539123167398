import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './BurgerMenu.scss';

type ComponentProps = {
    logged: boolean;
}

const BurgerMenu: React.FC<ComponentProps> = ({ logged }) => {
    const { t } = useTranslation();
    return (
        <div className="burger">
            <label>
                <input type="checkbox" id="menu-toggle" />
                <div className="container">
                    <div className="control">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <nav>
                        <ul>
                            {logged && <li><NavLink to="/my-profile" activeClassName="current" exact={true}><span>{t("menu.profile")}</span></NavLink></li>}
                            {!logged && <li><NavLink to="/my-profile" activeClassName="current" exact={true}><span>{t("menu.login")}</span></NavLink></li>}
                            <li><NavLink to="/add-article" activeClassName="current" exact={true}><span>{t("menu.add")}</span></NavLink></li>
                            <li><NavLink to="/faq" activeClassName="current" exact={true}><span>{t("menu.help")}</span></NavLink></li>
                            <li><NavLink to="/about" activeClassName="current" exact={true}><span>{t("menu.about")}</span></NavLink></li>
                        </ul>
                    </nav>
                </div>
            </label>
        </div>
    );
}

export default BurgerMenu;