import React, { useState, useEffect } from 'react';
import './Likes.scss';
import LikedIcon from '../../assets/icons/liked.svg';
import NotLikedIcon from '../../assets/icons/not-liked.svg';
import Modal from '../template/Modal';
import Button from '../controls/Button';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
    nb: number;
    isLiked: boolean;
    canLike: boolean;
    onChange: (liked: boolean) => void
}

const Likes: React.FC<ComponentProps> = ({ nb, isLiked, onChange, canLike }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [liked, setLiked] = useState(isLiked);
    const [showConfirm, setShowConfirm] = useState(false);

    const dismiss = () => setShowConfirm(false);

    const confirm = showConfirm ? <Modal dismiss={dismiss} contentId="confirm">
        <h2>{t('login.required')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('like.login') }} />
        <Button onClick={() => onChange(true)}>{t('like.doLogin')}</Button>
        <Button onClick={dismiss} mode='secondary'>{t('like.close')}</Button>
    </Modal> : null;

    const action = () => {
        if (!loading && canLike) {
            setLoading(true);
            onChange(!liked);
        }
        if (!canLike) {
            setShowConfirm(true);
        }
    }

    useEffect(() => {
        if (liked !== isLiked && loading) {
            setLoading(false);
        }
        setLiked(isLiked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLiked]);

    return <div className="likes-container" onClick={action}>
        {confirm}
        <div className={`icon ${loading ? "loading" : ""}`} >
            {liked ? <img src={LikedIcon} alt="" /> : <img src={NotLikedIcon} alt="" />}
        </div>
        <div className="nb">{nb}</div>
    </div>

}

export default Likes;