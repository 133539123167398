import { DimensionAttribute, ShippingSizeLimit, ShippingSize, shippingSizeLimits } from "@youzd/ref-data";

const canFit = (width: number, height: number, depth: number, limit: ShippingSizeLimit): boolean => {
    const limitDimensions = [limit.maxDepth, limit.maxHeight, limit.maxWidth].sort((a, b) => b - a);
    const objectDimensions = [depth, height, width].sort((a, b) => b - a);
    return objectDimensions.reduce<boolean>((result, d, index) => result && d <= limitDimensions[index], true);
}

const getDimensionInM = (d: DimensionAttribute) => {
    return d.unit === 'cm' ? d.value / 100 : d.value;
}

export const getShippingSize = (width: DimensionAttribute, height: DimensionAttribute, depth: DimensionAttribute): ShippingSize | undefined => {
    const widthInM = getDimensionInM(width);
    const heightInM = getDimensionInM(height);
    const depthInM = getDimensionInM(depth);
    return shippingSizeLimits.find(l => canFit(widthInM, heightInM, depthInM, l))?.size
}